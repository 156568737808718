// Text Banner Module. Can be used in any slot on the site.
.text-banner {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 auto;
    max-width: $page-grid-max-width;
    padding: 0 $page-grid-margin-sm;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(15, 1fr);
        padding: 0 $space-4xl;
    }

    @include media-breakpoint-up(lg) {
        padding: 0;
    }

    .inner {
        grid-column: 1 / 7;
        color: $dark-text;
        padding: $space-xl $space-2xl;

        @include media-breakpoint-up(md) {
            grid-column: 1 / 16;
            padding: $space-2xl $space-4xl;
        }

        @include media-breakpoint-up(lg) {
            grid-column: 2 / 15;
        }

        a {
            color: $dark-text;
        }

        &.pink {
            background: $loyalty-badge-background-color;
        }

        &.cyan {
            background: $color-light-cyan;
        }

        &.aero {
            background: $color-aero-blue;
        }

        &.night {
            background: $color-night-blue;
            color: $light-text;

            a {
                color: $light-text;
            }
        }
    }

    content {
        display: flex;
        flex-flow: row wrap;

        @include media-breakpoint-up(lg) {
            align-items: flex-end;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }

    copy {
        display: block;

        title {
            @include body-16-medium;

            display: block;
        }

        description {
            @include body-14-light;
        }
    }

    actions {
        @include body-14-light;

        display: flex;
        flex-flow: row wrap;
        gap: 0 $space-m;
        width: 60%;

        @include media-breakpoint-up(md) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            justify-content: flex-end;
            flex-flow: row nowrap;
            margin-left: $space-4xl;
            width: auto;
        }

        a {
            margin-top: $space-m;

            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }

            &.btn {
                border: rem(1) solid rgba($color-black, 0.3);
                height: rem(48);
                line-height: rem(48);
                padding: 0 $space-l;
            }
        }
    }

    .inner.night actions a.btn {
        border: rem(1) solid rgba($color-black, 0.3);
    }

    .rendering-template:has(&) {
        display: contents;
    }
}
