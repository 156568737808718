.error-section {
    margin: rem(88) 0;

    .error-title {
        @include font-serif-regular;
        margin-top: rem(32);
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: 0;
    }

    .error-content {
        padding: 0 rem(24);

        .logo-icon {
            width: rem(240);

            @include media-breakpoint-up(md) {
                width: rem(400);
            }
        }
    }

    .error-message {
        @include font-sans-regular;
        margin-bottom: rem(16);
        margin-top: rem(16);
        font-size: rem(16);
        line-height: rem(24);
    }

    .continue-shopping {
        margin-top: rem(32);
        height: rem(56);
        line-height: rem(56);
    }

    @include media-breakpoint-up(md) {
        margin: rem(144) 0;

        .error-content {
            padding: 0 rem(76);
        }

        .error-title {
            margin-top: rem(48);
        }

        .error-message {
            font-size: rem(14);
            line-height: rem(20);
        }
    }
}

.error-page-container {
    background-color: $error-page-background;
}
