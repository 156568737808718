.popover {
    @include sans-family;
    @include body-12-regular;

    border: rem(1) solid $popover-border-color;
    border-radius: 0;
    color: $popover-color;
    font-family: $sprig-sans;
    max-width: calc(100% - rem(40));
    padding: rem(36) $space-m $space-m;

    @include media-breakpoint-up(md) {
        max-width: rem(320);
    }

    @include media-breakpoint-up(lg) {
        padding-top: $space-m;
    }

    p {
        margin-bottom: 0;
        margin-top: $space-s;

        &:first-child {
            margin-top: 0;
        }
    }

    strong {
        @include font-sans-regular;
    }

    @include media-breakpoint-down(lg) {
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.99998 6.70733L8.6676 9.37495L9.37471 8.66785L6.70708 6.00022L9.37471 3.3326L8.6676 2.62549L5.99998 5.29311L3.33235 2.62549L2.62524 3.3326L5.29287 6.00022L2.62524 8.66785L3.33235 9.37495L5.99998 6.70733Z' fill='black'/%3E%3C/svg%3E");
            content: '';
            cursor: pointer;
            position: absolute;
            right: $space-m;
            top: $space-m;
            width: rem(12);
            height: rem(12);
        }
    }

    .popover-body {
        color: $popover-color;
        padding: 0;
    }

    .arrow {
        display: none;
    }
}
body {
    &.modal-open {
        > .popover {
            z-index: $zindex-max-highest;
        }
    }
    > .popover {
        z-index: $zindex-medium;
    }
}
