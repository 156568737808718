@import './collapsibleItem';

.footer {
    &-inner,
    &-legal-block {
        @include grid-layout;

        margin-top: $space-5xl;

        @include media-breakpoint-down(md) {
            margin-top: $space-4xl;
        }

        @include media-breakpoint-down(sm) {
            padding-inline: $space-l;
        }
    }

    &-inner {
        @include media-breakpoint-down(md) {
            margin-top: $space-xl;
        }
    }

    &-email-block {
        grid-column: 2/5;

        @include media-breakpoint-only(md) {
            grid-column: 2/7;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
        }
    }

    &-links-container {
        @include media-breakpoint-up(lg) {
            display: contents;
        }

        .rendering-template {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }

    &-help-block {
        grid-column: 6/9;
    }

    &-shop-block {
        grid-column: 9/12;
    }

    &-about-us-block {
        grid-column: 12/15;
    }

    &-mobile-wrapper {
        @include media-breakpoint-only(md) {
            grid-column: 8/15;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
        }

        @include media-breakpoint-up(lg) {
            display: contents;
        }
    }

    &-social-block {
        grid-column: 2/15;
        margin-top: rem(12);

        .social-link-icons {
            display: flex;
            margin-left: rem(-12);

            @include media-breakpoint-up(lg) {
                margin-left: -$space-s;
            }

            a {
                padding: rem(12);
                display: block;

                @include media-breakpoint-up(lg) {
                    padding: $space-s;
                }
            }

            .svg-icon {
                display: block;
            }
        }
    }

    &-legal-block {
        border-top: rem(1) solid $divider-color;
        padding-block: $space-xl;

        .content-asset {
            grid-column: 2/15;

            @include media-breakpoint-down(sm) {
                grid-column: 1/7;
            }
        }

        .footer-copy-inner {
            margin-top: $space-xl;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: $space-s $space-2xl;

            @include media-breakpoint-up(xl) {
                justify-content: space-between;
            }

            @include media-breakpoint-down(sm) {
                gap: $space-s $space-xl;
                display: block;
                column-count: 2;
            }
        }

        li {
            @include media-breakpoint-down(sm) {
                margin-bottom: $space-s;
            }
        }

        li:has(.copyright-footer-txt) {
            @include media-breakpoint-down(sm) {
                display: none;
            }

            + li {
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }

        a,
        .cookie-preferences,
        .copyright-footer-txt {
            @include font-sans-regular;
            @include font-sans-10px;

            text-align: left;

            @include media-breakpoint-down(sm) {
                @include font-sans-12px;
            }
        }

        a,
        .cookie-preferences {
            @include link-underline;

            text-decoration-color: $footer-link-border;
            padding: 0;

            &:hover {
                color: $dark-text;
                text-decoration-color: $footer-link-hover-border;
            }
        }
    }
}

.footer-section-title {
    @include title-text-upper;

    color: $footer-title-color;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.footer-links-container {
    @include media-breakpoint-down(sm) {
        margin-top: $space-2xl;
    }

    .collapsible-md {
        @include media-breakpoint-down(md) {
            border-bottom: rem(1) solid $footer-accordion-border-color;
            max-height: $space-5xl;
            transition: max-height $duration-default ease-in-out;
            overflow: hidden;
        }

        &.active {
            @include media-breakpoint-down(md) {
                max-height: rem(500);
            }
        }

        .content {
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                gap: $space-m;
            }
        }

        .title {
            @include media-breakpoint-down(md) {
                line-height: normal;
                margin: 0;
                padding-block: $space-xl;
            }

            &:after {
                @include media-breakpoint-down(md) {
                    border-color: $color-black;
                    height: rem(6);
                    margin-right: $space-2xs;
                    margin-top: $space-2xs;
                    width: rem(6);
                }
            }
        }
    }

    ul {
        @include media-breakpoint-up(lg) {
            margin-top: $space-m;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: $space-2xl;
        }
    }

    li + li {
        @include media-breakpoint-up(lg) {
            margin-top: $space-s;
        }
    }

    a {
        text-decoration-color: $footer-link-border;
        color: $footer-link-color;
        transition: all $duration-default;

        &:hover {
            text-decoration-color: $footer-link-hover-border;
        }
    }

    .miaw-chat-button {

        #launchChatButton {
            text-align: left;
            padding: 0;
            color: $footer-link-color;
            @include body-14-light;

            svg {
                margin-right: $space-s;
            }
        }
    }

}

.footer-email-block {
    @include media-breakpoint-down(md) {
        padding-top: $space-xl;
    }
}

.footer-newsletter-title {
    @include heading-h3;

    @include media-breakpoint-down(sm) {
        br {
            display: none;
        }
    }
}

.footer-email-signup {
    .btn {
        border: none;
        color: $base-text-color;
        height: $global-input-height;
        opacity: 0;
        padding: 0 $space-m;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: 300ms ease-in-out;

        &:disabled {
            background-color: transparent;
        }
    }

    .form-control:valid {
        ~ .btn {
            color: $dark-text;
            opacity: 1;
            pointer-events: auto;
        }
    }

    .form-control:invalid,
    .form-control.is-invalid {
        ~ .btn {
            color: $base-text-color;
        }
    }

    .form-control {
        appearance: none;
        padding-right: $space-6xl !important;

        &:placeholder-shown {
            ~ .btn {
                opacity: 0;
                pointer-events: none;
            }
        }

        &:not(:placeholder-shown) {
            ~ .btn {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .footer-signup-success-msg {
        @include body-12-regular;
    }

    .terms-conditions {
        @include body-12-regular;
    }
}

.footer-tertiary-link {
    @include body-12-regular;
}

.footer-banner {
    &-inner {
        position: relative;
        overflow: hidden;

        &.full-bleed {
            overflow: unset;
        }
    }

    &-image {
        width: 100%;

        .full-bleed & {
            @media screen and (min-width: $page-grid-max-width) {
                display: block;
                left: calc((100vw - $page-grid-max-width) / -2);
                position: relative;
                width: 100vw;
            }
        }
    }

    &-logo {
        height: rem(209);
        left: rem(57);
        position: absolute;
        top: rem(57);
        width: rem(400);

        @include media-breakpoint-down(md) {
            height: rem(238);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: rem(460);
        }

        @include media-breakpoint-down(sm) {
            height: rem(150);
            width: rem(290);
        }

        svg {
            max-height: 100%;
            width: 100%;
        }
    }
}

footer {
    background-color: $footer-background-color;
    border-top: rem(1) solid $divider-color;
    margin-top: $space-7xl;
    overflow-x: hidden;
    padding-block: 0;

    main:has(.customer-service) + & {
        margin-top: $space-6xl;
    }

    @include media-breakpoint-down(md) {
        margin-top: $space-6xl;
    }

    .footer-email-signup {
        background-color: $footer-background-color;

        .input-group-append {
            margin-top: rem(24);
            margin-bottom: rem(32);
        }
    }

    .content li {
        height: auto;
    }

    .modal {
        .modal-header {
            border: 0;
            padding: 0;

            .close {
                margin-top: rem(24);
                margin-right: rem(40);
                margin-bottom: rem(8);
                padding: 0;
            }
        }

        .success-modal-title {
            @include font-sans-semi-bold;
            color: $base-text-color;
            font-size: rem(16);
            line-height: rem(24);
            text-align: center;
        }

        .success-modal-body {
            @include font-sans-regular;
            color: $base-text-color;
            font-size: rem(14);
            line-height: rem(20);
            text-align: center;
            margin: rem(8) rem(40) rem(48) rem(40);
        }
    }

    main:has(.recently-carousel) + & {
        border-top: none;
        margin-top: 0;
    }

    main:has(.points-activity-pagination) + & {
        margin-top: rem(25);
    }
}

.signup-lightbox {
    .rendering-template {
        display: block;
    }
}

#embeddedMessagingConversationButton {
    display: none;
}
