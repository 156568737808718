.line-item {
    @include font-sans-light;

    font-size: rem(14);
    letter-spacing: rem(-0.14);
    line-height: 160%;
    display: grid;
    grid-template:
        'image . name price'
        'image . attributes price'
        'image . actions price'
        'image . messages messages'
        / min-content $space-l 1fr auto;

    &-no-product {
        .line-item-name {
            @include font-sans-medium;
        }

        .line-item-price,
        .line-item-messages {
            display: none;
        }
    }

    &-image {
        @include product-image;

        grid-area: image;
        text-align: center;
        width: rem(80);

        .line-item-oos & {
            filter: grayscale(1);
        }

        &.no-product {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $product-image-unavailable-bg;
        }

        svg {
            margin-top: $space-2xs;
        }
    }

    &-name {
        grid-area: name;
        text-wrap: wrap;
        font-weight: $font-weight-light;
        margin: 0;
        color: $line-item-title-color;

        &-link {
            color: $line-item-title-color;
            text-decoration: none;
        }
    }

    &-attributes {
        grid-area: attributes;
        font-size: inherit;

        .checkout-summary .gwp-container & {
            display: none;
        }
    }

    &-actions {
        grid-area: actions;
        margin-top: $space-s;
    }

    &-messages {
        grid-area: messages;
        font-weight: $font-weight-medium;
        display: flex;
        flex-direction: column;
        gap: $space-s;

        &:has(li:not(.d-none)) {
            margin-top: $space-m;

            .checkout-summary & {
                margin-top: 0;
            }
        }

        > li {
            @include body-12-medium;

            color: $dark-text;
            margin: 0;

            .similar-styles-category-link {
                padding-left: rem(6);
                color: $dark-text;
            }
        }
    }

    &-price {
        grid-area: price;
        padding-left: $space-l;
        display: grid;
        grid-template-columns: repeat(2, min-content);
        grid-column-gap: $space-xs;
        color: $dark-text;

        &-paid {
            &:only-child {
                grid-column: span 2;
            }

            &:has(+ .line-item-price-orig),
            &.gwp {
                @include font-sans-semi-bold;
            }
        }

        &-orig {
            text-decoration: line-through;
            color: $base-text-color;
        }

        &-msg {
            grid-column: 1 / span 2;

            .mini-cart-line-items &,
            .checkout-summary &,
            .receipt-summary & {
                display: none;
            }
        }
    }
}
