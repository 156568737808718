.promo-details {
    font-size: rem(14);
    line-height: 160%;
    letter-spacing: rem(-0.14);

    &-container {
        &:not(.promo-details-content &) {
            @include media-breakpoint-down(md) {
                padding: 0 rem(48);
            }

            @include media-breakpoint-down(sm) {
                padding: 0 $page-grid-margin-sm;
            }
        }
    }

    &-name {
        @include font-serif-extra-light;

        font-size: rem(28);
        line-height: 120%;
        letter-spacing: rem(-0.28);
    }

    &-header,
    &-card-header {
        @include body-12-medium;

        margin-bottom: 0;
        text-transform: uppercase;
    }

    &-description,
    &-card-product-name {
        @include body-14-light;

        margin-bottom: 0;
    }

    &-card-list {
        margin: $space-4xl 0;

        @include media-breakpoint-up(lg) {
            margin-top: $space-2xl;
        }
    }

    &-card-item {
        display: flex;

        &:not(:last-child) {
            margin-bottom: $space-l;
        }
    }

    &-card-item-image {
        aspect-ratio: 4/5;
        background-color: $image-background;
        display: block;
        max-width: rem(80);
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            display: block;
            height: 100%;
            inset: 0;
            object-fit: cover;
            position: absolute;
            width: 100%;
        }

        .multi-choice & {
            background: var(--global-promo-color, inherit) $icon-gift no-repeat center;
            background-size: rem(36) rem(36);
        }
    }

    &-card-item-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: $space-l;
        max-width: rem(236);
    }

    &-card-product-description {
        @include body-12-light;
    }

    &-name + &-header {
        margin-top: $space-4xl;

        @include media-breakpoint-up(lg) {
            margin-top: $space-5xl;
        }
    }

    &-name + &-description,
    &-header + &-description {
        margin-top: $space-m;
    }

    &-description + &-header {
        margin-top: $space-4xl;
    }

    .close-current-modal {
        display: none;
    }
}
