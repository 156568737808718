table {
    border: rem(1) solid $table-border;
    color: $table-color;
    width: 100%;
}

th {
    @include body-16-regular;

    background-color: $table-head-bg;
}

th,
td {
    border: rem(1) solid $table-border;
    padding: $space-m $space-s;

    @include media-breakpoint-up(md) {
        padding-inline: $space-xl;
    }
}

td {
    @include body-16-light;
}

.table-cell-description {
    @include body-10-regular;

    display: block;
    margin-top: $space-xs;

    @include media-breakpoint-up(md) {
        @include body-12-light;

        max-width: rem(160);
    }
}
