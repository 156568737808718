.loyalty-enrollnow-dialog,
.loyalty-welcome-dialog {
    .drawer-image-loyalty {
        img {
            max-width: 100%;
            min-height: 100%;
            object-fit: cover;

            @include media-breakpoint-down(sm) {
                min-height: rem(170);
            }
        }
    }

    .loyalty-getaccess-section {
        @include font-serif-regular;
        color: $base-text-color;
        padding: rem(48);

        @include media-breakpoint-down(sm) {
            padding: rem(16);
        }

        &.loyalty-welcome-msg {
            padding: rem(73) rem(68) rem(40);

            @include media-breakpoint-down(sm) {
                padding: rem(32) rem(16);
            }

            .welcome-cta {
                margin-top: rem(127);

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }

        .loyalty-getaccess-title {
            @include font-serif-regular;
            font-size: rem(24);
            line-height: rem(32);
            margin: 0;
        }

        .loyalty-getaccess-benefits {
            padding-top: rem(16);
        }

        .loyalty-getaccess-list {
            list-style-type: disc;
            letter-spacing: rem(0.6);
            line-height: rem(24);
            font-size: rem(18);
            margin-left: rem(15);
            padding: rem(8) 0;
        }

        .postal-code-msg {
            @include font-sans-regular;
            line-height: rem(16);
            letter-spacing: rem(0.4);
            font-size: rem(12);
            padding-top: rem(16);
            padding-bottom: rem(8);
        }

        .custom-form-group {
            margin: 0;
            padding-bottom: rem(24);

            &.margin-adjustment {
                @include media-breakpoint-down(sm) {
                    margin-top: rem(72);
                }
            }
        }

        .custom-control-input.is-invalid ~ .custom-control-label {
            &.loyalty-terms-conditions-label,
            &:before {
                color: $base-text-color;
                border-color: $base-text-color;
            }
        }

        .terms-conditions,
        .loyalty-terms-conditions-label {
            @include font-sans-regular;
            font-size: rem(12);
            line-height: rem(24);
            letter-spacing: rem(0.4);
            margin-bottom: rem(16);

            .loyalty-program-terms-and-condition {
                font-size: rem(12);
            }
        }

        .loyalty-terms-error {
            @include font-sans-regular;
            font-size: rem(12);
            line-height: rem(16);
            color: $error-text-color;
            position: absolute;
            margin-top: 0;
            left: 0;
            bottom: rem(8);
        }

        .welcome-copy {
            padding: rem(16) 0 rem(24);

            @include media-breakpoint-down(sm) {
                padding-bottom: rem(80);
            }
        }

        .welcome-paragraph {
            @include font-sans-regular;
            font-size: rem(14);
            line-height: rem(20);
            color: $base-text-color;
            margin: 0;
            padding: rem(16) 0;
        }

        .guest-enrollment {
            padding-top: rem(150);

            @include media-breakpoint-down(sm) {
                padding-top: rem(120);
            }
        }
    }

    .mobile-loyalty-enroll-now-title,
    .mobile-loyalty-welcome-title {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: rem(1);
        @include font-sans-bold;
        color: $base-text-color;
        margin: 0;
        text-transform: uppercase;
    }
}

.loyalty-guest-refer-friend-modal,
.loyalty-digital-refer-friend-modal {
    .loyalty-friend-referral-dialog {
        max-width: rem(960);
    }

    .modal-content {
        border: none;
    }

    .modal-body {
        padding: 0;
    }

    @include media-breakpoint-up(md) {
        .modal-dialog {
            max-width: rem(960);
            margin: rem(8) auto;
        }

        .modal-body {
            overflow-y: visible;
            max-height: rem(480);
        }
    }

    .refer-friend-guest-image,
    .refer-friend-digital-image {
        height: 100%;

        .drawer-image {
            max-width: 100%;
            object-fit: cover;
            height: rem(136);
            width: 100%;

            @include media-breakpoint-up(md) {
                height: rem(480);
            }
        }
    }

    .close-guest-referral-mobile {
        line-height: rem(16);
        padding: rem(18) rem(16) rem(20);
    }

    .close-referral-guest-modal-icon,
    .close-referral-digital-modal-icon {
        padding: rem(20) rem(16) rem(20) 0;
        line-height: rem(16);
        z-index: $zindex-lowest;
        position: inherit;

        .close-referral-icon {
            display: inline-block;
            width: rem(16);
            height: rem(16);
        }

        @include media-breakpoint-up(md) {
            padding-top: rem(16);
            padding-bottom: 0;
        }
    }

    .close-mobile-loyalty-digital-modal-title {
        @include font-sans-bold;
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: rem(1);
        color: $base-text-color;
        margin: 0;
        text-transform: uppercase;
    }

    .refer-friend-right-section {
        padding: rem(24) rem(16) 0;

        @include media-breakpoint-up(md) {
            padding: rem(84) rem(40);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .refer-modal-header {
            @include font-serif-regular;
            font-size: rem(24);
            line-height: rem(32);
            color: $loyalty-border-color;
            text-align: left;
            margin: 0 rem(4);

            @include media-breakpoint-up(md) {
                margin: 0;
                margin-right: rem(16);
                line-height: rem(40);
                font-size: rem(32);
            }
        }

        .friend-referral-content {
            @include font-sans-regular;
            font-size: rem(14);
            line-height: rem(20);
            text-align: left;
            margin: rem(8) rem(4) 0;

            @include media-breakpoint-up(md) {
                margin: rem(16) rem(16) 0 0;
            }
        }

        .friend-referral-cta {
            @include font-sans-medium;
            margin-top: rem(40);
            font-size: rem(14);
            height: rem(56);

            .friend-referral-btn {
                width: 100%;
                height: 100%;
                border: none;
                line-height: rem(24);
                font-size: rem(14);
                font-weight: $font-weight-medium;
                padding: rem(16) rem(67);
            }
        }

        .loyalty-terms-link {
            @include font-sans-regular;
            padding-top: rem(16);
            font-size: rem(12);
            letter-spacing: rem(0.4);
            text-align: left;

            a {
                line-height: rem(24);
                color: $loyalty-referral-color;
            }
        }
    }

    .refer-friend-digital-right-section {
        padding: rem(24) rem(16) 0;

        @include media-breakpoint-up(md) {
            padding: rem(44) rem(40);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .refer-digital-modal-header {
            @include font-serif-regular;
            font-size: rem(24);
            line-height: rem(32);
            color: $loyalty-border-color;
            text-align: left;
            margin: 0 rem(4);

            @include media-breakpoint-up(md) {
                margin: 0;
                margin-right: rem(16);
                line-height: rem(40);
                font-size: rem(32);
            }
        }

        .refer-digital-modal-content {
            @include font-sans-regular;
            font-size: rem(14);
            line-height: rem(20);
            text-align: left;
            margin: rem(16) rem(4) 0;

            @include media-breakpoint-up(md) {
                margin: rem(16) rem(16) 0 0;
            }
        }

        .friend-referral-digital-cta {
            @include font-sans-medium;
            margin-top: rem(40);
            font-size: rem(14);
            height: rem(56);

            .friend-referral-digital-btn {
                width: 100%;
                height: 100%;
                border: none;
                line-height: rem(24);
                font-size: rem(14);
                font-weight: $font-weight-medium;
                padding: rem(16) rem(55);

                @include media-breakpoint-up(md) {
                    padding: rem(16) rem(67);
                }
            }
        }

        .loyalty-terms-link {
            @include font-sans-regular;
            padding-top: rem(16);
            font-size: rem(12);
            letter-spacing: rem(0.4);
            text-align: left;

            a {
                line-height: rem(24);
                color: $loyalty-referral-color;
            }
        }
    }
}

// Loyalty enrollment + Welcome dialog use only this code. Code above not used
.club-lilly-enroll-dialog {
    &-picture {
        display: block;
        margin: 0 (-$space-4xl) $space-3xl;

        @include media-breakpoint-down(sm) {
            margin-inline: -$space-l;
        }

        img {
            width: 100%;
            height: auto;
            aspect-ratio: 3 / 2;
            object-fit: cover;
        }

        .loyalty-enrollnow-dialog & {
            margin: 0 (-$space-3xl) $space-3xl (-$space-4xl);

            @include media-breakpoint-down(sm) {
                margin-inline: -$space-l;
            }
        }
    }

    &-content {
        margin: 0 0 $space-l;

        @include media-breakpoint-up(md) {
            margin: 0 0 $space-3xl;
        }

        ul {
            list-style: none;
            margin: $space-l 0;
            border-top: rem(1) solid $divider-color;

            @include media-breakpoint-up(md) {
                margin: $space-3xl 0;
            }
        }

        li {
            background: 0 rem(45)
                url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='11' viewBox='0 0 12 11' fill='none'%3E%3Cpath d='M5.8341 8.92544C5.93669 8.86575 6.06343 8.86575 6.16602 8.92545L9.04005 10.5977C9.29116 10.7439 9.5949 10.5176 9.52683 10.2352L8.77501 7.11585C8.74562 6.9939 8.78791 6.86582 8.88416 6.78536L11.3832 4.69601C11.6095 4.50678 11.4928 4.13831 11.1988 4.11397L7.88102 3.83919C7.76033 3.8292 7.65482 3.7539 7.60612 3.64303L6.30212 0.674168C6.18658 0.41112 5.8134 0.411109 5.69784 0.674151L4.39363 3.64304C4.34493 3.75391 4.23942 3.8292 4.11874 3.83919L0.801234 4.11396C0.50722 4.13831 0.390467 4.50678 0.616805 4.69601L3.11583 6.78535C3.21208 6.86582 3.25438 6.9939 3.22498 7.11586L2.47298 10.2352C2.40489 10.5176 2.70862 10.7439 2.95974 10.5978L5.8341 8.92544Z' fill='black'/%3E%3C/svg%3E")
                no-repeat;
            background-position-y: center;
            padding: $space-3xl 0 $space-3xl rem(35);
            border-bottom: rem(1) solid $border-color-med;
        }

        .modal-text {
            @include body-16-light;
        }

        .modal-text + .modal-links {
            margin-top: $space-m;
        }

        .modal-links .tertiary-link {
            @include font-sans-16px;
        }
    }

    &-form {
        margin: 0 0 $space-3xl;

        .form-group {
            margin: $space-m 0;
        }

        .custom-checkbox .custom-control-input.is-invalid~.custom-control-label {
            color: $base-text-color;

            .account-dashboard & {
                color: $color-black;
            }
        }

        .custom-control-input.is-invalid~.custom-control-label:before {
            border-color: $border-color-med;
        }
    }

    &-more-link {
        font-size: rem(14);
        margin: 0 0 $space-3xl;
    }

    &-actions {
        text-align: center;

        .tertiary-link {
            margin-top: $space-l;
        }

        .error-msg-loyalty {
            @include body-12-regular;

            display: block;
            margin-top: $space-m;
            text-align: left;
        }
    }

    button.close {
        backdrop-filter: blur(rem(8));
        background: $sheet-close-bg;
        border-radius: 50%;
        height: $global-icon-link-size;
        line-height: 0;
        opacity: 1;
        width: $global-icon-link-size;

        &:hover {
            background: $sheet-close-bg-hover;
            transition: background $duration-default ease;
        }
    }
}
