@import '../recommendations';

.not-found-container {
    margin: $space-6xl auto 0;

    @include media-breakpoint-up(md) {
        margin: $space-7xl auto 0;
    }

    &.m-error-details {
        @include grid-layout;

        .recommendations {
            grid-column: 1/16;

            @include media-breakpoint-down(sm) {
                grid-column: 1/7;
            }

            &:has(.swiper-wrapper) {
                .title {
                    display: block!important;
                    padding: 0 grid-span(1);
                }
            }

        }

        .error.button-secondary {
            margin: $space-3xl 0 0;
        }
    }

    .not-found-content {
        @include grid-inner-standard;
    }

    .page-title {
        @include heading-h2;

        color: $dark-text;
        margin-bottom: $space-l;
    }

    .page-content {
        @include body-16-light;

        color: $dark-text;
    }

    .error-message {
        @include heading-h2;
        margin-top: 0;
    }

    .error-info {
        @include font-sans-semi-bold;
        margin-bottom: rem(32);
        letter-spacing: rem(0.3);
    }

    .product-listing-button {
        display: none;
    }

    .error {
        &.continue-shopping {
            max-width: rem(304);
            margin: 0 auto;
        }
    }
}

.order-detail-error {
    line-height: rem(20);
    width: rem(293);
    margin-left: auto;
    margin-right: auto;
}

.modal-background {
    opacity: $overlay-opacity;
}

.modal-backdrop,
.modal-background {
    z-index: $zindex-modal-bg;
}

.modal-dialog {
    z-index: $zindex-modal;
}

.rendering-template {
    display: inline-block;

    &:has(.content-page) {
        display: block;
    }
}
