.wishlist-alert-dialog {
    @include scroll-custom;

    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-height: rem(249);
    opacity: 0;
    overflow-y: auto;
    padding: $space-s 0;
    visibility: hidden;

    &:hover, &-shown {
        outline-color: transparent;
    }

    @include media-breakpoint-up(lg) {
        opacity: 1;
        visibility: visible;
        width: rem(200);
    }

    &-success {
        .alert-dialog-text {
            @extend .lp-icon-tick;

            color: $wishlist-alert-item-color;
            min-width: rem(158);
            padding-left: $space-s;

            &::after {
                left: $space-m;
            }
        }
    }

    &-removed {
        padding: 0 $space-l 0 0;
        position: fixed;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            top: rem(43);
            right: rem(-50);
        }

        .alert-inner-icon {
            float: left;
            margin-right: $space-m;
            max-width: rem(60);

            img {
                width: 100%;
            }
        }

        .alert-dialog-text {
            color: $dark-text;

            @include media-breakpoint-up(lg) {
                margin-top: $space-m;
            }
        }

        .alert-dialog-ctas {
            @include media-breakpoint-up(lg) {
                align-items: flex-start;
                margin-bottom: $space-m;
            }
        }
    }

    .alert-dialog-text {
        @include body-14-light;

        color: $wishlist-alert-item-color;
        flex-shrink: 0;
        margin: 0;
        padding: $space-s $space-2xl $space-s $space-m;
        position: relative;
        text-decoration: none;
        width: 100%;

        &:hover {
            background-color: $wishlist-alert-item-hover-bg;
            cursor: pointer;
        }

        &.m-selected {
            &::after {
                @extend .lp-icon-tick;

                right: $space-m;
            }
        }

        &.m-new {
            color: $base-text-color;

            &::after {
                content: '+';
                display: inline-block;
                margin-left: $space-xs;
            }
        }

        &.m-current {
            order: -1;
        }
    }
}
