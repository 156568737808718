@mixin imageTextCenter {
    display: block !important;
    padding-bottom: 0;
    margin-bottom: rem(36);

    .lca-hero, .video-component {
        display: block;
        width: 100%;
        height: 100%;
    }

    .lca-text-bg {
        display: none;
    }

    .text-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }
}

.content-tile {
    $content-tile-padding: 3rem;
    position: relative;
    padding-bottom: $content-tile-padding;

    &.cta-below {
        flex-direction: column;

        &.bottom-left {
            align-items: flex-start;
        }

        &.bottom-center {
            align-items: center;
        }

        &.bottom-right {
            align-items: flex-end;
        }

        &.middle-left {
            align-items: flex-start;
        }

        &.middle-center {
            align-items: center;
        }

        &.middle-right {
            align-items: flex-end;
        }

        &.top-left {
            align-items: flex-start;
        }

        &.top-center {
            align-items: center;
        }

        &.top-right {
            align-items: flex-end;
        }
    }

    &.cta-top {
        .text-container {
            position: absolute;
        }

        &.mob-top {
            justify-content: center;
            align-items: flex-start;
        }

        &.mob-center {
            justify-content: center;
            align-items: center;
        }

        &.mob-bottom {
            justify-content: center;
            align-items: flex-end;
        }
    }

    .description {
        @include font-sans-regular;
        font-size: rem(16);
        line-height: rem(24);
        &.title-description {
            font-size: rem(14);
            line-height: rem(16);
            margin-top: rem(16);
        }
    }

    .title {
        h1, h2, h3, h4, h5, h6 {
            @include font-serif-regular;
            font-size: rem(32);
            line-height: rem(40);
        }
    }

    .cta-link {
        @include font-sans-regular;
        font-size: rem(14);
        text-decoration: underline;
        margin-bottom: 0;
    }

    /* For imageTextTemplateSplit.isml */
    &.image-text-split, &.video-text-split {
        &.cta-top {
            display: flex;

            .text-container {
                position: relative;
            }

            .lca-text-content {
                position: absolute;
                width: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: var(--cta-bg);

                &.blur {
                    backdrop-filter: blur(var(--blur-amt));
                }
            }

            &.mob-top {
                flex-direction: column-reverse;
            }

            &.mob-center {
                @include media-breakpoint-down(sm) {
                    @include imageTextCenter;
                }
            }

            &.mob-bottom {
                flex-direction: column;
            }
        }
    }

    &.video-text-split {
        &.cta-top.no-cta-bg {
            overflow: hidden;

            .text-container {
                position: absolute;
                height: calc(100% - $content-tile-padding);
                width: 100%;
                top: 0;
            }

            .lca-text-content {
                align-items: center;
                width: 101%;
            }

            &[class*='-left'], &[class*='-right'] {
                @include media-breakpoint-up(md) {
                    // Use margin instead of padding for bottom spacing -- this is so we can overflow the overlay to eliminate any sub-pixel rounding issues.
                    padding-bottom: 0;
                    margin-bottom: $content-tile-padding;

                    .text-container {
                        height: 101%;
                        top: -0.5%;
                    }
                }

                .lca-text-content {
                    transform: translateY(-50%);
                    display: flex;
                    justify-content: center;
                    @include media-breakpoint-up(md) {
                        height: 100%;
                        width: calc(var(--text-width-override) + rem(1));
                    }
                }
            }

            &[class*='-left'] {
                .lca-text-content {
                    left: 0;
                    right: auto;
                }
            }

            &[class*='-right'] {
                .lca-text-content {
                    left: auto;
                    right: rem(-1);
                }
            }

            @include media-breakpoint-up(md) {
                &[class*='top-']:not(.top-center) {
                    .lca-text-content {
                        align-items: flex-start;
                        transform: none;
                    }
                }

                &[class*='bottom-']:not(.bottom-center) {
                    .lca-text-content {
                        align-items: flex-end;
                        transform: none;
                    }
                }

                &.bottom-center {
                    .lca-text-content {
                        bottom: -1px;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm) {
            &.cta-top.no-cta-bg {
                &.mob-top {
                    .lca-text-content {
                        transform: none;
                        top: 0;
                        left: 0;
                    }
                }

                &.mob-center {
                    padding-bottom: $content-tile-padding;
                }

                &.mob-bottom {
                    .lca-text-content {
                        transform: none;
                        top: auto;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
            &.mob-top {
                flex-direction: column-reverse;
            }

            &.mob-center {
                @include media-breakpoint-down(sm) {
                    @include imageTextCenter;
                }
            }

            &.mob-bottom {
                flex-direction: column;
            }
        }

        .cta-link {
            text-align: center;
        }
        .text-container {
            position: relative;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        &.link-entire-module .play-video {
            display: none !important;
        }
    }
}

@include media-breakpoint-up(md) {
    .content-tile {
        &.cta-top {
            &.bottom-left {
                justify-content: flex-start;
                align-items: flex-end;
            }

            &.bottom-center {
                justify-content: center;
                align-items: flex-end;
            }

            &.bottom-right {
                justify-content: flex-end;
                align-items: flex-end;
            }

            &.middle-left {
                justify-content: flex-start;
                align-items: center;
            }

            &.middle-center {
                justify-content: center;
                align-items: center;
            }

            &.middle-right {
                justify-content: flex-end;
                align-items: center;
            }

            &.top-left {
                justify-content: flex-start;
                align-items: flex-start;
            }

            &.top-center {
                justify-content: center;
                align-items: flex-start;
            }

            &.top-right {
                justify-content: flex-end;
                align-items: flex-start;
            }

            /* For imageTextTemplateSplit.isml and videoTextTemplate.isml */
            &.image-text-split, &.video-text-split {
                &[class*='-left'] {
                    flex-direction: row-reverse;
                }

                &[class*='-right'] {
                    flex-direction: row;
                }

                &.middle-center, &.top-center, &.bottom-center {
                    @include imageTextCenter;
                }

                &[class*='middle-'] {
                    .lca-text-content {
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &[class*='top-'] {
                    .lca-text-content {
                        top: 0;
                        transform: translateX(-50%);
                    }
                }

                &[class*='bottom-'] {
                    .lca-text-content {
                        top: auto;
                        bottom: 0;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}
