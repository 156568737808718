// Slideout sheet styles
// grid-based version
.sheet {
    --lr-padding: #{$space-4xl};

    contain: paint;

    &-dialog.modal-dialog {
        display: flex;
        flex-direction: column;
        contain: paint;
        position: fixed;
        margin: auto;
        height: 100%;
        width: $sidenav-width-xs;
        right: -$sidenav-width-xs;
        box-shadow: 0 0 rem(16) rgba(0 0 0 / 15%);
        background-color: $sheet-primary-bg;
        pointer-events: initial;

        .show & {
            right: 0;
            bottom: 0;
        }

        // Bootstrap overrides -- need more specificity
        .modal.fade & {
            transform: translate3d(0%, 0, 0);
            transition: all $duration-default;
        }

        @include media-breakpoint-down(sm) {
            bottom: -120%;
            box-shadow: none;
            height: auto;
            right: 0;
            max-height: 85%;
            max-width: 100%;

            &.m-static-height {
                height: 100%;
            }

            &.m-full-height {
                height: 100%;
                max-height: 100%;
            }

            &.m-minvar { // do not use when sheet body has an iframe
                max-height: 100%;
                min-height: 85%;
            }
        }

        @include media-breakpoint-up(md) {
            width: $sidenav-width-md;
            right: -$sidenav-width-md;
            max-width: $sidenav-max-width-md;
        }

        @include media-breakpoint-up(lg) {
            width: $sidenav-width-lg;
            right: -$sidenav-width-lg;
            max-width: $sidenav-max-width-lg;
        }
    }

    &-page {
        height: 100%;
        min-height: 100%;
        display: grid;
        grid-template:
            '. title .' auto
            '. header .' auto
            '. content .' 1fr
            '. separator .' 1px
            '. footer .' auto
            / [full-bleed-start] var(--lr-padding) 1fr var(--lr-padding) [full-bleed-end];

        @include media-breakpoint-down(sm) {
            --lr-padding: #{$space-l};
        }

        > .full-bleed {
            grid-column: full-bleed;
        }

        // hide all but the first page by default
        ~ .sheet-page {
            display: none;
        }

        &.active {
            display: grid;
        }

        // Make the sheet title bar overlay the sheet content
        &.title-overlay {
            .sheet-title {
                z-index: 1;

                .close-current-modal {
                    backdrop-filter: blur(rem(8));
                    background: $sheet-close-bg;
                    border-radius: 50%;
                    height: $global-icon-link-size;
                    line-height: 0;
                    opacity: 1;
                    width: $global-icon-link-size;

                    &:hover {
                        background: $modal-nav-btn-bg-hover;
                        transition: background $duration-default ease;
                    }
                }
            }

            .sheet-body {
                margin-top: rem(-72);

                @include media-breakpoint-up(lg) {
                    margin-top: rem(-79);
                }
            }
        }

        &.title-hidden {
            .modal-header-title {
                visibility: hidden;
            }
        }
    }

    &-title {
        $button-padding: rem(10);

        grid-area: title;
        padding-block: $space-2xl;
        display: flex;
        justify-content: space-between;
        text-align: left;

        button {
            padding: $button-padding;
            display: flex;
            gap: $button-padding;

            // mobile + tablet should have a base height of 8 + padding
            margin: calc(-#{$button-padding} - rem(8.75)) -#{$button-padding};

            @include media-breakpoint-up(lg) {
                // desktop should have a base height of 15px + padding
                margin: calc(-#{$button-padding} - rem(4.75)) -#{$button-padding};
            }
        }

        .close-current-modal {
            backdrop-filter: blur(rem(8));
            background: $sheet-close-bg;
            border-radius: 50%;
            height: $global-icon-link-size;
            line-height: 0;
            opacity: 1;
            width: $global-icon-link-size;

            &:hover {
                background: $modal-nav-btn-bg-hover;
                transition: background $duration-default ease;
            }
        }

        &.m-close-accent .close-current-modal {
            background-color: $sheet-close-bg;

            &:hover {
                background-color: $sheet-close-bg-hover;
            }
        }

        svg {
            height: rem(24);
            width: rem(24);
        }

        .modal-header-title {
            flex-grow: 1;
        }
    }

    &-header {
        grid-area: header;
    }

    &-body {
        grid-area: content;
        overflow-y: hidden;
        position: relative;

        &-content {
            overflow-y: auto;
            height: 100%;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-content:not(.full-bleed) {
            padding-inline: var(--lr-padding);
        }

        &-end {
            display: block;
            height: 1px; // needed for the intersection observer used to show/hide the separator
        }

        &:has(.onetrust-preference-center) {
            .custom-scrollbar {
                display: none;
            }
        }
    }

    &-body-separator {
        grid-area: separator;
        width: 100%;
        border-top: rem(1) solid $divider-color;
    }

    &-footer {
        grid-area: footer;
        padding-block: $space-l;

        &-actions {
            display: flex;
            gap: $space-m;
            align-items: center;

            .btn {
                flex: 1 1 50%;
            }
        }
    }

    &-heading,
    h4,
    .modal-header-title {
        @include font-sans-semi-bold;

        font-size: rem(12);
        line-height: rem(24);
        letter-spacing: rem(0.36);
        text-transform: uppercase;
        margin: 0;
        text-align: left;
    }

    .modal-header-title {
        line-height: rem(8); // differs from the designs' 24px line height -- 24px conflicts with the fixed 8px height of the sheet title area

        @include media-breakpoint-up(lg) {
            line-height: rem(15); // 15px for desktop
        }
    }

    .modal-back .modal-header-title {
        line-height: inherit; // when the title is inside the back button, we DON'T want to adjust spacing, as the spacing is already baked into the margin
    }

    .modal-title {
        @include heading-h2;

        margin-bottom: $space-2xl;
        color: $dark-text;
        text-transform: none;
    }

    .modal-text {
        color: $dark-text;
    }

    .custom-scrollbar {
        background: $scrollbar-color-track;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: rem(1.5);
    }

      .custom-scrollbar-thumb {
        background: $scrollbar-color;
        height: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
