// Base font, color setting
body {
    @include body-16-light;

    background: $body-bg;
    color: $base-text-color;
    font-family: $sprig-sans;
    -webkit-font-smoothing: antialiased;
    font-synthesis: none;
}

@supports (font-variation-settings: normal) {
    body {
        font-family: $sprig-sans-variable;
    }
}

strong {
    color: $dark-text;
    font-weight: $font-weight-medium;
}

.svg-icon {
    pointer-events: none;
}

button {
    background: none;
    border: 0;
    color: $link-color;
}

a,
.button-link {
    @include link-underline;
}

*:focus-visible,
.custom-checkbox .custom-control-input:focus-visible + label,
.custom-radio .form-check-input:focus-visible + label {
    outline: rem(2) solid $primary-focus-color;
    outline-offset: rem(2);
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.bullet-list {
    padding-left: $space-2xl;

    li {
        border: none;
        padding: 0;
        position: relative;

        &::before {
            background-color: $base-text-color;
            border-radius: 50%;
            content: '';
            display: block;
            height: rem(3);
            left: -$space-l;
            position: absolute;
            top: rem(10);
            width: rem(3);
        }
    }
}

hr {
    border-color: $divider-color;
}

@include media-breakpoint-down(md) {
    .stylist-chat-overlay-bg {
        overflow: hidden !important;
    }

    .stylist-chat-overlay-bg .modal-background {
        overflow: hidden !important;
        display: block !important;
        background-color: $stylist-chat-overlay-bg-color;
        opacity: 0.8;
        touch-action: none !important;
        z-index: $zindex-highest;
    }

    html.avcHn2VQJenBvoR5hilPG.chat-widget-active:has(.input-focused) {
        overflow: hidden !important;
        touch-action: none !important;
        height: 100%;
        width: 100%;
        position: fixed;
    }
}

@include media-breakpoint-down(sm) {

    html.avcHn2VQJenBvoR5hilPG.chat-widget-active {
        position: fixed;
        overflow: hidden !important;
        touch-action: none !important;
    }
}
