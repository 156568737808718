.reset-password-container {
    margin-top: rem(80);

    .reset-password-title {
        h2 {
            @include font-serif-regular;
            line-height: rem(40);
            margin-bottom: 0;
        }
    }

    .reset-password-fields {
        margin-top: rem(40);
    }

    .btn-save {
        margin-top: rem(40);
    }

    @include media-breakpoint-up(md) {
        margin-top: rem(96);
    }
    .valid-password-info {
        padding-left: rem(13);
    }
    .valid-password-info-title, .valid-password-info {
        @include font-sans-regular;
        font-size: rem(12);
        letter-spacing: rem(0.4);
        line-height: rem(16);
        color: $emperor;
        &.padding-none {
            padding: 0;
        }
        li {
            padding: rem(4) 0;
        }
        .error-in-pattern, .matched-pattern {
            display: none;
            padding-right: rem(9);
        }
        .pwd-criteria-not-matched {
            color: $monza;
            list-style-type: none;
            .error-in-pattern {
                display: inline-block;
            }
            svg {
                fill:$monza;
            }
        }
        .pwd-criteria-matched {
            list-style-type: none;
            .matched-pattern {
                display: inline-block;
            }
        }
    }
}
