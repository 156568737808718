.btn {
    @include font-sans-medium;

    --btn-height: #{rem(58)};
    --btn-border-size: #{rem(1)};

    border: var(--btn-border-size) solid transparent;
    border-radius: 0;
    font-size: rem(12);
    height: var(--btn-height);
    letter-spacing: rem(0.36);
    line-height: calc(var(--btn-height) - 2 * var(--btn-border-size));
    padding: 0 $space-l;
    text-transform: uppercase;

    &:focus {
        box-shadow: none;
    }

    &:focus-visible {
        outline: rem(2) solid $primary-focus-color;
        outline-offset: rem(2);
    }

    &-small {
        --btn-height: #{rem(48)};
    }

    // custom button - primary
    &.button-primary {
        @include button(
            $bg: $primary-bg-color,
            $color: $primary-color,
            $border-color: $primary-bg-color,
            $hover-bg: $primary-hover-bg-color,
            $hover-color: $primary-color,
            $hover-border-color: $primary-active-bg-color,
            $active-bg: $primary-active-bg-color,
            $active-color: $primary-color,
            $active-border-color: $primary-active-bg-color
        );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $primary-disabled-bg-color, $color: $primary-disabled-color, $border-color: $primary-disabled-bg-color);
        }
    }

    // custom button - secondary
    &.button-secondary {
        @include button(
            $bg: $secondary-bg-color,
            $color: $secondary-color,
            $border-color: $secondary-bd-color,
            $hover-bg: $secondary-bg-color,
            $hover-border-color: $secondary-hover-bd-color,
            $active-bg: $secondary-bg-color,
            $active-border-color: $secondary-active-bd-color
        );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $secondary-disabled-bg-color, $color: $secondary-disabled-color, $border-color: $secondary-disabled-border-color);
        }
    }

    // button secondary, but default background color is transparent instead of solid
    &.button-secondary-transparent {
        @include button(
            $bg: transparent,
            $color: $secondary-color,
            $border-color: $secondary-bd-color,
            $hover-bg: transparent,
            $hover-border-color: $secondary-hover-bd-color,
            $active-bg: transparent,
            $active-border-color: $secondary-active-bd-color
        );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $secondary-disabled-bg-color, $color: $secondary-disabled-color, $border-color: $secondary-disabled-border-color);
        }
    }
}

.tertiary-link {
    @include font-sans-light;
    @include font-sans-14px;

    border-bottom: rem(1) solid $link-decoration-color;
    display: inline-block;
    padding: 0;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.icon-link {
    @include font-sans-light;
    text-decoration: none;

    &:hover {
        opacity: 0.67; // simulate $color-grey-dark without hardcoding the color
        text-decoration: none;
    }

    &::after {
        content: ' ›'; // guilsinglright &rsaquo;
    }
}
