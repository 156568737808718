.notify-me-container {
    .notify-form {
        position: relative;
    }

    .modal-title {
        max-width: rem(200);

        .lp-slideout-modal.modal & {
            margin-bottom: $space-m;
        }
    }

    a {
        color: $base-text-color;
        text-decoration: underline;
    }

    .form-group {
        margin-top: $space-m;
    }

    .notify-checkbox-label {
        color: $dark-text;
        font-size: rem(14);
        line-height: rem(22);
    }

    .notify-small-print {
        @include body-12-regular;

        margin: $space-m 0 $space-3xl;
    }

    .notify-me-success {
        p {
            margin-bottom: $space-m;
        }
    }
}
