.breadcrumb,
.container .breadcrumb {
    font-size: 0;
    margin: $space-s 0;
    padding: 0;

    @include media-breakpoint-up(lg) {
        margin: $space-l 0;
    }

    &-item {
        display: inline-block;
        height: rem(19);
        padding: 0;

        & + &::before {
            @include font-sans-regular;
            @include font-sans-12px;

            color: $breadcrumb-border-color;
            float: none;
            padding: 0 rem(7);
        }

        a,
        span {
            @include font-sans-regular;
            @include font-sans-12px;

            color: $breadcrumb-text-color;
            text-decoration: none;
        }

        a:hover {
            text-decoration: $link-decoration;
            text-decoration-color: $link-decoration-color-dark;
            text-underline-offset: rem(1);
            text-underline-position: under;
        }
    }
}

.ma-breadcrumbs,
.plp-breadcrumbs,
.pdp-breadcrumbs {
    @include grid-layout;

    @include media-breakpoint-down(sm) {
        padding-left: $space-l;
        padding-right: $space-l;
    }

    .breadcrumb {
        grid-column: 2 / span 13;

        @include media-breakpoint-down(sm) {
            grid-column: 1 / span 6;
        }
    }
}

.back-to-page-link,
.back-to-account-link {
    @include font-sans-regular;
    @include font-sans-12px;

    margin-top: rem(16);
    color: $breadcrumb-text-color;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-left: rem(16);

    &::after {
        width: rem(12);
        height: rem(12);
        content: '';
        border-top: 1px solid $breadcrumb-border-color;
        border-left: 1px solid $breadcrumb-border-color;
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: rem(2);
    }
}
