.video-template {
    margin-bottom: rem(104);

    .video-headings {
        height: rem(112);
    }

    .title,
    .subtitle {
        text-align: center;
        color: $video-component-title-color;
    }

    .title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include font-sans-semi-bold;
            font-size: rem(24);
            line-height: rem(32);
            letter-spacing: rem(2);
        }
    }

    .subtitle {
        @include font-serif-regular;
        font-size: rem(18);
        line-height: rem(24);
        letter-spacing: rem(0.6);
        margin-top: rem(16);
    }

    a:hover,
    a:active {
        text-decoration: none;
    }
}

.video-component {
    position: relative;
    display: block;
    justify-content: center;

    video {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .video-controls {
        bottom: $space-m;
        display: flex;
        flex-direction: column;
        gap: $space-s;
        position: absolute;
        right: $space-m;
        z-index: $zindex-medium;

        @include media-breakpoint-down(md) {
            bottom: $space-xl;
        }

        .shoppable-media & {
            @include media-breakpoint-up(lg) {
                left: $space-2xl;
                right: auto;
            }
        }

        .content-page-section & {
            flex-direction: row;
        }
    }

    &.video-PDP {
        .video-controls {
            bottom: rem(66);
        }
    }

    .play-video,
    .mute-video,
    .caption-video {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $space-s;
        background: rgba($shoppable-playbutton-background, 0.5);
        border-radius: 50%;

        svg {
            fill: $color-white;
            height: rem(16);
            width: rem(16);
        }
    }

    .mute-video {
        &.muted {
            .svg-video-mute {
                display: none;
            }
        }

        &.unmuted {
            .svg-video-unmute {
                display: none;
            }
        }
    }

    .play-video {
        .svg-video-pause {
            display: none;
        }

        &.played {
            .svg-video-play {
                display: none;
            }

            .svg-video-pause {
                display: block;
            }
        }
    }

    /*hiding play-video button while video playing*/
    .play-video.playing {
        display: none !important; //PDP video has d-block which is block !important so playing needs to have none !important
    }
}
