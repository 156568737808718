@import '../global/global';

.content-page {
    margin-inline: auto;
    max-width: $site-max-width;

    &-title {
        @include heading-h2;

        color: $headline-color;
        text-transform: capitalize;
    }

    &-subtitle {
        @include heading-h3;

        color: $headline-color;
        margin-bottom: $space-xl;
    }

    &-section {
        margin-bottom: $space-5xl;

        @include media-breakpoint-up(lg) {
            margin-bottom: $space-6xl;
        }

        &:first-child {
            margin-top: $space-4xl;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.narrow {
            margin-inline: grid-span(1);
            width: grid-span(10);

            @include media-breakpoint-up(md) {
                margin-left: grid-span(1);
                margin-right: grid-span(6);
                width: grid-span(8);
            }

            @include media-breakpoint-up(lg) {
                margin-left: grid-span(3);
                margin-right: grid-span(4);
            }
        }

        &.narrow-desktop {
            margin-inline: grid-span(1);

            @include media-breakpoint-up(lg) {
                margin-left: grid-span(3);
                margin-right: grid-span(3);
                width: grid-span(9);
            }
        }

        &.m-width-60-desktop {
            @include media-breakpoint-up(lg) {
                max-width: 60%;
            }
        }

        &.content-page-media {
            @media screen and (min-width: $page-grid-max-width) {
                width: 100vw;
                position: relative;
                left: calc((100vw - $page-grid-max-width) / -2);
            }
        }
    }

    &-textblock {
        @include body-16-light;

        &:not(:last-child) {
            margin-bottom: $space-5xl;
        }

        &-title {
            @include heading-h3;

            color: $headline-color;
        }

        &-button {
            margin-right: $space-s;
        }

        &.narrow {
            margin-right: grid-span(2);

            @include media-breakpoint-up(md) {
                margin-right: grid-span(6);
                width: grid-span(9);
            }

            @include media-breakpoint-up(lg) {
                margin-right: grid-span(3);
                width: grid-span(12);
            }
        }

        &.width-50-desktop {
            margin-right: grid-span(2);

            @include media-breakpoint-up(md) {
                margin-right: grid-span(1);
            }

            @include media-breakpoint-up(lg) {
                margin-right: grid-span(7);
                width: grid-span(8);
            }
        }

        &.m-width-60-desktop {
            margin-right: grid-span(2);

            @include media-breakpoint-up(md) {
                margin-right: grid-span(1);
            }

            @include media-breakpoint-up(lg) {
                margin-right: grid-span(6);
                width: grid-span(9);
            }
        }

        &.m-tablet-right-grid-1 {
            @include media-breakpoint-down(md) {
                margin-right: grid-span(1);
            }
        }

        &.m-mobile-right-grid-2 {
            @include media-breakpoint-down(sm) {
                margin-right: grid-span(2);
            }
        }

        .mode-70-30 & {
            margin-left: grid-span(1);

            @include media-breakpoint-up(lg) {
                margin-left: 0;
                width: 77%;
            }

            .content-page-textblock-title + p {
                margin-top: $space-s;
            }
        }

        .mode-25-4 & {
            margin-right: grid-span(1);
            margin-bottom: $space-2xl;

            @include media-breakpoint-up(md) {
                margin-right: 0;
                margin-bottom: 0;
                padding-right: grid-span(1);
                width: 25%;
            }

            @include media-breakpoint-up(lg) {
                padding-right: 0;

                p {
                    margin: 0 auto;
                    max-width: rem(280);
                }
            }
        }

        li {
            border-bottom: rem(1) solid $divider-color;
            padding: $space-m 0;

            &:first-child {
                padding-top: 0;
            }
        }

        p {
            margin-bottom: 0;
        }

        &-title + p {
            margin-top: $space-xl;
        }

        &-button + p {
            margin-top: $space-m;
        }

        p + p {
            margin-top: $space-xl;
        }

        p + &-button {
            margin-top: $space-2xl;
        }

        p + ul,
        p + ol {
            margin-top: $space-xl;

            @include media-breakpoint-up(md) {
                margin-top: $space-3xl;
            }
        }

        ul + &-button {
            margin-top: $space-2xl;

            @include media-breakpoint-up(md) {
                margin-top: $space-3xl;
            }
        }

        &-button + &-button {
            margin-top: $space-2xl;
        }

        p + .content-page-event-list,
        h3 + .content-page-event-list {
            margin-top: $space-l;

            @include media-breakpoint-up(md) {
                margin-top: $space-4xl;
            }
        }
    }

    &-title + &-textblock {
        margin-top: $space-xl;
    }

    &-title + &-section {
        margin-top: $space-5xl;

        @include media-breakpoint-up(md) {
            margin-top: $space-6xl;
        }
    }

    &-splitblock {
        padding: 0 grid-span(1);

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        &.mode-70-30 {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        &.mode-25-4 {
            @include media-breakpoint-up(md) {
                display: flex;
                padding-bottom: $space-2xl;
                padding-top: $space-l;
            }

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }

        &.mode-hero {
            padding: 0;

            .content-page-textblock p {
                margin-right: grid-span(1);

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                    margin-right: grid-span(6);
                    width: grid-span(8);
                }

                @include media-breakpoint-up(lg) {
                    margin-inline: 0;
                    width: 100%;
                }
            }
        }

        &-left {
            margin-bottom: $space-2xl;

            @include media-breakpoint-up(md) {
                margin-bottom: $space-4xl;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                width: grid-span(7);
            }

            .mode-70-30 & {
                margin-bottom: $space-3xl;

                @include media-breakpoint-up(lg) {
                    margin-bottom: $space-4xl;
                    width: grid-span(9);
                }
            }

            .mode-hero & {
                @include media-breakpoint-up(lg) {
                    align-items: center;
                    display: flex;
                    width: grid-span(10);
                }
            }
        }

        &-right {
            @include media-breakpoint-up(lg) {
                margin-left: grid-span(1);
                width: grid-span(7);
            }

            .mode-70-30 & {
                @include media-breakpoint-up(lg) {
                    width: grid-span(5);
                }
            }

            .mode-hero & {
                margin-inline: grid-span(1);

                @include media-breakpoint-up(lg) {
                    align-items: center;
                    display: flex;
                    width: grid-span(3);
                }
            }
        }
    }

    &-media {
        width: 100%;

        .content-page-grid & {
            &:nth-child(odd) {
                .content-page-media-title,
                .content-page-media-text {
                    @include media-breakpoint-down(sm) {
                        margin-left: $space-l;
                    }

                    @include media-breakpoint-down(md) {
                        margin-left: $space-2xl;
                    }
                }
            }

            &:first-child,
            &:nth-child(6) {
                .content-page-media-title,
                .content-page-media-text {
                    @include media-breakpoint-up(lg) {
                        margin-left: $space-2xl;
                    }
                }
            }
        }

        &-wrapper {
            aspect-ratio: 4/5;
            display: block;
            overflow: hidden;
            position: relative;
            width: 100%;

            @include media-breakpoint-up(md) {
                aspect-ratio: 4/3;
            }

            @include media-breakpoint-up(lg) {
                aspect-ratio: 16/9;
            }

            .content-page-splitblock & {
                aspect-ratio: 4/5;
            }

            .mode-70-30 & {
                aspect-ratio: 4/3;
            }

            .mode-hero & {
                aspect-ratio: 5/4;

                @include media-breakpoint-up(lg) {
                    aspect-ratio: 16/9;
                }
            }

            .content-page-grid & {
                aspect-ratio: 1/1;
            }

            img,
            video {
                bottom: 0;
                display: block;
                height: 100%;
                left: 0;
                object-fit: cover;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
            }
        }

        &-caption {
            @include body-14-light;

            margin: $space-m grid-span(1) 0;
            max-width: rem(285);

            @include media-breakpoint-up(md) {
                margin-left: grid-span(1);
                margin-right: grid-span(6);
            }

            @include media-breakpoint-up(lg) {
                margin-left: grid-span(3);
                margin-right: grid-span(4);
            }

            .content-page-splitblock & {
                margin-left: 0;
            }
        }

        &-title {
            @include body-14-regular;

            color: $headline-color;
            margin-bottom: 0;
            margin-top: $space-m;

            @include media-breakpoint-up(md) {
                max-width: rem(220);
            }

            @include media-breakpoint-up(lg) {
                margin-top: $space-l;
            }

            .content-page-grid & {
                @include body-14-medium;
            }
        }

        &-text {
            @include body-14-light;

            margin-bottom: 0;
            margin-right: $space-l;

            @include media-breakpoint-up(md) {
                margin-right: $space-2xl;
            }
        }
    }

    &-grid {
        display: grid;
        gap: $space-2xl $space-xs;
        grid-template-columns: repeat(2, 1fr);

        @include media-breakpoint-up(md) {
            row-gap: $space-xl;
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(5, 1fr);
            row-gap: $space-4xl;
        }

        &-title {
            margin-bottom: $space-2xl;
            margin-left: $space-l;

            @include media-breakpoint-only(md) {
                margin-bottom: $space-xl;
            }

            @include media-breakpoint-up(lg) {
                margin-left: $space-7xl;
            }
        }
    }

    /* Event list */
    &-event-list {
        .event-date {
            @include media-breakpoint-up(md) {
                display: inline-block;
                margin-bottom: $space-xs;
                vertical-align: top;
                width: 37%;
            }
        }

        .event-details {
            margin-right: grid-span(2);

            @include media-breakpoint-up(md) {
                display: inline-block;
                margin-right: grid-span(1);
                width: 55%;
            }

            @include media-breakpoint-up(lg) {
                margin-right: 0;
                width: 62%;
            }
        }

        .event-location {
            @include body-16-medium;

            color: $headline-color;
            margin-bottom: $space-xs;
        }

        .event-info {
            margin-top: 0;
        }

        + .btn-load-more {
            margin-top: $space-l;

            @include media-breakpoint-up(md) {
                margin-top: $space-4xl;
            }
        }

        li {
            padding: $space-xl 0;
        }

        li:first-child {
            padding-top: 0;
        }
    }

    /* FAQ section */
    &-faq-section {
        display: none;
        margin-bottom: 0;

        &.active {
            display: block;
        }

        &-navigation {
            -ms-overflow-style: none;
            border-bottom: rem(1) solid $divider-color;
            margin-top: $space-xl;
            overflow-x: auto;
            padding-bottom: $space-xl;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            &-list {
                @include body-14-light;

                display: flex;
            }

            a {
                color: $dark-text;
                display: inline-block;
                margin-right: $space-l;
                position: relative;
                text-decoration: none;
                white-space: nowrap;

                @include media-breakpoint-up(md) {
                    margin-right: $space-xl;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    &::after {
                        background-color: $color-black;
                        bottom: -$space-xl;
                        display: block;
                        content: '';
                        height: rem(1);
                        left: 0;
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }

        &-bottom {
            @include body-12-regular;

            @include media-breakpoint-up(lg) {
                max-width: 90%;
            }
        }

        .card-header .btn {
            color: $base-text-color;
        }

        .card-body {
            color: $dark-text;
        }
    }

    .heading-h2 {
        @include heading-h2;

        margin-bottom: 0;
    }

    .hidden {
        display: none;
        visibility: hidden;
    }

    .btn-full-width {
        width: 100%;
    }

    /* Club Lilly */
    &.content-page-club-lilly {
        .content-page-section {
            margin-bottom: $space-6xl;
            margin-top: 0;

            &.content-page-faq-section {
                margin-bottom: $space-m;

                .dashboard-faq-section & {
                    margin-bottom: 0;
                }

                .card-header {
                    margin-bottom: 0;
                }
            }
        }

        .content-page-faq-section-navigation {
            margin-bottom: $space-xl;

            @include media-breakpoint-only(md) {
                margin-bottom: $space-m;
            }
        }

        .content-page-textblock-title + p {
            margin-top: $space-s;

            @include media-breakpoint-up(lg) {
                margin-top: $space-m;
            }
        }

        .content-page-splitblock.mode-70-30 {
            .content-page-splitblock-left {
                margin-bottom: $space-3xl;

                @include media-breakpoint-up(md) {
                    margin-bottom: $space-5xl;
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }

            .content-page-textblock p {
                @include body-14-light;
            }
        }

        .content-page-splitblock.mode-hero {
            .content-page-splitblock-left {
                margin-bottom: $space-2xl;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }

            .content-page-textblock-title + p {
                margin-top: $space-m;

                @include media-breakpoint-up(lg) {
                    margin-top: $space-s;
                }
            }
        }

        .club-lilly-guest {
            margin-top: $space-2xl;

            .is-logged-in & {
                display: none;
            }
        }

        .club-lilly-non-member {
            display: none;
            margin-top: $space-2xl;

            .is-logged-in:not(.is-member) & {
                display: block;
            }
        }

        .club-lilly-member {
            display: none;
            margin-top: $space-2xl;

            .is-logged-in.is-member & {
                display: block;
            }
        }

        .club-lilly-download-app {
            padding-inline: grid-span(1);
        }
    }

    .content-header {
        @include body-16-medium;

        color: $headline-color;
    }
}

.m-guest .link-user {
    display:none!important;
}

.m-logged-in .link-guest {
    display:none!important;
}
