// Mini-tote/Tote wrappers
.promo-banner,
.discounts-section {
    display: none;

    // only display when an actual promo is active
    // without this, the layout gap applies to the empty element
    &:has(.promo-block-earned, .promo-block-approaching, .promo-block-generic) {
        display: block;
    }
}

.promo-block {
    @include font-sans-light;

    font-size: rem(14);
    letter-spacing: rem(-0.14);
    line-height: 160%;
    max-width: 100%;
    background: var(--global-promo-color, inherit);
    padding: $space-m $space-xl;
    display: none;
    text-align: left;
    color: $dark-text;

    &:has(.promo-block-earned, .promo-block-approaching, .promo-block-generic) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--icon-spacing, #{$space-m});
        width: 100%;
    }

    &-icon {
        flex-shrink: 0;
        height: rem(24);
        width: rem(24);
    }

    &-messages {
        display: flex;
        flex-direction: column;
        gap: $space-s;

        svg {
            flex-shrink: 0;
        }
    }

    &-cta-icon {
        width: rem(12);
        flex-shrink: 0;
    }

    &-generic {
        // hide generic message when earned or approaching message is available
        &:has(+ :is(.promo-block-earned, .promo-block-approaching)) {
            display: none;
        }
    }

    &-earned {
        @include font-sans-medium;
    }

    &-item {
        align-items: center;
        display: flex;

        &:not(:last-child) {
            margin-bottom: $space-s;
        }

        &:has(.promo-block-cta-icon):hover {
            cursor: pointer;
        }
    }

    &-link,
    &-link:hover {
        text-decoration: none;
    }
}
