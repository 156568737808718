// common:
#onetrust-consent-sdk {
    #onetrust-banner-sdk,
    #onetrust-pc-sdk,
    #ot-sdk-cookie-policy {
        * {
            border-radius: 0;
            box-sizing: border-box;
        }

        .ot-sdk-container {
            padding: 0 !important;
            width: 100% !important;
        }

        a,
        #onetrust-pc-btn-handler.cookie-setting-link {
            @include link-underline;
            @include body-14-light;

            background: none;
            height: auto;
            padding: 0;
            text-transform: none;
            transition: text-decoration-color .1s linear;

            &:hover,
            &:focus {
                background: none !important;
                color: inherit;
            }
        }

        p {
            @include body-14-light;
        }

        button {
            @include body-12-medium;

            height: rem(48);
            letter-spacing: rem(0.36);
            padding: 0 $space-l;
            text-transform: uppercase;
            white-space: nowrap;

            &:hover,
            &:focus {
                background-color: $primary-hover-bg-color !important;
                border-color:  $primary-hover-bg-color !important;
                opacity: 1;
                transition: background 0.1s linear;
            }
        }
    }
}

// banner:
#onetrust-consent-sdk #onetrust-banner-sdk {
    box-shadow: none;

    &:focus {
        outline: none !important;
    }

    .ot-sdk-row {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: rem(72) $page-grid-margin-sm $page-grid-margin-sm;

        @media (min-width: 550px) {
            padding: $space-xl rem(28) $space-xl $space-4xl;
        }

        @include media-breakpoint-up(lg) {
            flex-flow: row nowrap;
            padding: $space-xl rem(104) $space-xl $space-4xl;
        }
    }

    #onetrust-group-container.ot-sdk-columns.ot-sdk-eight,
    #onetrust-button-group-parent.ot-sdk-columns.ot-sdk-three {
        margin: 0;
        padding: 0;
        position: static;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }

    #onetrust-policy {
        margin: 0;
        padding: 0;

        &-title {
            @include font-serif-light;
            @include font-serif-24px;

            display: block;
            margin-bottom: $space-m;
        }

        &-text {
            @include body-14-light;

            @include media-breakpoint-up(lg) {
                padding-right: $space-2xl;
            }
        }
    }

    #onetrust-button-group-parent {
        display: flex;
        flex-flow: row wrap;
        flex: 1 0 100%;
        transform: none;

        @media (min-width: 550px) {
            flex: 0 0 auto;
            flex-flow: row nowrap;
        }

        #onetrust-button-group {
            margin: 0;
            width: 100% !important;

            @media (min-width: 550px) {
                margin-top: $space-m;
                text-align: right;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }

            button + button {
                margin-top: $space-m;

                @media (min-width: 550px) {
                    margin-left: $space-m;
                    margin-top: 0;
                }
            }

            button.cookie-setting-link {
                margin-top: $space-m;
                padding: rem(12) 0;

                @media (min-width: 550px) {
                    margin-right: $space-m;
                    margin-top: 0;
                    padding: 0;
                }

                &:focus {
                    outline: none !important;
                }
            }
        }

        #onetrust-pc-btn-handler,
        #onetrust-accept-btn-handler,
        #onetrust-reject-all-handler {
            margin: 0;
            min-width: 0;
            width: 100%;

            @media (min-width: 550px) {
                width: auto;
            }
        }
    }

    #onetrust-close-btn-container {
        line-height: 0;
        right: rem(8);
        top: rem(11.5);
        transform: none;

        @include media-breakpoint-up(lg) {
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

// close btn:
#onetrust-consent-sdk #onetrust-pc-sdk #close-pc-btn-handler.ot-close-icon,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-close-btn-container button.banner-close-button {
    background-color: $sheet-close-bg;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCA0NCA0NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMiAyMy41NjA3TDI4LjQ2OTcgMzAuMDMwNEwyOS41MzAzIDI4Ljk2OTdMMjMuMDYwNyAyMi41MDAxTDI5LjUzMDMgMTYuMDMwNEwyOC40Njk3IDE0Ljk2OTdMMjIgMjEuNDM5NEwxNS41MzAzIDE0Ljk2OTdMMTQuNDY5NyAxNi4wMzA0TDIwLjkzOTMgMjIuNTAwMUwxNC40Njk3IDI4Ljk2OTdMMTUuNTMwMyAzMC4wMzA0TDIyIDIzLjU2MDdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: $global-icon-link-size !important;
    backdrop-filter: blur(rem(8));
    border-radius: 50%;
    height: $global-icon-link-size;
    line-height: 0;
    padding: 0;
    position: static;
    width: $global-icon-link-size;

    &:hover,
    &:focus {
        background-color: $sheet-close-bg-hover !important;
        outline: none;
        transition: background-color $duration-default ease;
    }
}

#onetrust-consent-sdk #onetrust-pc-sdk #close-pc-btn-handler.ot-close-icon {
    position: absolute;
    right: rem(8);
    top: rem(11.5);
    z-index: $zindex-lowest;

    @include media-breakpoint-up(md) {
        right: rem(28);
    }
}

// preferences:
#onetrust-consent-sdk {
    #onetrust-pc-sdk {
        @include media-breakpoint-up(md) {
            max-width: rem(459);
            min-width: rem(459);
        }

        @include media-breakpoint-up(lg) {
            max-width: rem(720);
            min-width: rem(720);
        }

        .ot-pc-logo,
        .ot-hlst-cntr,
        .ot-label-status {
            display: none;
        }

        .ot-pc-header {
            border-bottom: 0;
            height: 0;
            padding: 0;
        }

        .ot-pc-scrollbar {
            @include scroll-custom;

            overflow-x: hidden;
        }

        #ot-pc-content,
        #ot-pc-lst {
            margin: 0;
            padding: rem(72) $page-grid-margin-sm $space-2xl;
            top: 0;
            width: 100%;

            @include media-breakpoint-up(md) {
                padding: rem(72) $space-4xl $space-4xl;
            }
        }

        h2#ot-pc-title {
            @include font-serif-extra-light;
            @include font-serif-28px;

            margin-bottom: $space-xl;
            margin-top: 0;

            @include media-breakpoint-up(md) {
                @include font-serif-32px;

                margin-bottom: $space-2xl;
            }
        }

        #ot-pc-desc {
            @include body-14-light;

            margin-bottom: $space-2xl;
        }

        #accept-recommended-btn-handler {
            margin-bottom: $space-4xl;
        }

        h3#ot-category-title {
            @include font-serif-light;
            @include font-serif-20px;

            margin-bottom: $space-m;
            padding: 0;
        }

        .ot-accordion-layout.ot-cat-item {
            border: 0;
            border-bottom: rem(1) solid $divider-color;
            margin: 0;
            padding: $space-s 0;
            width: 100%;

            &:first-of-type { 
                border-top: 0;
            }

            .ot-acc-hdr {
                padding: 0;
                min-height: 0;
            }

            h4.ot-cat-header {
                @include  body-16-light;

                color: $base-text-color;
                padding: $space-m 0;
                position: relative;
                margin: 0;
            }

            button {
                height: 100%;
                opacity: 0;

                &:focus-visible + .ot-acc-hdr h4.ot-cat-header::before {
                    content: '';
                    display: block;
                    height: 72%;
                    left: 0;
                    outline-offset: rem(3);
                    outline: rem(1) solid $color-black;
                    position: absolute;
                    right: 0;
                    top: 12%;
                }
            }

            .ot-always-active {
                @include body-14-light;

                color: $dark-text;
                margin-left: rem(30);
            }

            .ot-arw {
                color: $dark-text;
                height: rem(14);
                width: rem(14);
            }

            .ot-arw-cntr {
                svg {
                    transform: rotate(90deg);
                }
            }

            .ot-acc-txt {
                display: block !important;
                height: auto !important;
                overflow: hidden !important;
                transition: max-height 0.4s cubic-bezier(0, 1, 0, 1), visibility 0.1s cubic-bezier(0, 1, 0, 1) !important;
                width: 100%;

                .ot-category-desc {
                    width: 100%;
                }
            }

            > button[aria-expanded=false] ~ .ot-acc-txt {
                max-height: 0;
                visibility: hidden;
            }

            > button[aria-expanded=true] ~ .ot-acc-txt {
                max-height: rem(9999);
                visibility: visible;
            }

            input:checked ~ .ot-acc-hdr .ot-arw,
            button[aria-expanded=true] ~ .ot-acc-hdr .ot-arw-cntr svg {
                transform: rotate(-90deg);
            }

            .ot-acc-grpdesc {
                @include body-14-light;

                padding: 0 0 $space-m;
            }
        }

        .ot-pc-footer {
            border-top: rem(1) solid $divider-color;
            max-height: none;

            &-logo {
                background: transparent;
                height: auto;
                margin: 0;
                padding: 0 $page-grid-margin-sm $space-l;
                text-align: right;

                @include media-breakpoint-up(md) {
                    padding: 0 $space-4xl  $space-l;
                }

                a {
                    display: inline-block;
                    height: auto;
                    line-height: 0;
                    margin: 0;
                    padding: 0;
                    text-align: right;
                    width: auto;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .ot-btn-container {
                display: flex;
                flex-flow: row wrap;
                gap: $space-m;
                margin: 0;
                padding: $space-l $page-grid-margin-sm $space-m;

                @include media-breakpoint-up(md) {
                    padding: $space-l $space-4xl $space-m;
                }

                @include media-breakpoint-up(lg) {
                    flex-flow: row nowrap;
                }

                button {
                    margin: 0;
                    max-width: none;
                    min-width: 0;
                    width: 100%;
                }
            }
        }

        .ot-tgl {
                font-size: 0;
                line-height: 0;

                .category-switch-handler:focus-visible + .ot-switch {
                    outline: rem(1) solid $color-black;
                    outline-offset: rem(5);
                }
            }

            .ot-switch {
                height: rem(16);
                margin-top: $space-2xs;
                width: rem(28);

                &-nob,
                &-nob:active {
                    background: $toggle-off-bg;
                    border: 0 !important;
                    border-radius: 20px !important;
                    height: rem(16);
                    outline: none !important;
                    width: rem(28);

                    &::before {
                        background: $toggle-knob;
                        border: 0;
                        border-radius: 50%;
                        bottom: 0;
                        height: rem(12);
                        margin-bottom: $space-2xs;
                        transform: translateX(0);
                        width: rem(12);
                    }
                }
            }

            input:checked + .ot-switch .ot-switch-nob {
                background: $toggle-on-bg !important;
                border: 0 !important;

                &::before {
                    background: $toggle-knob;
                    transform: translateX(rem(11));
                }
            }

        // US National Template specific css:
        #ot-suffix-content {
            @include body-14-light;

            margin-top: $space-2xl;

            a {
                display: inline !important;
            }
        }
    }
}

// Opt-out modal with form in iframe (US):
#onetrust-privacy-dialog {
    .lp-slideout-modal.modal {
        .onetrust-dialog-cookie-banner-text {
            @include body-14-light;

            color: $dark-text;

            button.cookie-preferences {
                @include link-underline;

                color: $base-text-color;
                padding: 0;
            }
        }

        .modal-dialog {
            background: $body-bg;
            height: 100%;
            max-height: 100%;

            @include media-breakpoint-up(md) {
                max-width: rem(459);
                min-width: rem(459);
            }

            @include media-breakpoint-up(lg) {
                max-width: rem(720);
                min-width: rem(720);
            }

            .modal-header {
                display: contents;

                h4 {
                    @include font-serif-extra-light;
                    @include font-serif-28px;

                    color: $dark-text;
                    padding: rem(72) $page-grid-margin-sm $space-s;

                    @include media-breakpoint-up(md) {
                        @include font-serif-32px;

                        padding: rem(72) $space-4xl $space-s;
                    }
                }
            }

            .close {
                backdrop-filter: blur(rem(8));
                background: $sheet-close-bg;
                border-radius: 50%;
                height: $global-icon-link-size;
                margin: rem(11.5) $space-s 0 0;
                opacity: 1;
                pointer-events: all;
                position: absolute;
                right: 0;
                top: 0;
                width: $global-icon-link-size;
                z-index: $zindex-max-highest + 1;

                &:hover {
                    background: $modal-nav-btn-bg-hover;
                    transition: background $duration-default ease;
                }

                @include media-breakpoint-up(md) {
                    margin-right: rem(28);
                }
            }
    
            iframe {
                border: 0;
                bottom: 0;
                height: calc(100% - rem(80)) !important;
                inset: 0;
                padding: 0;
                position: absolute;
                top: rem(80);
                width: 100%;

                @include media-breakpoint-up(md) {
                    padding: 0 0 0 rem(18);
                }
            }
        }
    }
}