.form-group.custom,
.input-group.custom {
    margin-bottom: 0;
    margin-top: rem(8); // spacing-s
    position: relative;

    .form-control {
        @include body-16-light;
        background-color: transparent;
        border: rem(1) solid $form-control-border-color;
        border-radius: 0;
        box-shadow: none;
        display: block;
        float: inherit;
        height: $global-input-height;
        margin-bottom: 0;
        padding: $space-m;
        padding-top: rem(35);
        position: relative;
        width: 100%;
        z-index: inherit;
        color: $form-control-text-color;

        &::placeholder {
            font-size: rem(16);
            line-height: rem(20);
        }

        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;
        }

        &.is-invalid {
            background-image: none;
        }

        &:disabled {
            background-color: $color-grey-light;
        }

        &:has(~ .toggle-password) {
            padding-right: $space-2xl;
        }
    }

    label {
        @include font-sans-light;
        position: absolute;
        top: rem(11);
        color: $form-control-label-color;
        font-size: rem(12);
        line-height: rem(21);
        letter-spacing: -#{rem(0.16)};
        transition: 0.2s ease;
        transition-property: top, font-size;
    }

    .float-label {
        @include body-16-light;
        left: $space-m;
        color: $base-text-color;
        line-height: 1;
        pointer-events: none;
        top: rem(29);
        width: calc(100% - $space-m);
    }

    label[for='email-guest'] {
        top: rem(28);
    }

    /* active state */
    input:focus ~ .float-label,
    input:not(:placeholder-shown) ~ .float-label {
        top: $space-s;
        @include body-12-regular;
    }

    input.mask:placeholder-shown::placeholder {
        opacity: 0;
    }

    input:autofill ~ .float-label {
        top: rem(11);
        font-size: rem(12);
    }

    input:-webkit-autofill ~ .float-label {
        top: rem(11);
        font-size: rem(12);
    }

    @supports (-ms-ime-align: auto) {
        .form-control:focus ~ .float-label,
        .form-control:valid ~ .float-label {
            top: rem(11);
            font-size: rem(12);
        }
    }

    /* validation error  */
    .form-control.is-invalid {
        border-color: $error-border-color;
    }

    .invalid-feedback {
        margin-top: $space-s;
        @include body-12-regular;
        color: $error-text-color;

        &::before {
            content: $icon-info-base;
            margin-right: $space-xs;
            position: relative;
            top: rem(2.5);
        }
    }

    // Selects
    .custom-select-wrapper {
        border: rem(1) solid $form-control-border-color;

        &:focus-within {
            border-color: $form-control-focused-border-color;
        }

        select.m-floating-label {
            opacity: 0;
            padding-top: $space-2xl;
        }

        select:-webkit-autofill,
        select.m-floating-label.m-filled,
        select.m-floating-label.m-changed {
            opacity: 1;

            & ~ .float-label {
                top: rem(11);
                font-size: rem(12);
            }
        }

        #billingAddressSelector ~ label[for='billingAddressSelector'] {
            top: 0;
        }

        .custom-select {
            height: rem(70);
            border: none;
            background: none;
            padding-top: $space-m;
        }

        &::after {
            content: '';
            position: absolute;
            top: rem(33);
            right: rem(19);
            width: rem(5);
            height: rem(5);
            border-bottom: rem(1) solid $select-icon-border-color;
            border-right: rem(1) solid $select-icon-border-color;
            transform: rotate(45deg);
        }
    }

    &.required .form-control-label::before {
        content: '';
    }
}

.billing-address-block {
    #billingAddressSelector {
        opacity: 1;
        min-height: rem(72);
        padding-top: $space-xl;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;

        @include media-breakpoint-down(sm) {
            width: rem(348);
            padding-bottom: 0;
        }
    }

    .input-group.custom,
    .form-group.custom {
        @include body-16-light;
        margin-top: $space-m;

        .form-control-label {
            overflow: hidden;
            text-overflow: ellipsis;
            top: rem(25.6);
        }

        .invalid-feedback {
            margin-top: $space-s;
            @include body-12-regular;
        }

        .form-control {
            @include body-16-light;
        }

        label[for^='billingAddressSelector'] {
            top: 0;
            padding-top: $space-s;
            @include body-12-regular;
        }
    }

    .fpo-info-group {
        display: none;
    }

    .col-6.right-col {
        padding-left: 0 !important;
    }

    .col-6.left-col {
        padding-right: $space-s !important;
    }

    @include media-breakpoint-up(md) {
        .right-col {
            padding-left: 0 !important;
        }

        .left-col {
            padding-right: $space-s !important;
        }
    }
    .input-group.custom,
    .form-group.custom {
        margin-top: $space-s;

        @include media-breakpoint-up(sm) {
            margin-top: $space-m;
        }
    }
}

.shipping-form {
    .shipping-address {
        #shipmentSelector-default {
            opacity: 1;
            min-height: rem(76);
            padding-top: $space-m;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media-breakpoint-down(sm) {
                width: rem(290);
                padding-bottom: 0;
            }
        }
    }

    .col-6.right-col {
        padding-left: 0 !important;
    }

    .col-6.left-col {
        padding-right: $space-s !important;
    }

    @include media-breakpoint-up(md) {
        .right-col {
            padding-left: 0 !important;
        }

        .left-col {
            padding-right: $space-s !important;
        }
    }

    @include media-breakpoint-up(lg) {
        .right-col {
            padding-left: 0 !important;
        }

        .left-col {
            padding-right: $space-m !important;
        }

        .col-6.right-col {
            padding-left: 0 !important;
        }

        .col-6.left-col {
            padding-right: $space-m !important;
        }
    }
}

.sheet-page {
    &.is-invalid {
        .sheet-footer .invalid-feedback {
            display: block;
        }
    }
    .sheet-footer .invalid-feedback {
        @include body-12-regular;
        margin-top: $space-m;
        color: $error-text-color;
        text-align: left;
    }
}

.shipping-address {
    .input-group.custom,
    .form-group.custom {
        @include body-16-light;
        margin-top: $space-m;

        .form-control-label {
            overflow: hidden;
            text-overflow: ellipsis;
            top: rem(27.2);
        }

        .invalid-feedback {
            margin-top: $space-s;
            @include body-12-regular;
        }

        .form-control {
            @include body-16-light;
        }

        label[for^='shipmentSelector'] {
            top: 0;
            padding-left: $space-m;
            padding-top: $space-s;
        }
    }

    .input-group.custom {
        margin-top: $space-m;

        &:has(input.shippingPhoneNumber) {
            align-items: center;
            color: $dark-text;
            border: none;
        }

        .shipping-phone-number-container {
            border: rem(1) solid $form-control-border-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .shippingPhoneNumber {
                border: none;
                width: 100%;
            }
        }

        .shipping-phone-number-container:has(.shippingPhoneNumber.is-invalid) {
            border: rem(1) solid $error-text-color !important;

            & ~ .invalid-feedback {
                display: block;
            }
        }
    }

    .custom-info-icon {
        svg {
            width: rem(12);
            height: rem(12);
        }
    }

    .custom-select-wrapper:has(.shippingState.is-invalid) {
        border: rem(1) solid $error-text-color !important;

        & ~ .invalid-feedback {
            display: block;
        }
    }

    .gift-message {
        .invalid-feedback {
            @include body-12-regular;
            margin-top: rem(11);

            &::before {
                content: $icon-info-base;
                margin-right: $space-xs;
                position: relative;
                top: rem(2.5);
            }
        }
    }
}

.checkout-section {
    .shipping-address-block {
        .input-group.custom,
        .form-group.custom {
            margin-top: $space-s;

            @include media-breakpoint-up(sm) {
                margin-top: $space-m;
            }
        }
    }

    .custom-select-wrapper,
    .input-group.custom {
        margin-top: $space-m;

        &:has(select.cc-expiration),
        &:has(input.securityCode),
        &:has(input.contact-details-phone-number) {
            align-items: center;
            color: $dark-text;
            border: none;
        }

        .security-code-container,
        .contact-details-phone-number-container {
            border: rem(1) solid $form-control-border-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .shippingPhoneNumber {
                border: none;
                width: 100%;
            }

            .saved-payment-security-code {
                border: none;
            }
        }

        .security-code-container:has(.securityCode.is-invalid),
        .contact-details-phone-number-container:has(.contact-details-phone-number.is-invalid) {
            border: rem(1) solid $error-text-color !important;

            & ~ .invalid-feedback {
                display: block;
            }
        }
        .custom-info-icon {
            border: none;
            svg {
                width: rem(12);
                height: rem(12);
            }
        }
        .saved-payment-security-code {
            width: 100%;
        }
    }

    input {
        &.saved-payment-security-code:placeholder-shown::placeholder,
        &.securityCode:placeholder-shown::placeholder {
            opacity: 0;
        }
    }
}

.customer-information-block {
    .form-group.custom {
        margin-top: $space-m;

        @include media-breakpoint-up(sm) {
            padding-right: $space-l;
        }
    }
}

.terms-conditions {
    @include font-sans-12px;
}

.input-group.custom {
    .form-control {
        width: 1%;
    }
}

footer hr {
    margin-top: rem(24);
    margin-bottom: rem(24);
}

.footer-email-signup label[for='email-signup']::after {
    content: '*';
}

.shipping-footer-banner.row {
    margin-bottom: rem(-24);
}

/* custom amount field */
.custom-amount {
    label {
        @include font-sans-semi-bold;
        font-size: rem(14);
        line-height: rem(32);
        letter-spacing: rem(0.4);
        margin-bottom: rem(16);
    }

    .form-control {
        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;
        }

        &.is-invalid {
            border: rem(1) solid $error-border-color;
            color: $error-text-color;
            background-image: none;
        }

        &::placeholder {
            font-size: rem(14);
        }
    }
}

/* custom textarea */
.custom-textarea {
    margin-top: rem(24);
    position: relative;

    label {
        // Should not be according to designs
        @include font-sans-light;
        color: $form-control-label-color;
        font-size: rem(12);
        letter-spacing: -#{rem(0.16)};
        line-height: rem(21);
        margin-bottom: rem(8);
    }

    .custom-textarea-input {
        border: rem(1) solid $form-control-border-color;
        border-radius: 0;
        color: $form-control-text-color;
        display: block;
        resize: none;

        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;
        }

        &.is-invalid {
            border-color: $error-border-color;

            & + .char-count {
                color: $error-text-color;
            }
        }
    }
}

/* custom checkbox */
.custom-checkbox {
    .custom-control-label {
        @include font-sans-light;
        @include font-sans-16px;

        &::before {
            background: $custom-control-background-color;
            border: rem(1) solid $custom-control-border-color;
            border-radius: rem(2);
        }
    }

    .custom-control-input {
        height: rem(18);
        left: rem(-1);
        top: rem(4);
        width: rem(18);
        z-index: $zindex-low;

        &:hover {
            cursor: pointer;
        }

        &:hover:not(:disabled):not(:checked) ~ .custom-control-label::before {
            border-color: $checkbox-checked-border-color;
        }

        &:checked ~ .custom-control-label::before {
            background-color: $checkbox-checked-background-color;
            border-color: $checkbox-checked-border-color;
        }

        &:checked ~ .custom-control-label::after {
            // we're displaying a larger-than-usual indicator in the default box size, which bootstrap seems to have issues with. The offset corrects horizontal positioning.
            --custom-control-indicator-size: #{rem(20)};
            --custom-control-indicator-horiz-offset: #{rem(2)};

            // indicator SVG should be square/1:1 aspect ratio, otherwise it won't be vertically centered
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.707 7.561'><path d='M.354,3.354l3.5,3.5L10.354.354' style='fill:none; stroke:%23fff;'/></svg>");
        }

        &:focus ~ .custom-control-label::before {
            border-color: $checkbox-checked-border-color;
            box-shadow: none;
        }

        &:focus-visible ~ .custom-control-label::before {
            border-color: $checkbox-checked-border-color;
            box-shadow: none;
            outline: rem(2) solid $checkbox-focus-outline-color;
            outline-offset: rem(2);
        }

        &:not(:disabled):active ~ .custom-control-label::before {
            color: $custom-control-background-color;
            background-color: $checkbox-disabled-background-color;
            border-color: $checkbox-disabled-border-color;
        }

        &:focus + label {
            outline: none;
        }

        &.is-invalid {
            border-color: $error-border-color;

            & ~ .custom-control-label {
                color: $error-border-color;
            }
        }
    }

    .invalid-feedback {
        margin-top: rem(11);
        font-size: rem(12);
        line-height: rem(16);
        color: $error-text-color;
        font-weight: $font-weight-regular;

        &::before {
            font-weight: $font-weight-regular;
            content: 'i';
            display: inline-block;
            border: rem(1) solid;
            border-radius: rem(10);
            font-size: rem(9);
            width: rem(11);
            text-align: center;
            height: rem(11);
            line-height: rem(11);
            margin-right: rem(6);
        }
    }
}

/* custom radio */
.custom-radio {
    position: relative;
    margin-top: rem(18);

    .form-check-label {
        @include font-sans-light;
        font-size: rem(16);
        letter-spacing: rem(-0.16);
        line-height: rem(24);
        padding-left: rem(38);

        .svg-icon {
            margin-right: rem(4);
            vertical-align: bottom;
        }

        &::before {
            background-color: $radio-background-color;
            background-image: none;
            border: rem(1) solid $radio-border-color;
            border-radius: rem(18);
            content: '';
            cursor: pointer;
            height: rem(18);
            left: 0;
            position: absolute;
            top: rem(2);
            width: rem(18);
        }

        &:hover:not(:disabled)::before {
            border-color: $radio-active-border-color;
        }
    }

    .form-check-input {
        margin-left: 0;
        margin-top: rem(2);
        opacity: 0;

        &:focus + label {
            outline: none;
        }

        &:focus-visible ~ .form-check-label::before {
            outline: rem(2) solid $radio-focus-outline-color;
            outline-offset: rem(2);
        }

        &:checked ~ .form-check-label {
            @include font-sans-light;

            &::after {
                background-color: $radio-dot-color;
                border-radius: rem(10);
                content: '';
                height: rem(10);
                left: rem(4);
                position: absolute;
                top: rem(6);
                width: rem(10);
            }

            &::before {
                border-color: $radio-active-border-color;
                border-width: rem(2);
            }
        }

        &:disabled ~ .form-check-label {
            &::before {
                background-color: $radio-disabled-background-color;
            }
        }
    }
}

/* credit card form styles */
select.expirationYear:not([value='']) ~ .float-label,
select.expirationMonth:not([value='']) ~ .float-label,
select.radius:not([value='']) ~ .float-label {
    top: rem(8);
    font-size: rem(12);
    color: $form-control-label-color;
    line-height: rem(16);
}

.credit-card-form,
.payment-form {
    select.expirationYear:not([value='']) ~ .float-label,
    select.expirationMonth:not([value='']) ~ .float-label {
        @include body-16-light;
        top: rem(25);
    }

    .custom-select-wrapper {
        margin-top: 0;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Billing address address selector */
.address-selector-block .form-group.custom .custom-select-wrapper .custom-select.addressSelector {
    padding-bottom: rem(16);
    height: rem(72);
    // border-top: rem(1) solid $billing-address-border-color;
    margin-top: rem(16);
    white-space: normal;
    @include media-breakpoint-up(md) {
        border-top: 0;
        margin-top: 0;
        white-space: nowrap;
    }
}

.mask {
    font-family: $text-security-disc !important;

    // to avoid unexpected behavior for iOs devices
    @include media-breakpoint-up(lg) {
        -webkit-text-security: disc !important;
    }
}

.payment-form-fields {
    .credit-card-form {
        .form-group .custom-select-wrapper {
            .custom-select {
                &.expirationMonth,
                &.expirationYear {
                    opacity: 1;
                    color: $light-text;
                    border: rem(1) solid $form-control-border-color;

                    &.m-changed {
                        color: $dark-text;
                    }
                }
                option {
                    color: $dark-text;
                }
            }
        }
    }
}