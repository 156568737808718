.scrollable-container {
    .scrollable-content-wrapper {
        position: relative;
    }

    .scrollable-content {
        @include scroll-custom;

        display: flex;
        flex-wrap: nowrap;
        margin: $space-2xl 0 0;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100vw;

        .image-container {
            aspect-ratio: unset;
            min-height: auto;

            .swiper-slide {
                aspect-ratio: 4/5;
            }

            .swiper-bottom {
                display: none;
            }

            .swiper-button {
                opacity: 0;
                z-index: $zindex-negative;
            }

            &:hover .swiper-button {
                @include media-breakpoint-up(lg) {
                    opacity: 1;
                    z-index: $zindex-lowest;
                }
            }
        }

        .grid-tile {
            padding-bottom: $space-3xl;
            padding-right: $space-xs;
            width: 50%;

            @include media-breakpoint-up(md) {
                width: grid-span(5);
            }

            @include media-breakpoint-up(xl) {
                padding-bottom: 0;
                width: 20%;
            }

            &:last-of-type {
                padding-right: 0;
            }

            .product-tile .tile-body {
                padding: $space-m $space-l 0;

                @include media-breakpoint-up(md) {
                    padding: $space-m $space-2xl 0;
                }

                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                }
            }

            &:nth-of-type(5n + 1) .product-tile .tile-body {
                @include media-breakpoint-up(lg) {
                    padding-left: $space-2xl;
                }
            }
        }
    }

    .scroll-left-arrow,
    .scroll-right-arrow {
        cursor: pointer;
        display: none;
        height: $global-icon-link-size;
        margin-bottom: auto;
        margin-top: 0;
        opacity: 0;
        position: absolute;
        top: rem(-76);
        transition: opacity $duration-default;
        width: $global-icon-link-size;

        @include media-breakpoint-up(xl) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.lp-icon-chevron {
            &::before {
                width: rem(11.3);
                height: rem(11.3);
                color: $dark-text;
            }
        }
    }

    .scroll-left-arrow {
        right: rem(148);
    }

    .scroll-right-arrow {
        right: rem(96);
    }

    @include media-breakpoint-up(md) {
        .scrollable-content {
            ~ .scroll-left-arrow,
            ~ .scroll-right-arrow {
                opacity: 1;
            }
        }

        .scroll-left-arrow,
        .scroll-right-arrow {
            opacity: 1;
        }
    }
}

@include media-breakpoint-up(md) {
    .homepage {
        .scroll-left-arrow,
        .scroll-right-arrow {
            margin-top: rem(-20);
        }
    }

    .ways-to-wear .sbs-carousel {
        .banner {
            padding-right: rem(16);
        }

        .scroll-left-arrow {
            left: rem(4);
        }

        .scroll-right-arrow {
            right: rem(4);
        }
    }
}
