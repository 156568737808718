.toggle-password {
    position: absolute;
    top: $space-xl;
    right: $space-s;
    opacity: 1;

    .eye-active-icon {
        display: none;
    }

    .eye-inactive-icon {
        display: inline-block;
    }

    &.show-password {
        .eye-active-icon {
            display: inline-block;
        }

        .eye-inactive-icon {
            display: none;
        }
    }
}

.toggle-password-input {
    &::-ms-reveal {
        visibility: hidden;
    }
}

.toggle-password-input {
    &::-webkit-textfield-decoration-container {
        visibility: hidden;
    }
}

.toggle-password-input {
    &::-ms-clear {
        visibility: hidden;
    }
}

.valid-password-info {
    @include body-12-regular;
    margin: $space-m 0;
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        padding-bottom: $space-xs;
        gap: $space-s;
        &::before {
            content: '';
            border: rem(1) solid $password-requirement-default-color;
            border-radius: 50%;
            width: rem(8);
            height: rem(8);
        }
    }

    .pwd-criteria-matched {
        &::before {
            border-color: $password-requirement-valid-color;
            background-color: $password-requirement-valid-color;
        }
    }
}
