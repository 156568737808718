.flyin {
    $spacer: rem(20);

    .modal-dialog {
        border: 0;
        border-radius: 0;
        height: auto;
        margin: auto;
        max-width: calc(100% - $spacer * 2);
        overflow-y: hidden;
        padding: $space-l 0;
        width: 100%;
        z-index: $zindex-max-highest;

        @include media-breakpoint-up(md) {
            max-width: rem(460);
        }

        @include media-breakpoint-up(lg) {
            max-width: rem(574);
        }
    }

    .modal-content {
        border: rem(1) solid $color-black;
        border-radius: 0;
        height: auto;
        padding: rem(72) $space-l $space-l;
        position: static;

        @include media-breakpoint-up(md) {
            padding-inline: $space-4xl;
        }
    }

    &-close {
        backdrop-filter: blur(rem(8));
        background: $sheet-close-bg;
        border-radius: 50%;
        height: $global-icon-link-size;
        line-height: 0;
        margin-right: $space-s;
        margin-top: rem(36);
        opacity: 1;
        padding: 0;
        pointer-events: all;
        position: absolute;
        right: 0;
        top: 0;
        width: $global-icon-link-size;
        z-index: $zindex-max-highest + 1;

        &:hover {
            background: $modal-nav-btn-bg-hover;
            transition: background $duration-default ease;
        }

        @include media-breakpoint-up(md) {
            margin-right: rem(36);
        }
    }

    &-title {
        @include font-serif-extra-light;
        @include font-serif-24px;

        @include media-breakpoint-up(lg) {
            @include font-serif-28px;
        }

        color: $dark-text;
        margin: $space-m 0;
    }

    &-form {
        margin-top: $space-2xl;
    }

    &-invalid-feedback {
        margin-bottom: $space-m;
    }

    &-terms {
        @include body-14-light;
    }

    &-centered {
        text-align: center;
    }

    &-nothanks {
        @include body-14-light;
        @include link-underline;

        margin: $space-m auto 0;
        padding: 0;
    }

    &-thanks {
        padding-bottom: $space-4xl;
    }

    p {
        margin: 0;

        &.flyin-terms {
            margin: $space-2xl 0;
        }

        &.narrow {
            max-width: rem(350);
        }
    }

    a {
        @include link-underline;

        color: inherit;
    }
}
