// Global common classes

.no-scroll {
    overflow: hidden;

    &:has(.search-open) {
        @include media-breakpoint-down(md) {
            inset: 0;
            position: fixed;
        }
    }
}

.text-underline {
    text-decoration: underline;
}

.font-sans,
.font-gotham-regular {
    @include font-sans-regular;
}

.font-serif,
.font-crimson {
    @include font-serif-regular;
}

.hide {
    display: none !important;
}

.hide-visually {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    outline: 0;
    padding: 0;
    position: absolute;
    width: 1px;
}

.show {
    display: var(--show-display, block) !important;
}

.flex {
    display: flex;
}

.zindex-0 {
    z-index: $zindex-zero;
}

.zindex-1 {
    z-index: $zindex-lowest;
}

.relative {
    position: relative;
}

.no-pointer-events {
    pointer-events: none;
}

.sticky-top-nav {
    position: sticky;
    top: rem(85);
    align-self: flex-start;
    transition: all $duration-default;
}

.margin-auto {
    margin: auto;
}

.no-underline {
    text-decoration: none !important;
}

.container.full-width {
    padding-left: 0;
    padding-right: 0;
}

.grid-main-layout {
    @include grid-layout;

    @include media-breakpoint-down(sm) {
        padding-left: $space-l;
        padding-right: $space-l;
    }

    &.full-width {
        @include media-breakpoint-down(sm) {
            padding-left: $space-l;
            padding-right: $space-l;
        }
    }
}

.lp-dot {
    display: inline-block;
    background-color: $store-locator-dot-color;
    width: $space-s;
    height: $space-s;
    border-radius: 50%;
    margin-right: $space-s;
}

#evg-corner-module {
    z-index: $zindex-low !important;
}
