.preferred-store-container {
    .preferred-store-content.modal-body {
        @include media-breakpoint-up(md) {
            padding: $space-m $space-4xl;
        }

        .store-search-form .form-group {
            margin-bottom: $space-s;
        }

        .detect-location {
            @include body-14-light;
            @include link-underline;

            display: inline-block;
            margin-top: (-$space-s);

            &.no-underline {
                text-decoration-color: transparent;
            }
        }

        .stores-count-message {
            padding: 0;
            border-bottom: 0;
        }

        .store-name {
            @include body-14-light;
            @include sans-family;
        }

        .store-mode {
            @include body-14-light;

            color: $dark-text;
            margin: $space-s 0;

            &::before {
                content: '';
                display: inline-block;
                width: $space-s;
                height: $space-s;
                margin-right: $space-s;
                border-radius: 50%;
                background-color: $store-available;
            }

            &.store-is-closed::before {
                background-color: $white;
                border: rem(1) solid $store-unavailable;
            }
        }

        .store-details {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            max-width: 100%;
            width: 100%;
        }

        .store-status {
            margin-bottom: 0;
        }

        .select-store-input-group {
            padding: rem(3);
        }

        .store-distance {
            @include body-12-regular;
        }

        .store-address {
            @include body-12-regular;

            margin-bottom: 0;
        }

        .storelocator-phone {
            @include body-12-regular;

            display: block;
            color: $base-text-color;
        }
    }

    .store-hours-container {
        color: $base-text-color;
        margin-top: $space-s;
    }

    .store-main-info {
        color: $base-text-color;

        p {
            margin: 0;
        }
    }

    .store-hours-list-item div:first-of-type {
        min-width: rem(74);
    }

    .btn-store-search {
        margin-top: $space-2xl;
    }

    .results-card:has(.store) {
        border-top: rem(1) solid $divider-color;
        padding-top: $space-2xl;
    }

    .preferred-store + .stores-count-message {
        margin-top: $space-2xl;
    }
}
