@keyframes spinner-rotate {
    0% {
        transform: rotate(0deg);
    }
    25%, 50% {
        transform: rotate(180deg);
    }
    75%, 100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner-fade-in {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes spinner-fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.veil {
    $spinner-size: rem(48);

    z-index: $zindex-medium;

    .underlay {
        background: rgba($body_bg, 0.5);
        opacity: 1;
    }

    .spinner {
        animation: none;

        font-size: 0;
        left: 50%;
        line-height: 0;
        margin-left: calc($spinner-size / 2 * -1);
        margin-top: calc($spinner-size / 2 * -1);
        position: absolute;
        text-align: center;
        top: 50%;
        transform-origin: center;

        height: rem(1);
        width: rem(1);
        margin-left: 0;
        margin-top: 0;
        opacity: 0;

        transition-property: height, width, margin, opacity;
        transition-duration: $duration-default;
        transition-timing-function: ease-out;
    }

    &.m-active .spinner {
        // 4.8s = 1.2s per each rotation frame and pause frame (2 rotations + 2 pauses)
        animation: spinner-rotate 4.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        height: $spinner-size;
        width: $spinner-size;
        margin-left: calc($spinner-size / 2 * -1);
        margin-top: calc($spinner-size / 2 * -1);
        opacity: 1;
    }

    body > & {
        z-index: $zindex-max-highest;

        .spinner {
            position: fixed;
        }
    }

    .sheet &,
    .modal & {
        z-index: $zindex-highest;
    }

    .sheet:has(&),
    .modal:has(&) {
        pointer-events: none;
    }
}

*.m-veiled {
    position: relative;
}
