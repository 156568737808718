@import '../global/global';

.checkout-login-title.page-title {
    @include font-serif-regular;
    position: static;
    color: $page-title-color;
    font-size: rem(32);
    line-height: rem(40);
    background-color: $custom-control-background-color;
    margin: rem(78) 0 rem(48) 0;
    padding: 0;

    &::before {
        background-color: $page-title-background-color;
    }
}

h1.page-title {
    @include font-serif-regular;
    position: static;
    color: $page-title-color;
    font-size: rem(32);
    line-height: rem(40);
    background-color: $custom-control-background-color;
    padding: 0;
    margin: rem(32) 0 rem(48);

    @include media-breakpoint-up(md) {
        margin: rem(24) 0 rem(32);
    }

    &::before {
        background-color: $page-title-background-color;
    }

    &.plp-title {
        @include font-sans-semi-bold;
        font-size: rem(24);
        line-height: rem(32);
        letter-spacing: rem(2);
        text-transform: uppercase;
    }
}
