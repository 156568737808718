@import 'productCard';

// when mini-cart empty messaging is displayed, we need it to take 100% height of the body section
#minicart {
    .sheet-title {
        color: $dark-text;
    }

    .mini-cart-contents {
        padding-bottom: $space-2xl;
    }

    &:has(.mini-cart-contents.d-none) {
        .sheet-body-content,
        .mini-cart-container {
            height: 100%;
        }

        .sheet-footer {
            display: none;
        }
    }
}

.minicart-footer {
    display: grid;
    gap: $space-l;

    .subtotal {
        display: flex;
        justify-content: space-between;

        &-label,
        &-price {
            @include body-16-light;
        }

        &-price {
            color: $dark-text;
        }
    }
}

.mini-cart-line-items {
    display: grid;
    gap: $space-2xl;

    .sheet-heading {
        @include body-14-light;

        // hide sheet headings that don't have line items after them (e.g. bopis, gwps)
        display: none;
        color: $dark-text;
        text-transform: none;
        margin-bottom: -$space-m;

        &:has(+ .line-item) {
            display: block;
        }

        &:not(:first-child) {
            margin-top: $space-s;
        }

        // When headings collapse after removing products from mini-tote,
        // the top heading may not be the first child (but still appears first)
        // in this case, we need to 0 out the margin
        + .sheet-heading {
            margin-top: 0;
        }
    }

    // The following two selectors control the shipping heading specifically,
    // which has more complicated logic.
    // When ISPU items exist, the shipping title shows, but NOT if the user previously
    // had shipping items in their cart which they removed (the heading will stick
    // around in the DOM and should be hidden)
    .mini-cart-ship-title.sheet-heading {
        display: none;

        &:not(:has(+ .line-item)) {
            display: none !important;
        }
    }

    &:has(.mini-cart-store-title + .line-item) {
        // Display the shipping title when ISPU items exist
        .mini-cart-ship-title.sheet-heading {
            display: block;
        }
    }

    .oos-items-divider {
        border-top: rem(1) solid $divider-color;
        display: none;
    }

    &:has(.line-item-oos) {
        .oos-items-divider {
            display: block;
        }
    }
}

.empty-cart-content {
    display: flex;
    align-items: center;
    height: 100%;

    p {
        @include font-serif-regular;

        font-size: rem(32);
        line-height: 120%;
        letter-spacing: rem(-0.96);
        margin-bottom: rem(24);

        @include media-breakpoint-down(md) {
            font-size: rem(28);
            letter-spacing: rem(-0.28);
        }
    }

    a {
        font-size: rem(14);
        line-height: 160%;
        letter-spacing: rem(0.14);
    }
}

.minicart-error {
    @include font-sans-regular;

    color: $cart-error;
    font-size: rem(12);
    line-height: 160%;

    .cart-error-message {
        margin-bottom: $space-m;
    }
}

.minicart-link {
    -webkit-appearance: none;
    padding: 0;
}
