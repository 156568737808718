.reset-password-header {
    @include heading-h2;
    color: $dark-text;
    margin-bottom: $space-m;
}

.password-reset-section,
.reset-password-success-text {
    @include body-16-light;
    p {
        margin-bottom: $space-2xl;
    }
}
