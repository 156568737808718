@import './global/global';
@import './needHelp';
@import './togglePassword';

.page-title.create-account-page-title {
    @include media-breakpoint-up(md) {
        margin: rem(32) 0 rem(40);
    }

    margin: rem(32) 0 rem(16);
}

.early-access-level {
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: rem(0.4);
    margin-top: rem(32);
}

.registration-container {
    legend {
        @include font-sans-16px;
        margin-bottom: rem(8);
    }

    .birthday-group {
        margin-top: $space-2xl;
    }

    .birthday-container {
        gap: $space-s;
        margin: 0;
    }
    .birth-month,
    .birth-day {
        margin-top: rem(0);
        flex: 1 1;
    }
}

.register-sheet {
    .register-footer {
        text-align: center;
    }
    .js-login-link {
        margin-top: $space-l;
    }
}

.loyalty-program-checkbox {
    margin-top: $space-2xl;
    padding: $space-l;
    border: rem(1) solid $border-color-med;
    background-color: $loyalty-signup-box-background-color;
    cursor: pointer;

    .loyalty-program-label {
        display: flex;
        gap: $space-m;
        margin-left: $space-2xl;
        cursor: pointer;
        justify-content: space-between;
    }

    &-title {
        @include font-sans-16px;
        color: $dark-text;
    }
    &-text {
        @include body-14-light;
        p {
            margin-bottom: 0;
        }
    }
    &-disclaimer {
        @include body-12-regular;
        margin-top: $space-xs;
        .tertiary-link {
            @include body-12-regular;
        }
    }
    &:has(:checked) {
        border-color: $loyalty-signup-box-selected-border;
    }

    svg {
        min-width: rem(70);
    }
}
.email-optin-checkbox {
    margin-top: $space-2xl;
}
.email-optin-label {
    color: $dark-text;
}

.privacy-policy {
    margin-top: $space-2xl;
}
