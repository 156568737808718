.alert-dialog {
    @include body-12-regular;

    align-items: center;
    background-color: $color-white;
    border: rem(1) solid $divider-color;
    display: flex;
    justify-content: flex-start;
    min-width: rem(120);
    padding: $space-l;
    position: fixed;
    z-index: $zindex-highest;

    @include media-breakpoint-up(lg) {
        transform: translate(-50%, $space-m);
        margin-left: rem(22);
        margin-top: rem(22);
    }

    &.m-right {
        @include media-breakpoint-up(lg) {
            transform: translate(-100%, $space-m);
        }
    }

    @include media-breakpoint-up(lg) {
        animation: fade-in 150ms ease-in;
        max-width: rem(320);
    }

    @include media-breakpoint-down(md) {
        animation: slide-from-bottom 150ms ease-in;
        bottom: rem(20);
        left: rem(20);
        position: fixed;
        right: rem(20);
    }

    &.m-onclose {
        @include media-breakpoint-up(lg) {
            transition: opacity 150ms ease-out;
            opacity: 0;
        }

        @include media-breakpoint-down(md) {
            transition: transform 150ms ease-out;
            transform: translateY(100%);
        }
    }

    &-icon {
        img {
            // case with product image that passed when product is removed from WL
            margin: -$space-l 0 -$space-l rem(-25);
        }
    }

    &-text {
        margin: 0 $space-s;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-ctas {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;

        button {
            @include body-12-regular;
            @extend .tertiary-link;

            color: $base-text-color;
            white-space: nowrap;

            & + button {
                margin-top: $space-m;
            }
        }
    }
}
