.product-tile {
    min-height: 0; // overwrite from base
    position: relative;

    &-badge {
        &-text {
            @include body-14-medium;

            color: $plp-badge-color;
            display: inline-block;
        }
    }

    .pdp-loyalty-badge {
        &-text {
            @include body-14-light;

            background: $loyalty-badge-background-color;
            color: $loyalty-badge-text-color;
            display: inline-block;
            padding: 0 $space-s;
        }
    }

    .swiper-pagination {
        display: none; // will show only on hover on low density
    }

    .image-container {
        aspect-ratio: 4/5;
        min-height: 0; // overwrite from base
        position: relative;
        text-align: center;

        .tile-img-link {
            img {
                display: block;
                width: 100%;
            }

            svg {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        &:has(.tile-carousel.m-one-image) {
            .swiper-bottom,
            .swiper-button {
                display: none !important;
            }
            .swiper-slide {
                margin: 0 auto !important;
            }

            .swiper-wrapper {
                transform: none !important;
            }
        }
    }

    .swiper-wrapper {
        .svg-icon {
            display: none;
        }

        &.tile-noimage {
            aspect-ratio: 4/5;
            background-color: $product-image-unavailable-bg;

            .svg-icon {
                display: block;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .tile-body {
        box-sizing: border-box;
        padding: $space-m $space-l 0;

        @include media-breakpoint-up(md) {
            padding: $space-m $space-2xl 0;
        }

        .pdp-link {
            @include font-sans-14px;

            a.link {
                @include font-sans-14px;

                color: $plp-link-color;
                display: inline-block;
                text-decoration: none;
            }

            h3.product-name {
                @include sans-family;
                @include font-sans-light;
                @include font-sans-14px;

                color: $plp-link-color;
                display: inline-block;
                margin: 0;
            }
        }

        .item-unavailable-message {
            @include font-sans-medium;
            @include font-sans-14px;

            margin: 0 0 $space-m;
        }

        .availability-block {
            @include body-14-light;

            color: $base-text-color;
            display: inline;
            margin: 0;
            padding: 0;
        }

        .price {
            @include font-sans-light;
            @include font-sans-14px;

            color: $plp-price-color;

            &-variation {
                display: none; // price for the selected color
            }

            &-main {
                display: block; // price for all colors

                .on-sale-message {
                    display: none;
                }
            }

            &-section {
                display: flex;
                flex-flow: row wrap;
                column-gap: $space-xs;
            }

            .sales {
                @include font-sans-light;

                color: $dark-text;
                order: -1;

                &.sale-selected {
                    * {
                        font-weight: $font-weight-medium !important;
                    }
                }
            }

            .starting {
                @include font-sans-light;
            }

            .pricing {
                color: $price-color;
            }

            .on-sale-message {
                @include font-sans-medium;
            }

            .percent-off {
                @include font-sans-light;
                @include font-sans-14px;

                color: $dark-text;
                padding: 0;
                white-space: nowrap;

                &.has-bg {
                    padding: 0 $space-xs;
                }

                &-wrapper {

                }

                + .sales {
                    color: $sale-price-color;
                }
            }

            .range {
                @include font-sans-light;

                display: flex;

                .sales {
                    color: $price-color;
                }

                .strike-through-section:not(.d-none) + .sales {
                    @include font-sans-medium;
                }
            }

            .strike-through {
                @include font-sans-14px;

                color: $base-text-color;
                margin: 0;
                text-decoration: line-through;

                &-section {
                    text-decoration: none;

                     + .sales {
                        @include font-sans-medium;

                        color: $sale-price-color;
                    }
                }

                + .sales {
                    @include font-sans-medium;

                    color: $sale-price-color;
                }

                &:not(.non-adjusted-price) {
                    + .pricing,
                    + .price-section .sales {
                        color: $sale-price-color;
                    }
                }
            }

            .list-range {
                .sales {
                    white-space: nowrap;
                }

                .list + .price-section,
                .price-section + .price-section {
                    .sales {
                        color: $sale-price-color;
                    }
                }

                .percent-off + .price-section .sales {
                    color: $sale-price-color;
                }

                .list {
                    order: 0;
                    white-space: nowrap;
                }
            }

            .list-range.price-section:has(.sales-label) {
                display: flex;
                flex-flow: row wrap;

                > .list {
                    display: flex;
                    column-gap: $space-xs;
                    width: 100%;
                }
            }
        }

        .price-value {
            column-gap: rem(6);
            display: flex;
            flex-flow: row wrap;
        }

        .price-values-select {
            @include font-sans-medium;
        }

        .final-sale-message,
        .availability-message .availability-message-text {
            @include font-sans-12px;

            color: $availability-error-message-color;
            max-width: rem(375);
        }

        .color-swatches {
            min-height: unset;

            .swatch-circle {
                width: rem(16);
                height: rem(16);
                margin-right: 0;
                border: rem(0.5) solid $swatch-selection-border-color;
            }

            .swatches {
                &-more-colors {
                    @include font-sans-12px;

                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        height: $global-icon-link-size-sm;
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: $global-icon-link-size-sm;
                    }
                }
            }

            .swatches-empty {
                @include font-sans-semi-bold;
                @include font-sans-12px;

                color: $price-color;
                letter-spacing: rem(0.4);
                margin-bottom: $space-s;
                text-align: left;
            }

            .product-tile-swatch {
                align-items: center;
                border-radius: 50%;
                display: flex;
                height: rem(20);
                justify-content: center;
                margin-right: $space-s;
                width: rem(20);

                .swatch-circle {
                    border-radius: 50%;
                    border: rem(0.5) solid $swatch-outline-color;
                    box-shadow:
                            0 0 0 rem(2) var(--backgroundModule, $body_bg),
                            0 0 0 rem(3) transparent;
                    height: rem(16);
                    position: relative;
                    width: rem(16);
                }

                &::before {
                    content: '';
                    display: block;
                    height: $global-icon-link-size;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: rem(28); // not overlapping max possible width for clickable area is 28px
                }

                &:hover .swatch-circle {
                    box-shadow:
                        0 0 0 rem(1.17) var(--backgroundModule, $body_bg),
                        0 0 0 rem(2) $color-grey-medium;
                    transition: box-shadow $duration-default ease;
                }

                &.selected .swatch-circle {
                    box-shadow:
                        0 0 0 rem(1.17) var(--backgroundModule, $body_bg),
                        0 0 0 rem(2) $selected-swatch-border-color;
                }

                &.m-low-density-only {
                    display: none;
                }
            }
        }

        .plp-promos {
            display: block;
            margin: 0;
            padding: 0;

            .promotion {
                @include body-12-light;

                color: $color-grey-dark;
                line-height: rem(20);

                &-header {
                    display: inline-block;
                }

                .info-icon-group {
                    display: none;
                }
            }

            .promotion + .promotion {
                margin-top: $space-s;
            }
        }

        .add-to-cart-messages {
            @include font-sans-light;
            @include font-sans-14px;

            .add-to-basket-alert {
                @include font-sans-light;
                @include font-sans-14px;

                border-radius: 0;
                border: none;
                background-color: $add-to-cart-message-background;
                color: $add-to-cart-message-color;
            }
        }

        .neon-badge-indicator {
            @include font-sans-12px;

            color: $swatch-neon-color;
            margin-top: $space-xs;
            text-align: center;

            p {
                margin: 0;
            }
        }

        .e-gift-card-message {
            @include font-sans-12px;

            margin-top: $space-s;
        }
    }

    .loyalty-early-access-lock-container {
        position: absolute;
        right: 0;
        top: 0;
    }

    .wishlist-actions,
    .loyalty-early-access-locked {
        display: block;
        position: absolute;
        right: $space-xs;
        top: $space-xs;
        z-index: $zindex-lowest;

        .add-to-wish-list,
        .strike-wish-list {
            align-items: center;
            display: flex;
            height: $global-icon-link-size;
            justify-content: center;
            opacity: 0.45;
            transition: opacity $duration-default ease;
            width: $global-icon-link-size;

            &:hover {
                opacity: 1;
            }

            .svg-wishlist-icon {
                display: block;
            }

            .svg-wishlist-active-icon {
                display: none;
            }

            &.added-to-wish-list {
                opacity: 1;

                .svg-wishlist-icon {
                    display: none;
                }

                .svg-wishlist-active-icon {
                    display: block;
                }
            }
        }
    }

    .loyalty-early-access-locked {
        opacity: 0.45;
    }

    .loyalty-early-access {
        &-locked {
            font-size: inherit;
            height: $global-icon-link-size;
            line-height: normal;
            padding: 0;
            width: $global-icon-link-size;
        }

        &-lock-container {
            .early-access-container-tooltip {
                display: none;

                .early-access-tooltip-header {
                    @include font-sans-bold;
                }
            }
        }
    }

    .loyalty-early-access-restricted {
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-bottom: 0;
    }

    .video-component {
        position: absolute;
        top: 0;

        @include media-breakpoint-up(md) {
            position: unset;
            top: unset;
        }
    }

    &[data-product-type='set'] .tile-body .color-swatches {
        display: none;
    }

    // accessibility visual:
    .image-container {
        overflow: hidden;
    }

    &::before {
        bottom: rem(2);
        content: '';
        display: block;
        left: rem(-2);
        pointer-events: none;
        position: absolute;
        right: rem(-2);
        top: rem(2);
        width: calc(100% + $space-xs);
        z-index: $zindex-medium;
    }

    .image-container::before {
        bottom: rem(4);
        content: '';
        display: block;
        height: calc(100% - rem(6));
        left: rem(2);
        pointer-events: none;
        position: absolute;
        right: rem(2);
        top: rem(4);
        width: calc(100% - rem(4));
        z-index: $zindex-medium;
    }

    &:focus-visible,
    .image-container:focus-visible {
        outline: 0;

        &::before {
            outline: rem(2) solid $color-black;
        }
    }

    &:has(*:focus-visible)::before {
        outline: rem(2) solid $color-grey-medium;
    }

    .tile-body *:focus-visible {
        background: $body-bg;
        outline-offset: 0;
        position: relative;
        z-index: 3;
    }
}
