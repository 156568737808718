.size-chart {
    flex-wrap: wrap;
    margin: $space-s 0 0;
    text-align: left;

    #main-content & {
        justify-content: flex-start;
        margin-bottom: $space-2xl !important;
    }

    > a {
        line-height: rem(24);
        letter-spacing: rem(0.4);
        margin-right: $space-m;
        text-decoration: underline;
    }

    .size-chart-link {
        margin-left: 0 !important;
    }

    .pdp-reviews-suggest {
        width: 100%;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            width: auto;
        }

        .fit-review {
            color: $base-text-color;
            margin-left: $space-xs;

            .custom-set-items & {
                cursor: auto;
            }
        }

        .no-fit-review {
            color: $base-text-color;
            cursor: auto;
            margin-left: $space-xs;
        }
    }
}

.lp-sizechart {
    @include font-sans-regular;

    text-align: left;
    font-size: rem(12);
    line-height: rem(16);
    color: $base-text-color;

    h2,
    &-category-btn {
        @include font-serif-regular;

        font-size: rem(24);
        line-height: rem(40);
        margin-bottom: 0;
        background: none;
        border: 0;
        padding: 0;
    }

    &-category-btn {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        &::after {
            content: '';
            transform: rotate(45deg);
            height: rem(12);
            width: rem(12);
            border-bottom: rem(1) solid $base-text-color;
            border-right: rem(1) solid $base-text-color;
            margin-right: $space-xs;
            transition: transform $duration-default ease;
        }
    }

    h3 {
        @include font-sans-semi-bold;

        font-size: rem(14);
        line-height: rem(20);
        margin-bottom: $space-xs;
    }

    p {
        letter-spacing: rem(0.4);
    }

    article {
        h3 {
            margin-bottom: $space-xs;
        }

        margin-bottom: $space-xl;

        &:first-child {
            margin-top: $space-m;
        }
    }

    &-category-dropdown {
        width: 40%;

        &-menu {
            position: absolute;
            background-color: $sizechart-menu-bg;
            flex-direction: column;
            margin-left: rem(-16);
            z-index: $zindex-lowest;
            border: 0;
            padding: 0;
            overflow: hidden;
            height: 0;
            transition: all $duration-default;
            width: 40%;

            &.nav-tabs {
                border: 0;
            }

            .nav-item {
                .nav-link {
                    color: $base-text-color;
                    font-size: rem(14);
                    line-height: rem(24);
                    padding: $space-xs $space-m;
                    position: relative;
                    transition: all $duration-default;

                    &:hover {
                        opacity: 0.4;
                    }

                    &.active {
                        background-color: $sizechart-menu-bg;
                        border-bottom: 0;

                        &::before {
                            content: '';
                            transform: rotate(45deg);
                            height: rem(10);
                            width: rem(5);
                            border-bottom: rem(1) solid $base-text-color;
                            border-right: rem(1) solid $base-text-color;
                            position: absolute;
                            left: rem(3);
                            top: rem(8);
                        }
                    }
                }
            }
        }

        &.show-menu {
            .lp-sizechart-category-dropdown-menu {
                height: auto;
                padding: $space-m 0;
            }

            .lp-sizechart-category-btn {
                &::after {
                    content: '';
                    transform: rotate(-135deg);
                }
            }
        }
    }

    .swim-tabcontent {
        margin-bottom: $space-3xl;

        &-nav {
            margin-bottom: $space-3xl;

            li {
                width: 50%;
                text-align: center;

                a {
                    margin: 0 auto;
                    font-size: rem(14);
                    line-break: rem(20);
                    padding: $space-s;
                    display: block;
                    text-decoration: none;

                    &.active {
                        border-bottom: rem(2) solid $active-border-bottom-navy;
                    }
                }
            }
        }

        .swim-sizechart-accordion {
            .swim-card {
                border-bottom: rem(1) solid $accordion-border-silver;

                &:first-child {
                    border-top: rem(1) solid $accordion-border-silver;
                }

                &-header {
                    height: rem(56);

                    button {
                        color: $base-text-color;
                        padding: 0 $space-m;
                        width: 100%;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &::after {
                            content: '';
                            transform: rotate(45deg);
                            height: rem(12);
                            width: rem(12);
                            border-bottom: rem(1) solid $base-text-color;
                            border-right: rem(1) solid $base-text-color;
                            margin-right: $space-xs;
                            display: block;
                        }

                        &[aria-expanded='true'] {
                            &::after {
                                transform: rotate(-135deg);
                            }
                        }
                    }
                }

                article {
                    margin-bottom: 0;

                    &:nth-of-type(odd) {
                        background-color: $table-row-grey;
                    }
                }

                .fit-img {
                    padding: $space-m $space-xl;

                    img {
                        width: 100%;
                        margin: 0 auto;
                        filter: grayscale(1);
                    }
                }

                .fit-desc {
                    padding: $space-s $space-s $space-s $space-m;

                    ul {
                        padding: 0;
                        margin-bottom: 0;
                        list-style-position: inside;
                        letter-spacing: rem(0.4);
                        list-style: disc;
                        list-style-position: inside;
                    }

                    > p {
                        margin-bottom: $space-s;
                    }
                }
            }
        }
    }

    .size-chart-collapsible {
        &.active {
            .sizinginformation {
                right: 0;
            }
        }
    }

    .sizinginformation {
        background: $white;
        z-index: $zindex-max-highest;
        padding: $space-xl $space-m $space-xl $space-3xl;
        overflow: auto;

        @include media-breakpoint-up(md) {
            overflow: unset;
        }

        &-container {
            height: 100%;
        }
    }

    &-header {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: $space-m;

        .lp-sub-text {
            @include font-sans-bold;

            font-size: rem(14);
            line-height: rem(20);
            text-transform: uppercase;
            letter-spacing: rem(1);
            margin-bottom: $space-2xl;
        }

        #sizechart-close {
            position: absolute;
            right: rem(40);
            top: rem(40);
            width: rem(16);
            height: rem(16);
            border: 0;
            display: table;
            padding: 0;
            background: none;
        }
    }

    &-content {
        padding-right: $space-2xl;
        height: 90%;
        padding-bottom: $space-7xl;
        overflow: visible;

        @include media-breakpoint-up(md) {
            padding-bottom: 0;
            overflow: auto;
        }
    }

    .sizechart {
        &.tab-pane.active {
            animation: opacity-show 0.5s ease-in-out;
        }
    }

    .table-container {
        margin-bottom: $space-xl;
    }

    .sizecharttable {
        letter-spacing: rem(0.24);

        &.table-striped tbody tr:nth-of-type(even) {
            background-color: $table-row-grey;
        }

        &.table-striped tbody tr:nth-of-type(odd) {
            background-color: $table-row-white;
        }

        td,
        th {
            border: 0;
            padding: $space-m $space-s;
            text-align: center;
            vertical-align: middle;
            font-weight: $font-weight-regular;
        }

        .firstRow {
            @include font-sans-semi-bold;

            text-align: center;
        }

        td[rowspan='2'] {
            vertical-align: top;
        }
    }

    .tips,
    .nofit {
        h2 {
            margin-bottom: $space-m;
            line-height: rem(32);
        }
    }

    .tips {
        margin-bottom: $space-3xl;
    }

    .btn {
        &-find-a-store {
            margin-bottom: $space-xl;
            font-size: rem(14);
        }
    }

    .return-policy-link {
        font-size: rem(12);
        line-height: rem(24);
        letter-spacing: rem(0.4);
    }
}

@keyframes opacity-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.size-chart-visible {
    header,
    #carousel-stripe-banner,
    .is-sticky,
    .sticky.is-sticky {
        z-index: $zindex-lowest;
    }

    .lp-sizechart {
        z-index: $zindex-max-highest;
        position: relative;
    }
}
