@mixin themed-scroll {
    scrollbar-color: $scrollbar-color transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar-button {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-color;

        &:hover {
            background-color: $scrollbar-color;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: $wildsand;

        &:hover {
            background-color: $wildsand;
        }
    }

    &::-webkit-scrollbar {
        width: rem(4);
        height: rem(4);
    }
}

@mixin scroll-custom($mode: default) {
    // default colors, use on elements with white bg only
    $bg_color: $color-grey-light;
    $thumb_color: $scrollbar-color;
    $thumb_color_lg: $thumb_color;

    // transparent, used on sidebars
    @if ($mode == transparent) {
        $bg_color: transparent;
        $thumb_color_lg: $color-grey-light;
    }

    // on-light, used on elements with light bg
    @if ($mode == on-light) {
        $bg_color: rgba($color-black, 0.1);
    }

    // on-dark, used on elements with dark bg
    @if ($mode == on-dark) {
        $bg_color: rgba($color-white, 0.3);
        $thumb_color: $color-white;
    }

    @supports selector(::-webkit-scrollbar) {
        &::-webkit-scrollbar {
            width: rem(1.5);
        }
    }

    @supports selector(::-webkit-scrollbar-thumb) {
        &::-webkit-scrollbar-thumb {
            background-color: $thumb_color;

            @include media-breakpoint-up(lg) {
                background-color: $thumb_color_lg;
            }
        }
    }

    @supports selector(::-webkit-scrollbar-track) {
        &::-webkit-scrollbar-track {
            background-color: $bg_color;
        }
    }

    @supports not (selector(::-webkit-scrollbar)) {
        scrollbar-color: $thumb_color $bg_color;
        scrollbar-width: thin;

        @include media-breakpoint-up(lg) {
            scrollbar-color: $thumb_color_lg $bg_color;
        }
    }
}
