.search-global {
    position: relative;
    padding-bottom: $space-3xl;
    padding-top: rem(3);

    @include media-breakpoint-up(sm) {
        padding-bottom: 0;
    }

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    .search-form {
        display: flex;
        flex-direction: column;

        &-submit {
            height: rem(24);
            line-height: rem(24);
            padding: 0;
            width: rem(24);
            margin-right: $space-m;
            margin-top: rem(9);

            .svg-icon {
                height: rem(24);
                width: rem(24);
                stroke: $dark-text;
            }

            @include media-breakpoint-down(sm) {
                margin-right: rem(10);
            }
        }

        &-input {
            order: 0;
            display: flex;

            @include media-breakpoint-down(md) {
                padding: 0 $space-3xl;
            }

            @include media-breakpoint-down(sm) {
                padding: 0 $space-m;
            }
        }

        &-clear {
            @include font-sans-light;
            @include font-sans-14px;
            color: $base-text-color;
            display: none;
            padding: 0;
            position: absolute;
            right: rem(55);
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-up(md) {
                right: $space-6xl;
            }

            @include media-breakpoint-up(lg) {
                right: $space-7xl;
            }
        }

        &-close {
            align-items: center;
            display: flex;
            height: rem(24);
            padding: 0;
            position: absolute;
            right: $space-m;
            top: 50%;
            transform: translateY(-50%);
            width: rem(24);
            z-index: $zindex-high;

            @include media-breakpoint-up(md) {
                right: $space-3xl;
            }

            @include media-breakpoint-up(lg) {
                right: 0;
            }

            svg {
                width: rem(24);
                height: rem(24);
            }
        }
    }

    input {
        @include heading-h3;
        border-radius: 0;
        border: none;
        border-bottom: rem(1) solid $search-border-color;
        color: $search-input-text-color;
        padding-left: $space-2xs;
        padding-right: rem(62);
        padding-bottom: rem(10);
        width: 100%;
        margin-right: rem(36);

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: $form-control-focused-border-color;
        }

        &::placeholder {
            color: $search-text-color;
        }

        @include media-breakpoint-up(md) {
            margin-right: rem(36);
        }

        @include media-breakpoint-up(lg) {
            margin-right: $space-7xl;
        }
    }
}

.search-menu {
    position: absolute;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s;
    z-index: $zindex-lowest;
    top: 0;

    &.open {
        .fade-bg {
            top: unset;
            height: 100vh;
            position: relative;
        }
    }

    .search-form-input {
        width: 100%;
    }

    &-wrapper {
        height: 100dvh;
        transition: max-height $duration-default;
        min-height: rem(460);
        background-color: $search-menu-background-color;

        @include media-breakpoint-up(lg) {
            height: 0;
        }
    }

    &-container {
        @include grid-layout;
        padding: $space-3xl 0;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding: $space-m 0 $space-3xl;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: auto;
            padding: rem(21) 0 $space-3xl;
        }
    }

    .header-nav-logo {
        grid-column: 2/4;

        svg {
            height: rem(57);
            width: rem(110);
            fill: $header-logo-color;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &-container-scrollable {
        grid-column: 4/15;

        @include media-breakpoint-down(md) {
            grid-column: 1/7;
        }
    }

    &.header-search-type {
        max-height: 0;

        @include media-breakpoint-up(lg) {
            max-height: 100%;
            display: none;
        }

        .search-global {
            display: none;
        }
    }

    &.open {
        max-height: 100vh;
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        .search-global {
            display: block;
        }

        .search-menu-container {
            @include media-breakpoint-up(lg) {
                height: auto;
            }
        }
    }
}

.suggestions {
    position: relative;
    width: 100%;
    border: 0;
    font-size: rem(12);
    line-height: rem(16);
    text-align: left;

    &.full-suggestions {
        margin-top: $space-2xl;

        @include media-breakpoint-down(md) {
            margin-top: rem(50);
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: $space-5xl;
        }

        #search-results {
            width: 100%;
        }

        .suggestions-section-title {
            @include body-14-light;

            align-items: center;
            color: $base-text-color;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $space-m;
            text-transform: none;

            @include media-breakpoint-down(md) {
                @include font-sans-16px;
            }

            .do-you-mean {
                margin-right: $space-2xs;
            }

            a {
                color: $base-text-color;
                text-decoration: none;
            }
        }

        .suggestions-section-items {
            @include media-breakpoint-down(md) {
                @include font-sans-16px;
            }
        }
    }

    .recently-viewed {
        display: none;

        &:has(.product-listing) {
            display: block;
        }

        .recently-carousel {
            padding: 0;

            @include media-breakpoint-only(md) {
                margin-top: $space-m;
            }
        }

        .carousel-feature .swiper-outer {
            padding-top: 0;
        }

        .image-container {
            .loyalty-early-access-lock-container,
            .wishlist-add-update,
            .wishlist-strike,
            .product-tile-early-access,
            .product-tile-badge {
                display: none !important;
            }
        }

        .grid-tile {
            max-width: 100%;

            @include media-breakpoint-up(md) {
                flex: 0 0 calc(25% - $space-2xs);
            }
        }
    }

    &.popular-search {
        margin-top: rem(47);
        order: 3;

        .popular-search-grid {
            gap: $space-xs;
            display: grid;
            grid-template-columns: repeat(11, 1fr);

            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                gap: unset;
            }
        }

        .search-results-popular {
            grid-column: 1 / 3;

            @include media-breakpoint-down(md) {
                order: 1;
                padding: 0 $space-3xl;
            }

            @include media-breakpoint-down(sm) {
                padding-inline: $space-l;
            }

            .suggestions-section-items {
                height: rem(24);

                @include media-breakpoint-down(md) {
                    height: rem(26);
                }
            }
        }

        .suggestions-search-history {
            grid-column: 4 / 6;

            @include media-breakpoint-down(md) {
                margin: (-$space-l) 0 rem(28);
                order: 0;
                overflow: hidden;
                padding-left: $page-grid-margin-md;
                width: 100%;

                .suggestions-section-title {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                padding-left: $page-grid-margin-sm;
            }

            &-list {
                @include media-breakpoint-down(md) {
                    align-items: center;
                    display: flex;
                    overflow-x: auto;
                    padding-bottom: $space-l;
                    scrollbar-color: $dark-text transparent;
                }

                .suggestions-section-items {
                    align-items: center;
                    display: flex;
                    height: rem(24);
                    width: fit-content;

                    &:hover {
                        .link {
                            border-color: $search-border-color;
                        }

                        .remove-recent-search {
                            visibility: visible;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        height: rem(22);
                        margin-bottom: 0;
                        margin-right: rem(10);
                    }
                }

                .item {
                    align-items: center;
                    color: $search-input-text-color;
                    display: flex;

                    .svg-icon {
                        display: none;

                        @include media-breakpoint-down(md) {
                            display: inline-block;
                            margin-right: $space-xs;
                            margin-top: rem(1);
                            stroke-width: 1.5;
                            stroke: $dark-text;
                        }
                    }
                }

                .link {
                    @include media-breakpoint-down(md) {
                        @include body-14-light;
                    }
                }

                .remove-recent-search {
                    align-items: center;
                    display: flex;
                    height: rem(24);
                    justify-content: center;
                    margin-left: rem(10);
                    visibility: hidden;
                    width: rem(24);

                    svg {
                        height: rem(12);
                        width: rem(12);
                    }

                    @include media-breakpoint-down(md) {
                        height: rem(32);
                        margin-left: 0;
                        padding: 0;
                        visibility: visible;
                        width: rem(32);
                    }
                }

                .remove-all-recent {
                    @include body-14-light;

                    padding: 0;

                    @include media-breakpoint-down(md) {
                        color: $dark-text;
                        flex-shrink: 0;
                        margin-right: rem(10);
                        text-decoration: underline;
                        text-underline-offset: rem(1.5);
                    }
                }
            }
        }

        .recently-viewed {
            grid-column: 7 / 12;
            padding-right: rem(47.5);

            @include media-breakpoint-down(md) {
                order: 2;
                flex-direction: column;
                margin-block: $space-xl $space-4xl;
                padding-inline: $space-3xl;
            }

            @include media-breakpoint-down(sm) {
                padding-inline: 0;
            }

            .suggestions-section-title {
                @include media-breakpoint-down(md) {
                    padding-block: $space-xl $space-m;
                }

                @include media-breakpoint-down(sm) {
                    padding-inline: $space-l;
                    margin-bottom: 0;
                }
            }
        }

        &.popular-search-with-error {
            .suggestions-search-history,
            .recently-viewed {
                display: none;
            }
        }
    }

    &-grid {
        display: grid;
        grid-gap: $space-xs;
        grid-template-columns: repeat(11, 1fr);

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &-wrapper {
        order: 4;
    }

    &-error {
        @include heading-h2;
        color: $search-input-text-color;
        margin-top: rem(47);
        order: 1;

        @include media-breakpoint-down(md) {
            margin-top: $space-xl;
            padding: 0 $space-3xl;

            &-first-line {
                display: block;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 0 $page-grid-margin-sm;
        }
    }

    &-section {
        grid-column: 4 / 12;

        &-title {
            @include body-12-medium;

            color: $dark-text;
            line-height: rem(15);
            margin-bottom: $space-m;
            text-transform: uppercase;
        }

        &-items {
            @include body-14-light;

            margin-bottom: $space-s;

            @include media-breakpoint-down(md) {
                @include font-sans-16px;
                margin-bottom: $space-m;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .item {
                padding: 0;
            }

            a {
                color: $search-input-text-color;
                text-decoration: none;
            }

            .category-suggestions-name {
                margin-bottom: rem(10);
            }
        }
    }

    &-related-products {
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;
        }

        @include media-breakpoint-down(md) {
            margin-top: $space-4xl;
            padding: 0 $page-grid-margin-md;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 0 $space-xl;
        }

        .suggestions-section-items {
            display: grid;
            gap: $space-xl $space-xs;
            grid-template-columns: 1fr 1fr;

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(4, 1fr);
            }

            @include media-breakpoint-up(lg) {
                width: rem(765);
            }

            .suggestions-product {
                padding: 0;

                &-image {
                    @include product-image;

                    position: relative;

                    svg {
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &-name {
                    @include body-14-light;

                    color: $base-text-color;
                    padding: $space-m $page-grid-margin-sm 0 0;
                    white-space: normal;

                    @include media-breakpoint-up(md) {
                        padding-right: $space-s;
                        padding-top: $space-m;
                    }
                }

                picture {
                    @include product-image;

                    display: block;

                    @include media-breakpoint-down(md) {
                        position: static;
                    }

                    img {
                        width: 100%;
                    }
                }

            }



            .tile-body {
                @include body-14-light;
                padding: 0;

                @include media-breakpoint-down(sm) {
                    padding: 0 $page-grid-margin-sm 0 0;
                }

                .price .sales {
                    color: $base-text-color;
                }
            }

            .product-tile:nth-child(odd) {
                .suggestions-product-name {
                    @include media-breakpoint-down(sm) {
                        padding: $space-m $page-grid-margin-sm 0;
                    }
                }

                .tile-body {
                    padding: 0;

                    @include media-breakpoint-down(sm) {
                        padding: 0 $page-grid-margin-sm;
                    }
                }
            }
        }
    }

    &-search-str {
        letter-spacing: rem(0.4);
    }

    &-related-products {
        a {
            span {
                display: block;
            }
        }
    }

    &-category-links {
        letter-spacing: rem(0.4);
        display: table;

        .category-suggestions-name {
            line-height: rem(24);
        }
    }

    .category-wrap {
        justify-content: start;
        grid-column: 1 / 3;

        @include media-breakpoint-down(md) {
            justify-content: center;
            padding: 0 $page-grid-margin-md;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 $page-grid-margin-sm;
        }
    }

    .category-wrap.category-wrap-mobile {
        @include media-breakpoint-down(sm) {
            padding-left: 0;

            .suggestions-section {
                padding-bottom: rem(16);
            }
        }
    }

    .link {
        @include font-sans-14px;

        border-bottom: rem(1) solid transparent;

        &:hover {
            border-color: $search-border-color;
        }

        @include media-breakpoint-down(md) {
            @include font-sans-16px;
            white-space: nowrap;
        }
    }
}

.suggestions-section-items .item.product-tile {
    @include media-breakpoint-down(sm) {
        padding: 0 rem(4);
    }
}

.search-menu-container-scrollable {
    max-height: calc(100vh - rem(80));
    overflow-y: auto;
    overflow-x: hidden;

    @supports (max-height: 100dvh) {
        max-height: calc(100dvh - rem(80));
    }

    &.m-keyboard-open {
        @supports (max-height: 100dvh) {
            max-height: calc(100dvh - var(--keyboard-height, rem(80)));
        }
    }
}
.row.suggestions-section.suggestions-related-products {
    @include media-breakpoint-down(sm) {
        padding-bottom: rem(40);
    }
}
.plp-style {
    z-index: $zindex-lowest;

    .suggestions-wrapper {
        box-shadow: 0 rem(10) rem(15) 0 rgba(0, 0, 0, 0.12);
        position: absolute;
        width: 100%;
    }

    .suggestions-related-products {
        display: none;
    }

    .suggestions {
        padding: 0 rem(40) rem(24);

        .left-container,
        .right-container {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
.left-container {
    .row.suggestions-section.suggestions-search-str {
        @include media-breakpoint-down(sm) {
            padding-top: rem(32);
        }
    }
}
.suggestions-section-items {
    .item.term.search-suggestions-item,
    .item.category-suggestions-name {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
