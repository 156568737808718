.login-nav-container {
    width: 100%;
    margin-top: $space-4xl;
    border-bottom: rem(1) solid $divider-color;
}
.login-nav-bar,
.login-page {
    @include grid-layout;
}

.login-nav-tabs,
.login-page .tab-content {
    grid-column: 4/9;
    @include media-breakpoint-down(md) {
        grid-column: 5/12;
    }
    @include media-breakpoint-down(sm) {
        grid-column: 1/7;
        padding-inline: $space-l;
    }
}

.login-nav-tabs {
    display: flex;
    gap: $space-xl;
    height: rem(46);
    .nav-item {
        a {
            @include body-14-light;
            text-decoration: none;
            display: inline-block;
            height: 100%;
            color: $dark-text;
            &:hover {
                color: $base-text-color;
            }
            &.active {
                color: $dark-text;
            }
        }
        &:has(.active) {
            border-bottom: rem(1) solid $tab-fg-color;
        }
        &:hover {
            border-bottom: rem(1) solid $tab-hover-color;
        }
    }
}
.invalid-referrer-id {
    width: 82%;
    margin: auto;
    padding-top: rem(88);
    padding-bottom: rem(48);

    @include media-breakpoint-up(md) {
        width: 48%;
        padding-top: rem(104);
        padding-bottom: rem(64);
    }
}

.maxed-out-referrer {
    width: 83%;
    margin: auto;
    padding-top: rem(88);
    padding-bottom: rem(48);

    @include media-breakpoint-up(md) {
        width: 45%;
        padding-top: rem(104);
        padding-bottom: rem(64);
    }
}

.referral-error-heading {
    @include font-serif-regular;
    font-size: rem(24);
    line-height: rem(28);
    color: $loyalty-heading-color;
    margin-bottom: rem(16);
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: rem(32);
        line-height: rem(40);
    }
}

.referral-error-info {
    @include font-sans-regular;
    font-size: rem(14);
    line-height: rem(20);
    color: $loyalty-referral-color;
    text-align: center;
    margin-bottom: 0;
}

.login-page-container {
    margin-top: $space-3xl;
    @include media-breakpoint-up(md) {
        margin-top: $space-4xl;
    }
}

.login-page form {
    @include media-breakpoint-up(md) {
        max-width: rem(356);
    }

    @include media-breakpoint-up(lg) {
        max-width: rem(477);
    }
}
.login-page,
#account-sheet {
    &-header {
        @include heading-h2;
        margin-bottom: $space-2xl;
        color: $dark-text;
    }

    .button-primary {
        margin-top: $space-2xl;
    }

    .forgot-password {
        margin-top: $space-s;
    }

    .remember-me-container {
        margin-top: $space-2xl;

        label {
            color: $dark-text;
        }

        .form-group {
            margin-bottom: 0;
        }
    }
    .js-register-link {
        margin-top: $space-l;
    }
    .login-footer {
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        form.login,
        form.trackorder {
            .form-group.custom {
                margin-top: $space-m;
            }
        }
        .forgot-password {
            margin-top: $space-m;
        }
    }
}

.early-access-login-form {
    .user-logged-in {
        .keep-user-logged-in {
            width: rem(16);
            height: rem(16);
            border: rem(0.5) solid $info-icon-border;
            border-radius: 50%;
            text-align: center;
            line-height: rem(16);
            font-size: rem(10);
            padding: 0;
            display: inline-block;
            cursor: pointer;
            margin-top: rem(4);
        }

        .keep-user-logged-in:hover,
        .keep-user-logged-in:focus {
            background-color: $base-text-color;
            color: $page-title-background-color;
        }
    }
}

.remember-me {
    .logged-in-user-notification {
        display: none;
    }
    label[for='rememberUsername'] {
        color: $dark-text;
    }
}

$keep-signed-in-icon-size: rem(12);
.keep-user-signed-in {
    width: $keep-signed-in-icon-size;
    height: $keep-signed-in-icon-size;
    line-height: $keep-signed-in-icon-size;
    margin-left: $space-s;
    &::before {
        background: $dark-text;
        width: $keep-signed-in-icon-size;
        height: $keep-signed-in-icon-size;
    }
}

#account-sheet .sheet-page {
    display: none;
    &.active {
        display: grid;
    }

    .sheet-footer .btn {
        margin-top: 0;
    }
}
