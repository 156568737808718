.flex-tabs {
    border-bottom: rem(1) solid $divider-color;
    width: 100%;

    &-inner {
        width: 100%;
        margin-inline: auto;
        display: flex;
        overflow-x: auto;
        gap: $space-xl;
    }

    a {
        padding-block: $space-xl;
        text-decoration: none;
        color: $tab-fg-color;
        flex-shrink: 0;

        &.active {
            border-bottom: rem(1) solid $tab-fg-color;
        }
    }

    &-content {
        display: none;

        &.active {
            display: block;
        }
    }
}
