@import './stripeBanner';

.page-top-navigation {
    &:has(.stuck) {
        display: contents;
    }

    &:not(.page[data-action='Home-Show'] &) {
        display: contents;
    }
}

header {
    background: $header-background-color;
    position: sticky;
    top: rem(-1);
    z-index: $zindex-header;

    body.m-pdp-loosed & {
        @include media-breakpoint-down(sm) {
            position: relative;
        }
    }

    .header-menu-opened &,
    &:hover {
        z-index: $zindex-header-hover;
    }

    &:has(.search-menu.open) {
        z-index: $zindex-header-hover;
    }

    .has-icon {
        display: inline-block;
    }

    .has-icon:focus-visible,
    .header-nav-icons .search-toggle button:focus-visible,
    .wishlist a:focus-visible,
    .minicart-link:focus-visible {
        outline: none;

        .svg-icon {
            outline: rem(2) solid $primary-focus-color;
            outline-offset: rem(2);
        }
    }

    .set-preferred-store {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        .navbar.main-nav {
            grid-column: 2 / 15;
            justify-content: space-between;
            padding: 0;
            position: static;
        }
    }

    &.stuck {
        border-bottom: rem(1) solid $header-border-color;
        background-color: $header-background-color;

        @include media-breakpoint-up(lg) {
            .main-nav {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .header .dropdown-menu {
            @include media-breakpoint-up(lg) {
                top: 100%;
            }
        }

        .navbar-nav {
            padding-top: $space-2xl;

            .nav-item {
                .nav-link {
                    padding-bottom: $space-2xl;
                }
            }
        }

        .header-nav-icons {
            padding-top: rem(28);

            .navbar-nav {
                padding-top: 0;
            }

            .nav-item {
                .nav-link {
                    padding-bottom: rem(28);

                    @include media-breakpoint-down(md) {
                        padding-bottom: 0;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                padding-top: rem(11);
                padding-bottom: rem(11);
                transition: padding $duration-default;
            }
        }

        .header-nav-logo {
            @include media-breakpoint-down(md) {
                width: rem(100);
            }
        }
    }
}

header .arrow,
.order-history .arrow,
.carousel-stripe-banner .arrow {
    overflow: hidden;
    display: inline-block;
    font-size: rem(12);
    width: rem(8);
    height: rem(8);
    margin-top: -2em;
    border-top: 1px solid $header-text-color;
    border-left: 1px solid $header-text-color;

    &.left {
        transform: rotate(-54deg) skew(-20deg);
    }

    &.right {
        transform: rotate(135deg) skew(-6deg);
    }
}

.carousel-stripe-banner {
    .arrow {
        border-top: rem(1) solid $stripe-icon-color;
        border-left: rem(1) solid $stripe-icon-color;
    }

    .control .sr-only {
        color: $white;
    }
}

.browser-warning {
    position: relative;
    background-color: $alert-info-background-color;
    font-size: rem(13);
    line-height: 1.25;
    padding: rem(16);
    text-align: center;
    z-index: $zindex-low;

    a {
        text-decoration: underline;
    }
}

.header-wrapper,
.back-arrow-container {
    .search-menu {
        left: 0;
        z-index: $zindex-higher;
    }
}

/* sticky header position */

.main-nav {
    @include grid-layout;

    color: $header-text-color;
    padding-right: 0;
    position: relative;
    top: 0;
    transition: all ease-out $duration-default;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    a {
        color: $menu-link;

        &:active {
            color: $menu-link;
        }
    }

    .main-menu {
        grid-column: 2 / 13;

        &.menu-toggleable-left {
            @include media-breakpoint-down(md) {
                z-index: $zindex-high;
                left: unset;
                right: -100%;
                transition-property: right;

                &.in {
                    right: 0;
                    margin-right: 0;
                }
            }
        }
        .navbar {
            @include media-breakpoint-up(lg) {
                gap: rem(4);
                display: grid;
                grid-template-columns: repeat(11, 1fr);
            }
        }
    }

    .highlighted-category {
        color: $header-highlighted-color;

        &:active {
            color: $header-highlighted-color;
        }
    }

    .header-nav-logo {
        grid-column: 1 / 3;
        width: rem(110);

        @include media-breakpoint-down(lg) {
            width: rem(100);
        }

        svg {
            width: 100%;
            height: auto;
            fill: $header-logo-color;
            margin-right: 0;

            @include media-breakpoint-down(md) {
                height: rem(57.2);
                width: auto;
            }
        }
    }

    .header-nav-icons {
        grid-column: 13 / 15;
        padding-top: rem(33);
        position: relative;

        @include media-breakpoint-up(lg) {
            min-width: rem(170);
        }

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            display: flex;
            grid-column: 2 / 15;
            justify-content: space-between;
            padding: $space-l 0 rem(17.5);

            .header-nav-logo {
                margin: 0;
                width: rem(100);
            }
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1 / 7;
            padding: rem(19) rem(6) rem(19) $space-l;
            background-color: transparent;
        }

        @include media-breakpoint-between(md, lg) {
            position: static;
        }

        .header-nav-logo {
            @include media-breakpoint-up(lg) {
                display: none;
            }

            svg {
                @include media-breakpoint-down(md) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .icons-content {
            float: none;

            @include media-breakpoint-down(md) {
                justify-content: space-between !important; // To overide bootstrap justifying on mobile
            }
        }

        .navbar-nav {
            justify-content: flex-end;

            svg {
                height: rem(24);
                width: rem(24);
                fill: $header-text-color;

                &.svg-search-icon {
                    stroke: $header-text-color;
                    stroke-width: 1.5;
                }
            }

            @include media-breakpoint-down(md) {
                flex-direction: row;
                margin-right: rem(-12);
                padding: rem(3) 0 rem(5.5);
            }

            @include media-breakpoint-down(sm) {
                margin-right: 0;
                padding: $space-xs 0;
            }
        }

        .minicart-total {
            position: relative;

            .minicart-quantity {
                @include font-sans-medium;
                align-items: center;
                background-color: $mini-cart-quantity-bg;
                border-radius: 50%;
                display: flex;
                font-size: rem(10);
                height: rem(16);
                justify-content: center;
                left: rem(12);
                position: absolute;
                top: rem(11);
                width: rem(16);

                @include media-breakpoint-down(md) {
                    left: rem(22);
                    top: rem(22);
                }
            }
        }

        .nav-item {
            margin-right: $space-l;
            padding: 0;
            position: relative;

            @include media-breakpoint-down(lg) {
                margin-right: $space-m;
            }

            @include media-breakpoint-down(md) {
                margin-right: $space-xs;
            }

            &:last-child {
                margin-right: 0;

                @include media-breakpoint-down(md) {
                    width: $global-icon-link-size;
                }
            }

            &.minicart {
                @include media-breakpoint-up(lg) {
                    margin-right: 0;
                }
            }

            &:hover,
            &:focus {
                @include media-breakpoint-up($next-breakpoint) {
                    .nav-link {
                        outline: none;

                        &::before,
                        &::after {
                            width: 50%;
                        }
                    }
                }
            }

            .nav-link {
                align-items: center;
                display: flex;
                justify-content: center;
                padding: 0 0 rem(33);
                position: relative;
                z-index: $zindex-medium;

                @include media-breakpoint-down(md) {
                    height: rem(44);
                    padding: rem(10);
                    width: rem(44);
                }

                &::after,
                &::before {
                    background: none;
                    border: none;
                    bottom: 0;
                    content: '';
                    height: rem(1);
                    margin: 0;
                    padding: 0 !important; //overriding bootstrap style
                    position: absolute;
                    transition: all .35s ease-out;
                    width: 0;

                    @include media-breakpoint-up(lg) {
                        background: $header-item-border-color;
                    }
                }

                &::before {
                    right: 50%;
                }

                &::after {
                    left: 50%;
                }
            }

            .dropdown-menu {
                border-radius: 0;
                border: none;
                left: 50%;
                margin-top: 0;
                padding: $space-2xl $space-5xl $space-3xl;
                position: absolute;
                top: 100%;
                transform: translateX(-50%);

                .user-message {
                    @include font-sans-medium;
                    @include font-sans-14px;
                    color: $header-text-color;
                    margin-bottom: $space-m;
                    text-transform: capitalize;
                }

                ul {
                    flex-flow: column;
                }
            }

            .dropdown-item {
                margin-bottom: $space-s;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover,
                &:active,
                &:focus {
                    background: transparent;
                }
            }

            .dropdown-link {
                @include font-sans-light;
                @include font-sans-14px;
                background: transparent;
                color: $header-text-color;
                padding: 0;
                text-decoration: none;

                &:hover {
                    text-decoration-color: $link-decoration-color-dark;
                    text-decoration: underline;
                    text-underline-offset: rem(1);
                    text-underline-position: under;
                }

                &.loyalty-dashboard-link {
                    position: relative;
                    vertical-align: middle;
                }

                &.loyalty-program-drawer-link {
                    display: flex;
                    position: relative;
                    padding-bottom: 0;
                    width: fit-content;

                    @include media-breakpoint-only(lg) {
                        padding: 0;
                        margin-top: rem(8);
                    }
                }
                .clublilly-cta-title {
                    position: relative;
                    top: rem(5);
                }
            }

            &.store-locator-header-nav,
            &.user-account-menu {
                .dropdown-menu {
                    width: rem(262);
                }
            }
        }

        .navbar-toggler-wrapper {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .store-locator-dropdown-menu {
            .dropdown-item {
                color: $base-text-color;
                display: flex;
                flex-wrap: wrap;

                &:active {
                    color: $base-text-color;
                }
            }

            .store-locator-name {
                @include font-sans-14px;
                margin-right: $space-xs;
            }

            .store-locator-value {
                color: $base-text-color;
                white-space: normal;
            }
        }

        .navbar-toggler {
            display: block;
            padding: 0;

            &.nav-link {
                @include media-breakpoint-down(md) {
                    z-index: $zindex-high;
                }
            }
        }

        .wishlist {
            padding: 0 $space-xl 0 0;

            @include media-breakpoint-up(lg) {
                padding: 0 $space-s;
            }
        }

        .minicart {
            margin-top: 0;

            .minicart-link {
                display: flex;
            }

            .icon-tote,
            .icon-tote-active {
                display: flex;
            }
        }
    }

    .wishlist {
        a {
            display: block;
        }
    }

    .user-account-menu {
        @include media-breakpoint-down(md) {
            display: none;
        }

        .account-icon-active {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
}

@include media-breakpoint-down(sm) {
    .stylist-chat-overlay-bg {
        .carousel-stripe-banner-container.show,
        .header-wrapper.container,
        .main-nav.top-navbar.sticky-top-nav {
            background-color: $stylist-chat-overlay-bg-color;
            opacity: 0.5;
        }
        .header-wrapper.container {
            opacity: 0.63;
        }
    }
}

.new-loyalty-badge {
    @include font-sans-semi-bold;
    border: 1px solid $loyalty-new-tag-color;
    padding: rem(1) rem(8);
    font-size: rem(12);
    line-height: rem(20);
    border-radius: rem(5);
    color: $loyalty-new-tag-color;
    position: relative;
    top: rem(-3);

    @include media-breakpoint-down(md) {
        width: auto;
        padding: rem(2) rem(10);
        top: 0;
        margin: 0 0 0 auto;
    }
}

.new-loyalty-badge-color {
    border: rem(1) solid $loyalty-guest-new-tag-color;
    color: $loyalty-guest-new-tag-color;
}

.header {
    color: $header-text-color;
    padding: rem(14) 0 0;
    position: static;

    @include media-breakpoint-up(lg) {
        padding: rem(14) 0;
    }

    .navbar {
        height: 100%;
        position: static;

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    &.main-menu {
        background-color: inherit;
        padding: 0;

        @include media-breakpoint-down(md) {
            background: $header-background-color;
        }
    }

    .nav-item.show {
        position: unset;
    }

    .navbar-nav {
        @include media-breakpoint-up(lg) {
            padding-top: rem(37);
        }
    }

    .menu-group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 0 0 calc(100% - rem(160));
        grid-column: 3 / 12;
        height: 100%;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-wrap: nowrap;
            flex: 0 0 auto;
            height: calc(100dvh - rem(68));
            justify-content: space-between;
            width: 100%;
            padding: $space-2xl 0 $space-3xl;
        }

        @include media-breakpoint-down(sm) {
            padding: $space-2xl 0;
        }
    }

    .navbar-nav .nav-item {
        margin-bottom: $space-xl;

        @include media-breakpoint-only(md) {
            margin-bottom: $space-2xl;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-right: $space-m;

            &:last-child {
                margin-right: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-right: $space-l;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:has([id*='sale']) {
            @include media-breakpoint-down(md) {
                padding-left: $page-grid-margin-md;
            }

            @include media-breakpoint-down(sm) {
                padding-left: $page-grid-margin-sm;
            }

            .nav-link {
                align-items: center;
                display: flex;

                .sun-small {
                    display: block;
                    margin-left: 0;
                    transform: translateY(rem(-1));

                    @include media-breakpoint-down(md) {
                        transform: translateY(0);
                    }
                }

                @include media-breakpoint-down(md) {
                    flex-grow: 1;
                    padding-left: 0;
                }
            }
        }

        .sun-small {
            margin-right: $space-xs;
            margin-top: rem(1);
            color: $dark-text;
            transition: color $duration-fast linear;

            @include media-breakpoint-down(md) {
                height: rem(24);
                margin-top: 0;
                width: rem(24);
            }
        }

        &:has([id*='sale']) {
            color: $header-text-color;
        }

        @include media-breakpoint-up(lg) {
            &:has(.print-nav-link) {
                position: relative;

                @keyframes printSlideshow {
                    0% {
                        background: url('../../images/print-1.svg') center / contain no-repeat;
                    }

                    50% {
                        background: url('../../images/print-2.svg') center / contain no-repeat;
                    }

                    100% {
                        background: url('../../images/print-3.svg') center / contain no-repeat;
                    }
                }

                &:before {
                    background: url('../../images/print-3.svg') center / contain no-repeat;
                    content: '';
                    display: block;
                    opacity: 0;
                    height: rem(16);
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: $zindex-higher;
                    pointer-events: none;
                    transition: background $duration-fast ease-out;
                }

                &:hover {
                    &:before {
                        opacity: 1;
                        animation: 1.8s ease-out infinite alternate printSlideshow;
                    }

                    .print-nav-link {
                        transition: color $duration-fast ease-out;
                        color: $white;
                    }
                }
            }
        }

        .print-nav-link {
            .svg-icon {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        .nav-link {
            @include font-sans-medium;
            @include font-sans-12px;
            color: $dark-text;
            line-height: rem(15);
            padding: 0 0 rem(37);
            text-transform: uppercase;
            z-index: $zindex-medium;

            @include media-breakpoint-down(md) {
                @include font-serif-extra-light;
                @include font-serif-34px;
                align-items: center;
                color: $dark-text;
                display: flex;
                padding: 0 $space-l;
                text-transform: capitalize;
            }

            @include media-breakpoint-down(sm) {
                @include font-serif-28px;
            }

            > .svg-icon {
                @include media-breakpoint-down(md) {
                    margin-left: $space-xs;
                    transform: translateY(rem(4));
                }
            }
        }

        border: none !important; // Important to override bootstrap borders
    }

    .navbar-bottom {
        display: none;

        @include media-breakpoint-down(md) {
            display: flex;
            margin-top: $space-7xl;
        }
        .nav-item {
            margin-bottom: $space-m;

            &:last-child {
                margin-bottom: 0;
            }

            .nav-link {
                @include font-sans-light;
                @include font-sans-16px;
                text-transform: unset;
            }
        }
    }

    .find-store-link {
        padding-top: rem(16);
    }

    .mobile-username {
        @include font-sans-semi-bold;
        @include font-sans-12px;
        text-transform: capitalize;
        padding: rem(12) 0 $space-s $space-m;
        margin-top: $space-xs;

        @include media-breakpoint-down(md) {
            @include font-sans-16px;
            margin-top: 0;
            padding: $space-s $page-grid-margin-md;
        }

        @include media-breakpoint-down(sm) {
            padding: $space-s $page-grid-margin-sm;
        }
    }
    svg {
        margin-right: rem(10);
    }
    .mobile-nav-item {
        margin-left: rem(30);
    }

    .navbar-expand-lg .navbar-nav {
        @include media-breakpoint-down(md) {
            flex-flow: column;

            .nav-link {
                padding-left: $page-grid-margin-md;
                padding-right: $page-grid-margin-md;
            }
        }

        @include media-breakpoint-down(sm) {
            .nav-link {
                padding-left: $page-grid-margin-sm;
                padding-right: $page-grid-margin-sm;
            }
        }
    }

    .nav-item > .nav-link {
        @include font-sans-semi-bold;
        @include font-sans-12px;
        border-bottom: rem(1) solid transparent;

        &::after,
        &::before {
            background: none;
            border: none;
            bottom: rem(-1);
            content: '';
            height: rem(1);
            margin: 0;
            padding: 0 !important; //overriding bootstrap style
            position: absolute;
            transition: all .35s ease-out;
            width: 0;

            @include media-breakpoint-up(lg) {
                background: $header-item-border-color;
            }
        }

        &::before {
            right: 50%;
        }

        &::after {
            left: 50%;
        }

        /* Safari only fix. It doesn't support transitions for pseudo selectors */
        @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {
                &::before,
                &::after {
                    transition: none;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &:hover,
            &:focus {
                outline: none;

                &::before,
                &::after {
                    width: 50%;
                }
            }
        }
        &.dropdown-toggle {
            .svg-icon {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
        }
    }

    .no-bold > .nav-link {
        @include font-sans-regular;
        fill: $base-text-color;

        svg {
            margin-right: rem(10);
        }
    }

    .dropdown {
        &-menu {
            top: 97%;
        }

        &-container {
            background: $header-background-color;
            display: none;
            left: 0;
            margin: 0;
            position: absolute;
            right: 0;
            z-index: $zindex-lowest;

            @include media-breakpoint-up(lg) {
                max-height: 0;
                padding: 0 rem(16);
            }

            .sub-menu-grid {
                @include grid-layout;

                .top-category {
                    @include media-breakpoint-down(md) {
                        grid-column: 1 / 16;
                        align-items: center;
                        display: flex;
                        margin-bottom: $space-4xl;
                        padding: rem(25) rem(44) rem(26);
                        position: relative;
                    }

                    @include media-breakpoint-down(sm) {
                        grid-column: 1 / 7;
                        margin-bottom: $space-l;
                        padding: rem(25) rem(13) rem(26);
                    }

                    .nav-link {
                        @include media-breakpoint-down(md) {
                            @include title-text-upper;
                            display: block;
                            font-family: inherit;
                            line-height: rem(15);
                            padding: 0;
                        }

                        .svg-icon {
                            @include media-breakpoint-down(md) {
                                opacity: 0;
                            }
                        }

                        &[id*='sale'] {
                            .sun-small {
                                @include media-breakpoint-down(md) {
                                    display: none;
                                }
                            }
                        }


                    }

                    .back {
                        padding: 0;
                        @include media-breakpoint-down(md) {
                            margin-right: $space-m;
                        }

                        svg {
                            @include media-breakpoint-down(md) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .sub-menu-wrapper {
                display: flex;
                flex-flow: column;
                grid-column: 4 / 15;

                @include media-breakpoint-up(lg) {
                    flex-flow: row;
                    gap: grid-span(1, 11);
                }

                @include media-breakpoint-down(md) {
                    flex-flow: column;
                    grid-column: 1 / 16;
                }

                .dropdown-menu {
                    @include media-breakpoint-up(lg) {
                        max-height: 100%;
                        padding: 0;
                        position: static;

                        .show & {
                            margin-top: 0;
                            min-height: 0;
                        }
                    }
                }

                .dropdown-container-menu {
                    &-item {
                        margin-bottom: $space-s;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        @include media-breakpoint-down(md) {
                            margin-bottom: $space-xl;
                        }
                    }

                    .dropdown-link {
                        @include font-sans-light;
                        @include font-sans-14px;
                        background-color: transparent;
                        color: $header-text-color;
                        display: block;
                        padding-left: 0;
                        text-decoration: none;
                        white-space: normal;
                        width: 100%;

                        @include media-breakpoint-down(md) {
                            @include font-sans-16px;
                            padding: 0 $page-grid-margin-md;
                        }

                        @include media-breakpoint-down(sm) {
                            padding: 0 $space-3xl;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .sub-menu-item {
                    flex-grow: 1;
                    flex-shrink: 0;
                    margin-right: 0;
                    max-width: rem(285);

                    @include media-breakpoint-down(md) {
                        margin-bottom: $space-4xl;
                        max-width: 100%;

                        &:first-child {
                            .sub-cat-name {
                                display: none;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .sub-cat-name {
                    display: block;

                    @include media-breakpoint-up(lg) {
                        @include body-12-medium;
                        color: $header-text-color;
                        line-height: rem(15);
                        margin-bottom: $space-m;
                        max-width: rem(188);
                        padding: 0;
                        text-transform: uppercase;

                        &::before,
                        &::after {
                            display: none;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        @include heading-h4;

                        color: $base-text-color;
                        font-family: $sprig-serif;
                        margin-bottom: $space-2xl;
                        padding: 0 $space-l 0 $page-grid-margin-md;
                    }

                    @include media-breakpoint-down(sm) {
                        padding: 0 $space-3xl;
                    }
                }
            }

            a {
                @include font-sans-regular;
                @include font-sans-12px;
            }

            .see-all-link {
                padding: $space-m;
                text-align: center;
                text-decoration: underline;
            }

            li {
                display: block;
                width: 100%;
                clear: both;
                min-width: rem(200);
                color: inherit;
                white-space: nowrap;
                background-color: transparent;
                border: 0;

                img {
                    display: block;
                }
            }

            &.is-checked {
                @include media-breakpoint-down(md) {
                    .banner-wrapper {
                        order: 0;
                    }
                }
            }
        }
    }

    .dropdown-item {
        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
        }

        &.top-category {
            &:hover,
            &.active {
                @include media-breakpoint-down(md) {
                    background: none;
                }
            }
        }
    }

    .dropdown {
        position: unset;

        &.show {
            .nav-link {
                &::before,
                &::after {
                    width: 50%;
                }

                @include media-breakpoint-down(md) {
                    padding-right: $space-l;
                    white-space: normal;
                }
            }
        }
    }

    .dropdown-menu {
        position: unset;
        box-shadow: none;

        &.show {
            margin-top: rem(-1);
            max-height: rem(437);
            padding: $space-2xl 0 $space-3xl;
            z-index: $zindex-low;
        }
        @include media-breakpoint-up(lg) {
            top: 94%;
        }

        @include media-breakpoint-down(md) {
            flex-flow: column;
        }
    }

    .navbar {
        padding: 0;

        @include media-breakpoint-down(md) {
            flex-flow: column;
            overflow-y: auto;
        }
    }
}

.menu-toggleable-left .close-menu {
    display: none;
}

.navbar-toggler {
    .icon-close {
        display: none;
    }

    .icon-ham {
        display: flex;
    }
}

.menu-toggleable-left.in + .header-nav-icons {
    .navbar-toggler {
        .icon-close {
            display: block;
        }

        .icon-ham {
            display: none;
        }

        @media screen and (width: 1024px) {
            height: rem(24);
            position: fixed;
            right: rem(47);
            top: rem(25);
            width: rem(24);
        }

        @include media-breakpoint-down(md) {
            height: rem(24);
            right: rem(47);
            top: rem(25);
            width: rem(24);
            position: fixed;
        }

        @include media-breakpoint-down(sm) {
            top: rem(25);
            right: $space-m;
        }
    }
}

.menu-toggleable-left.in {
    .navbar.navbar-expand-lg {
        padding-top: rem(11);
    }

    .header-nav-logo {
        @media screen and (width: 1024px) {
            padding: 0 $page-grid-margin-md;
            width: rem(210);
        }

        @include media-breakpoint-down(md) {
            padding: 0 $page-grid-margin-md;
            width: rem(210);
        }

        @include media-breakpoint-down(sm) {
            padding: 0 $page-grid-margin-sm;
            width: rem(150);
        }
    }

    .menu-group {
        @include media-breakpoint-down(md) {
            padding-top: $space-4xl;
        }

        @include media-breakpoint-down(sm) {
            padding-top: $space-2xl;
        }
    }

    .dropdown-menu {
        display: none;

        @include media-breakpoint-down(md) {
            padding: 0 0 $space-4xl;
        }
    }
}

.menu-toggleable-left.in .show > .dropdown-menu {
    display: block;

    @include media-breakpoint-down(md) {
        z-index: $zindex-high;
    }
}

.menu-toggleable-left.navbar-toggleable-lg {
    position: static;

    @include media-breakpoint-down(md) {
        position: fixed;
    }

    &.in {
        @include media-breakpoint-down(md) {
            min-width: 100%;
            position: fixed;
        }
    }
}

/* for 200% zoom */
.menu-toggleable-left.in .show > .dropdown-menu {
    @include media-breakpoint-down(md) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 0;
        transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
        display: block;
    }
}

.menu-toggleable-left {
    .show > .dropdown-menu {
        overflow-x: auto;
    }

    .nav-link:focus {
        outline: none;
    }

    .nav-item .nav-link,
    .dropdown-container li a {
        font-size: rem(12);
        line-height: rem(24);
        position: relative;
        @include media-breakpoint-up(lg) {
            line-height: rem(16);
        }

        .arrow {
            position: absolute;
            right: rem(20);
            top: rem(40);
        }
    }
}

.menu-toggleable-left.navbar-toggleable-md {
    top: rem(48);

    &.in {
        @include media-breakpoint-down(md) {
            left: rem(-1);
            height: 100%;
            overflow: auto;
        }
    }
}

// adjusting hamburd menu postion based on banner avaivlbity and stick header
.has-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(88);
        height: 100%;
        overflow: auto;
    }
}

// adjusting hamburd menu postion based on banner, skinny banner availability and stick header
.has-skinny-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(168);
        height: calc(100% - 168px);
    }
}
.has-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(88);
        height: 100%;
        overflow: auto;
    }
    &.has-skinny-banner {
        .menu-toggleable-left.navbar-toggleable-md {
            top: rem(208);
            height: calc(100% - 208px);
        }
    }
}

.is-sticky {
    .has-banner {
        .menu-toggleable-left.navbar-toggleable-md {
            top: rem(48);
            height: 100%;
            overflow: auto;
            padding: 0;
        }
    }
    .has-skinny-banner {
        .menu-toggleable-left.navbar-toggleable-md {
            top: rem(168);
            height: calc(100% - 168px);
            padding: 0;
        }
    }
}

.mobile-menu-open {
    overflow: hidden;
    // The below code is to restrict scroll on ios/safari when hamburg menu is open
    // since just overflow hidded doesn't work in mobile ios/safari
    height: 100%;
    width: 100%;
    position: fixed;

    .modal-background {
        display: block !important;
        @include media-breakpoint-up(lg) {
            top: rem(50);
        }
    }
}

.skinny_banner {
    position: relative;
    z-index: $zindex-low;
}

@include media-breakpoint-down(md) {
    .skinny_banner {
        .evg-container {
            height: rem(104);
        }
    }
    .navbar.main-nav {
        .menu-toggleable-left.navbar-toggleable-md.in {
            position: fixed;
            top: rem(48);
            height: calc(100% - 48px);
        }
        &.has-skinny-banner {
            .menu-toggleable-left.navbar-toggleable-md.in {
                position: fixed;
                top: rem(168);
                height: calc(100% - 168px);
            }
        }

        &.has-banner {
            .menu-toggleable-left.navbar-toggleable-md.in {
                position: fixed;
                top: rem(88);
                height: calc(100% - 88px);
            }
            &.has-skinny-banner {
                .menu-toggleable-left.navbar-toggleable-md.in {
                    position: fixed;
                    top: rem(208);
                    height: calc(100% - 208px);
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #girls-shop-by-size {
        li {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.checkout-header-container {
    padding-right: 0;
}

.checkout-header.navbar-header {
    justify-content: space-between;
    padding-right: $space-m;
    align-items: center;

    @include media-breakpoint-down(sm) {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        margin-left: clamp(rem(35), 5vw, $space-7xl);
        margin-right: clamp(rem(35), 5vw, $space-7xl);
    }

    @include media-breakpoint-up(lg) {
        margin-left: clamp(rem(52), 5vw, $space-7xl);
        margin-right: clamp(rem(52), 5vw, $space-7xl);
    }

    .checkout-home-logo svg {
        fill: $header-logo-color;
        width: rem(110);
    }
}

.header {
    &:has(.checkout-header) {
        padding-right: 0;
    }

    .header-place-order {
        display: none;

        @include media-breakpoint-up(lg) {
            &.place-order-stage {
                display: flex;
            }
        }
    }

    .go-back-home-link {
        @include body-14-light;
        display: flex;
        color: $base-text-color;

        @include media-breakpoint-up(lg) {
            &.place-order-stage {
                display: none;
            }
        }
    }

    .js-header-place-order {
        height: $space-4xl;
        line-height: $space-4xl;
        @include body-12-medium;
    }
}

header {
    &:has(.checkout-header) {
        position: static !important;
    }
}

body {
    &:has(.modal.open),
    &:has(.modal.show),
    &:has(.sheet.show),
    &.modal-open {
        header,
        .page-top-navigation,
        #carousel-stripe-banner,
        .carousel-stripe-banner-container {
            z-index: $zindex-header-modal-open;
        }
    }
}
