.wishlist-sheet,
.wishlist-sheet-edit {
    &-inner {
        position: relative;
        height: 100%;
    }

    &-availability {
        left: rem(63);
        position: absolute;
        text-transform: none;
        top: 50%;
        transform: translateY(-50%);

        &.not-available {
            color: $wishlist-unavailable-msg;
        }
    }

    &.m-create {
        .wishlist-sheet-item {
            @include body-14-light;

            border-top: rem(1) solid $divider-color;
            padding-block: $space-xl;
            position: relative;

            &:first-child {
                border-top: none;
            }

            &.m-selected {
                @extend .lp-icon-tick;

                &::after {
                    right: $space-m;
                }
            }

            &.m-new {
                &::after {
                    content: '+';
                    display: inline-block;
                    margin-left: $space-xs;
                }
            }
        }

        .form-control-label {
            top: rem(26);
        }
    }

    &.m-edit {
        .modal-actions {
            display: flex;
            gap: $space-m;

            .edit-list {
                margin-top: 0;
            }
        }

        .confirm-remove-wishlist-text {
            @include body-16-light;

            color: $dark-text;
            margin-bottom: 0;
        }
    }

    &.m-add-to-cart {
        .wishlist-sheet-inner {
            position: static;
        }

        .card {
            border: none;
            margin-bottom: 0;
            position: static;
        }

        .wishlist-card {
            position: static;
        }

        .attribute {
            margin-top: 0;
        }

        .remove-from-wishlist {
            display: none;
        }

        .wishlist-card {
            display: block;
        }

        .wishlist-item-image {
            display: none;
        }

        .product-image {
            max-height: rem(100);
            max-width: rem(80);
        }

        .wishlist-item-details {
            display: none;
        }

        .size-list {
            align-items: center;
            border-top: rem(1) solid $divider-color;
            display: flex;
            justify-content: space-between;
            position: relative;

            &:last-child {
                border-bottom: rem(1) solid $divider-color;
            }

            .tile-body-notify-me {
                @extend .tertiary-link;

                padding-right: 0;
                text-align: right;

                i {
                    display: none;
                }
            }
        }

        .size-btn {
            height: rem(70);
            padding-left: 0;
            text-align: left;
            width: 100%;

            &.not-available {
                width: unset;
            }

            &.selected {
                @extend .lp-icon-tick;

                &::after {
                    right: $space-m;
                }
            }

            &.not-available {
                span:first-child {
                    display: inline-block;
                    margin-right: $space-s;
                    opacity: 0.5;
                    text-decoration: line-through;
                    width: rem(55);
                }
            }

            &.btn {
                text-transform: none;
            }
        }

        .size-chart-link {
            margin-top: $space-2xl;
        }

        .add-to-cart-wishList {
            button {
                @extend .btn;
                @extend .button-primary;

                width: 100%;
            }
        }

        .wishlist-action-links {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &.m-share {
        .wishlist-share-section {
            align-items: center;
            border: rem(1) solid $border-color-med;
            color: $dark-text;
            display: flex;
            height: rem(72);
            padding: $space-s 0 $space-s $space-m;
        }

        .wishlist-share-copy {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .btn {
            flex-shrink: 0;
        }
    }

    .sheet-body-content {
        height: 100%;
        padding-bottom: $space-3xl;
    }

    .wishlist-modal-header {
        @include heading-h2;

        color: $headline-color;
        margin-bottom: $space-m;

        @include media-breakpoint-up(md) {
            margin-bottom: $space-2xl;
        }
    }

    .modal-header-title {
        @include body-12-medium;

        color: $headline-color;
    }

    .title-text-upper {
        @include title-text-upper;

        color: $headline-color;
    }
}

#wishlists-add-to-cart {
    z-index: $zindex-highest;
}
