@import '../functions/grid-span';
@import '../mixins/themed-scroll';

$overlay-opacity: 0.5;

@import './sheet';
@import './flex-tabs';

// LP Scrollbar styles
.lp-scrollbar {
    @include scroll-custom;
}

/* Safari displays vertical & horizontal scroll bar for overflow:auto style */
.no-vertical-scroll {
    overflow-y: hidden;
}

// background opacity styles
.fade-bg {
    opacity: 0;
    position: fixed;
    z-index: $zindex-lowest;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background-color: rgba($black, $overlay-opacity);
    transition:
        opacity 0.5s,
        width 0.1s 0.5s,
        height 0.1s 0.5s;
}

.open {
    .fade-bg {
        opacity: 1;
        width: 100%;
        height: 100%;
        transition: opacity 0.5s;
    }
}

// LP Cross Icon in CSS
// - use class name for 'lp-icon-plus', 'lp-icon-cross'
// - add icon-w24 class name to change size 'lp-icon-plus icon-w24', 'lp-icon-cross icon-w24'
.lp-icon {
    &-plus,
    &-cross {
        display: block;
        position: relative;
        height: rem(16);
        width: rem(16);

        &::before,
        &::after {
            content: ' ';
            position: absolute;
            height: rem(16);
            width: 1px;
            background-color: $icon-background-color;
            transition: background-color $duration-default ease;

            &:hover {
                background-color: rgba($icon-background-color, 0.8);
            }
        }

        &.icon-w24 {
            display: block;
            position: relative;
            height: rem(24);
            width: rem(24);

            &::before,
            &::after {
                height: rem(24);
            }
        }
    }

    &-plus {
        &::before {
            transform: rotate(0);
        }

        &::after {
            transform: rotate(90deg);
        }
    }

    &-cross {
        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    &-chevron {
        &::before,
        &.double::after {
            content: '';
            position: relative;
            color: $icon-background-color;
            border-style: solid;
            border-width: rem(1) rem(1) 0 0;
            height: rem(16);
            width: rem(16);
            transform: rotate(-45deg);
            display: inline-block;
        }

        &.double::after {
            margin-left: rem(-3);
        }

        &.chevron-left {
            &::before,
            &.double::after {
                transform: rotate(-135deg);
            }
        }

        &.chevron-right {
            &::before,
            &.double::after {
                transform: rotate(45deg);
            }
        }
    }

    &-caret {
        &::after {
            position: absolute;
            content: '';
            transform: rotate(45deg);
            height: rem(12);
            width: rem(12);
            border-bottom: rem(1) solid $icon-background-color;
            border-right: rem(1) solid $icon-background-color;
            right: 0;
            transition: all $duration-default ease;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &[aria-expanded='true'] {
            &::after {
                transform: rotate(225deg);
                top: rem(9);
            }
        }
    }

    &-tick {
        &::after {
            background-image: url('data:image/svg+xml;utf8,<svg width=%2212%22 height=%2212%22 viewBox=%220 0 12 12%22 fill=%22none%22 xmlns=%22http://www.w3.org/2000/svg%22><g id=%22Icon/Checkmark Small%22><path id=%22Vector 1%22 d=%22M1 5.75L4.5 9.25L11 2.75%22 stroke=%22black%22/></g></svg>');
            background-repeat: no-repeat;
            background-position: center;
            content: '';
            display: block;
            height: rem(12);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: rem(12);
        }
    }
}

// slideout menu styles & transition
.lp-slideout {
    &-menu {
        position: fixed;
        top: 0;
        height: 100%;
        width: $sidenav-width-xs;
        right: -$sidenav-width-xs;
        transition: all 0.5s;
        overflow: auto;
        z-index: $zindex-low;

        @include media-breakpoint-up(md) {
            width: $sidenav-width-md;
            right: -$sidenav-width-md;
            max-width: $sidenav-max-width-md;
        }

        @include media-breakpoint-up(lg) {
            width: $sidenav-width-lg;
            right: -$sidenav-width-lg;
            max-width: $sidenav-max-width-lg;
        }
    }

    &.active {
        .lp-slideout-menu {
            right: 0;
        }
    }

    &-open {
        .fade-bg {
            opacity: 1;
            width: 100%;
            height: 100%;
            transition: opacity 0.5s;
        }
    }
}

/*******************************
 MODAL AS RIGHT SIDEBAR
 Add "lp-slideout-modal" in modal parent div, ex: class="lp-slideout-modal modal fade"

Structure example:
   modal-content-flex-pass (on every element before actual content)
       modal-content-flex
            <ismodalnav showarrow="${false}" />
            modal-body
            modal-footer

By default dialog is adoptive to content height on mobile, to avoid this add "m-full-height" class
*******************************/
.lp-slideout-modal.modal {
    &.fade {
        opacity: 1;
    }

    &.show {
        opacity: 1;

        .modal-dialog {
            right: 0;

            @include media-breakpoint-down(sm) {
                bottom: 0;
            }
        }
    }

    .modal-dialog {
        box-shadow: 0 0 rem(16) rgba(0, 0, 0, 0.15);
        display: flex; // needed to pass height to children
        flex-direction: column;
        height: 100%;
        margin: auto;
        max-width: 100%;
        position: fixed;
        transform: translate3d(0%, 0, 0);
        transition: $duration-default;
        transition-property: all;

        @include media-breakpoint-down(sm) {
            background: transparent;
            bottom: -120%;
            box-shadow: none;
            height: 85%;
            left: 0;
            max-height: 85%;
            pointer-events: none; // since part of modal overlaps backdrop on SM we need to make it transparent for clicks
            right: 0;
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            right: -$sidenav-width-md;
            width: $sidenav-width-md;
        }

        @include media-breakpoint-up(lg) {
            right: -$sidenav-width-lg;
            width: $sidenav-width-lg;
        }

        &:not(:has(.modal-footer)) {
            .modal-body {
                padding-bottom: $space-4xl;
            }
        }
    }

    .modal-content {
        background: transparent;
        border: none;
        border-radius: 0;
        display: flex;
        flex-direction: column; // pass height to modal-body
        height: 100%;
        min-height: 100%;

        @include media-breakpoint-down(sm) {
            pointer-events: none; // since part of modal overlaps backdrop on SM we need to make it transparent for clicks
        }
    }

    .modal-content-flex-pass {
        height: 100%;

        @include media-breakpoint-down(sm) {
            pointer-events: none; // since part of modal overlaps backdrop on SM we need to make it transparent for clicks
        }
    }

    .modal-content-flex {
        display: flex;
        flex-direction: column;
        height: 100%;

        @include media-breakpoint-down(sm) {
            pointer-events: none; // since part of modal overlaps backdrop on SM we need to make it transparent for clicks
            justify-content: flex-end;
        }
    }

    .modal-top {
        background: $color-white;
        padding: rem(10) $space-4xl;
        pointer-events: auto; // need to restore pointer events on modal content elements

        @include media-breakpoint-down(sm) {
            box-shadow: 0 rem(-16) rem(16) rem(-16) rgba(0, 0, 0, 0.15); // leave only top shadow
            padding: rem(9) $space-l rem(4);
        }
    }

    .modal-top-controls {
        // this element needed to have posibility to insert other content into modal-top
        align-items: baseline;
        display: flex;
        justify-content: space-between;
    }

    .modal-nav {
        @include font-sans-medium;
        align-items: baseline;
        color: $dark-text;
        display: flex;
        font-size: rem(12);
        letter-spacing: rem(0.36);
        line-height: 1.2;
        min-height: rem(44);
        text-transform: uppercase;

        svg {
            height: rem(24);
            margin: rem(10) rem(10) rem(10) rem(-16);
            position: relative;
            top: rem(7);
            width: rem(24);
        }
    }

    button.modal-nav {
        &:hover {
            color: $modal-nav-btn-color-hover;
        }
    }

    .close {
        border-radius: 50%;
        font-size: rem(15);
        height: rem(44);
        margin: 0 rem(-20) 0 auto;
        padding: rem(10);

        @include media-breakpoint-down(sm) {
            margin-right: rem(-12);
        }

        &:hover {
            background: $modal-nav-btn-bg-hover;
            transition: background $duration-default ease;
        }

        svg {
            height: rem(24);
            width: rem(24);
        }
    }

    .modal-title {
        @include heading-h2;

        color: $dark-text;
        margin-bottom: $space-2xl;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .modal-stickybody {
        background: $color-white;
        display: block;
        padding: 0 $space-l rem(10);
        pointer-events: auto;

        @include media-breakpoint-up(md) {
            padding-inline: $space-4xl;
        }
    }

    .modal-body {
        @include scroll-custom;

        background: $color-white;
        display: block;
        flex-basis: initial;
        flex-grow: 0;
        flex-shrink: 1;
        height: 100%;
        margin: 0;
        overflow-y: scroll;
        padding: rem(9) $space-l 0;
        pointer-events: auto;

        @include media-breakpoint-up(md) {
            padding: $space-l $space-3xl $space-l $space-4xl;
        }
    }

    .modal-footer {
        background: $color-white;
        border-top: rem(1) solid transparent;
        display: block;
        padding: rem(14) $space-l $space-l;
        pointer-events: auto;
        transition: border-top-color 0.2s linear;

        @include media-breakpoint-up(md) {
            padding-inline: calc($space-4xl - $modal-footer-margin-between * 0.5);
        }

        &.separator {
            border-top-color: $modal-footer-divider;
        }

        .prices-add-to-cart-actions {
            margin: 0;
        }
    }

    .modal-dialog.m-full-height {
        // Modifier to make sheet full height on mobile
        height: 100%;
        max-height: 100%;
    }

    .modal-dialog.m-no-adoptive-height {
        // Modifier to disable adoptive height on mobile
        .modal-body {
            height: 100%;
        }
    }

    .modal-dialog.m-overlayed-top {
        // Modifier to make top over the content
        .modal-top {
            background: transparent;
            margin-bottom: rem(-64);
            position: relative;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                margin-bottom: rem(-57);
            }
        }

        .modal-body {
            padding-top: 0;
        }
    }
}

.modal-backdrop {
    width: 0;
    height: 0;
    transition:
        opacity $duration-default,
        width 0.1s 0.3s,
        height 0.1s 0.3s;
    z-index: $zindex-highest;

    &.show {
        width: 100%;
        height: 100%;
        opacity: $overlay-opacity;
        transition: opacity $duration-default;
    }
}

.custom-info-icon {
    color: transparent;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: rem(24);
        mask-image: $icon-info-base;
        mask-repeat: no-repeat;
        mask-position: center;
        background: $base-text-color; // icon color
    }
}

/*******************************
* Generic Modal
*******************************/
.generic-modal-dialog {
    .modal-dialog {
        padding: 0 rem(8);
        margin-top: rem(100);
    }

    .modal-content {
        padding: rem(32);
    }

    .generic-confirmation-body {
        @include media-breakpoint-up(md) {
            margin: 0 rem(32) rem(40);
        }

        margin-bottom: rem(24);
        text-align: center;
        padding: 0;
        @include font-sans-semi-bold;
        font-size: rem(16);
        line-height: rem(24);
        color: $base-text-color;
    }

    .generic-confirmation-body-body {
        margin-bottom: rem(24);
        text-align: center;
        @include font-sans-regular;
        font-size: rem(14);
        line-height: rem(20);
        color: $base-text-color;
    }

    .generic-confirmation-body-heading {
        margin-bottom: 0;
        text-align: center;
        padding: 0;
        @include font-sans-semi-bold;
        font-size: rem(16);
        line-height: rem(24);
        color: $link-color;
    }

    .generic-confirmation-header {
        .close {
            top: rem(24);
            right: rem(32);
        }
    }

    .modal-footer {
        border: none;
    }

    &.modal.fade .modal-dialog {
        margin: 0 auto;
        transform: translateY(-50%);
        top: 50%;
    }
}

/*******************************
* Back arrow icon
*******************************/
.back-arrow {
    font-size: rem(12);
    line-height: rem(16);
    @include font-sans-regular;
    margin-top: rem(16);
    color: $base-text-color;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-left: rem(16);

    &::after {
        width: rem(12);
        height: rem(12);
        content: '';
        border-top: 1px solid $base-border-color;
        border-left: 1px solid $base-border-color;
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: rem(2);
    }
}

/* custom error alert in form validations */
.custom-error-alert {
    @include font-sans-regular;
    color: $error-text-color;
    font-size: rem(12);
    line-height: rem(16);
    padding: rem(16) 0;
    letter-spacing: rem(0.4);
}

/*******************************
* Alert styles
*******************************/
.alert-info {
    background-color: $alert-info-background-color;
    border-color: $alert-info-border-color;
    color: $alert-info-text-color;
}

.animate {
    height: var(--anim-static-height);

    &-slide-up {
        height: 0 !important;
        overflow-y: hidden;
        transition: height var(--slide-up-duration, 300ms) var(--slide-up-ease, ease-in-out);
    }
}

@mixin no-scrollbar {
    -ms-overflow-style: none;
    overflow: auto;
    padding: 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
.cvv-info.cvv-info::before,
.custom-info-icon.ph-number-info::before {
    display: none;
}
