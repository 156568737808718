@import './global/global';

.account-need-help {
    margin-top: rem(64);

    @include media-breakpoint-up(md) {
        margin-top: rem(104);
    }

    .help-header {
        @include font-sans-semi-bold;
        font-size: rem(16);
        line-height: rem(24);
        text-transform: uppercase;
        color: $base-text-color;
        text-align: center;
        margin: rem(40) 0 rem(8);

        @include media-breakpoint-up(md) {
            margin: rem(48) 0 rem(8);
        }
    }

    .help-message {
        @include font-sans-regular;
        font-size: rem(14);
        line-height: rem(20);
        color: $base-text-color;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 45%;
            margin: 0 auto;
        }
    }

    .help-links {
        display: flex;
        justify-content: center;
        margin: rem(8) auto 0;
        width: auto;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            max-width: unset;
            flex-direction: row;
            margin: rem(40) auto 0;
        }

        &-wrapper {
            margin: auto;
            display: table;

            @include media-breakpoint-up(lg) {
                margin: 0;
                display: flex;
                width: 100%;
                justify-content: space-between;

                .service-link,
                .call-link {
                    a {
                        display: flex;
                    }
                }
            }
        }
    }

    .call-link {
        margin-right: 0;
        margin-left: 0;

        @include media-breakpoint-up(md) {
            margin-right: rem(32);
            margin-left: rem(32);
        }
    }

    .service-link,
    .call-link,
    .time {
        margin-top: rem(16);
        display: flex;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            display: inline-flex;
        }
    }

    .call-icon,
    .mail-icon,
    .calendar-icon {
        margin-right: rem(16);
    }

    .time {
        p {
            margin-bottom: 0;
            line-height: rem(20);

            @include media-breakpoint-up(md) {
                margin-bottom: rem(16);
            }
        }
    }
}
