#carousel-stripe-banner {
    align-items: center;
    background-color: var(--stripe-banner-bg-color);
    border-bottom: rem(1) solid $divider-color;
    display: flex;
    justify-content: center;
    padding: $space-m rem(52);
    position: relative;
    z-index: $zindex-highest;
    transition: background $duration-fast linear;

    @include media-breakpoint-down(md) {
        padding: $space-m $space-4xl;
    }


    .carousel-inner {
        align-items: center;
        display: flex;
        flex: 1;
        text-align: center;
        width: auto;
    }

    .carousel-item {
        color: var(--stripe-banner-text-color);

        @include media-breakpoint-down(sm) {
            display: block;
            height: 100%;
            opacity: 0;
            z-index: $zindex-negative;

            &.active {
                display: block;
                opacity: 1;
                z-index: $zindex-zero;
            }
        }

        &:hover {
            text-decoration: none !important;
        }

        span {
            @include body-14-light;
            color: inherit !important;
            margin-right: $space-s;

            @include media-breakpoint-down(sm) {
                display: block;
                margin-right: 0;
            }
        }

        a {
            @extend .tertiary-link;
            @include body-14-light;
            color: var(--stripe-banner-link-color);
            text-overflow: ellipsis;

            &:hover {
                text-decoration: none !important;
            }
        }
    }

    .control {
        position: absolute;
        right: $space-l;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-down(md) {
            right: $space-m;
        }
    }

    .stripe-banner-pause {
        svg {
            stroke-width: 1.2;
            stroke: var(--stripe-banner-text-color);
        }
    }

    .stripe-banner-next {
        svg {
            stroke: var(--stripe-banner-text-color);
        }
    }

    .close-banner {
        left: $space-l;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-down(md) {
            left: $space-m;
        }

        svg {
            stroke-width: 1.4;
            stroke: var(--stripe-banner-text-color);
        }
    }

    .close {
        opacity: 1;
    }
}
