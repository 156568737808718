// accordion
.accordion-container {
    .card {
        @include font-sans-regular;
        font-size: rem(14);
        line-height: rem(20);
        border: none;
        border-radius: 0;
        margin-bottom: 0;
        border-bottom: thin solid $silver;

        .btn {
            position: relative;
        }

        &-body {
            @include font-sans-light;
            font-size: rem(16);
            line-height: rem(24);
            padding: 0 0 $space-2xl;

            .content {
                padding: 0;
                margin: 0;
            }
        }

        &-header {
            border: none;
            border-radius: 0;
            padding: 0;

            h5 {
                font-size: inherit;
                font-weight: $font-weight-regular;
                line-height: rem(1);
            }

            .btn {
                @include label-info;

                padding: $space-xl $space-m $space-xl 0;
                height: inherit;
                text-decoration: none;
                border: none;
                width: 100%;
                text-align: left;

                &.label-light {
                    @include body-16-light;

                    text-transform: none;
                }

                &::after {
                    position: absolute;
                    content: '';
                    transform: rotate(45deg) translateY(-50%);
                    height: rem(5);
                    width: rem(5);
                    border-bottom: rem(1) solid $icon-background-color;
                    border-right: rem(1) solid $icon-background-color;
                    right: rem(5);
                    top: 45%;
                    transition: all $duration-default ease;
                }

                &[aria-expanded='true'] {
                    &::after {
                        transform: rotate(225deg);
                    }
                }
            }
        }
    }
}

.info-icon {
    .tooltip {
        @include font-sans-regular;
        background-color: $dropdown-background-color;
        color: $base-text-color;
        line-height: rem(16);
        font-size: rem(12);
        border: rem(1) solid $silver;
        padding: $space-m;

        &::after {
            border-top: rem(7) solid $silver;
        }
    }
}

.sticky-top {
    z-index: $zindex-highest;
}

.input-group-append .btn,
.input-group-prepend .btn {
    z-index: 0;
}

.container .breadcrumb {
    border-bottom: 0;
    padding: $space-xl 0 0;
    margin: 0;
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: rem(0.4);
}

.container {
    @include media-breakpoint-only(md) {
        max-width: 100%;
    }

    @include media-breakpoint-only(lg) {
        max-width: 100%;
    }
}

body.modal-open,
.no-scroll {
    -webkit-overflow-scrolling: touch;
}

body.modal-open {
    overflow: hidden !important;
}

body:has(.search-results-container):has(.modal.show),
body:has(.stylitics-modal) {
    overflow: hidden !important;
}

// rewriting key properties from bootstrap (_custom-forms.scss) with custom properties to make overrides easier
.custom-control {
    --custom-control-font-size: #{$font-size-base};
    --custom-control-line-height: #{$line-height-base};
    --custom-control-gutter: #{$custom-control-gutter};
    --custom-control-indicator-size: #{$custom-control-indicator-size};

    min-height: calc(var(--custom-control-font-size) * var(--custom-control-line-height));
    padding-left: calc(var(--custom-control-gutter) + var(--custom-control-indicator-size));

    &-label {
        &::before,
        &::after {
            // must have units -- otherwise calc complains
            --custom-control-indicator-horiz-offset: #{rem(0)};

            top: calc(0.5 * (var(--custom-control-font-size) * var(--custom-control-line-height) - var(--custom-control-indicator-size)));
            left: calc(-1 * (var(--custom-control-gutter) + var(--custom-control-indicator-size)) + var(--custom-control-indicator-horiz-offset));
            width: var(--custom-control-indicator-size);
            height: var(--custom-control-indicator-size);
        }
    }
}
