.image-banner {
    background-color: var(--bg-color, transparent);
    color: $dark-text;
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    margin: 0 grid-span(1);
    position: relative;
    width: auto;

    @include media-breakpoint-down(sm) {
        margin: 0;
    }

    &.on-dark {
        color: $light-text;

        .button-secondary-transparent {
            border-color: $secondary-border-on-dark;
            color: $light-text;
        }

        a:not(.btn) {
            color: $light-text;
            text-decoration-color: $link-decoration-color-on-dark;
        }
    }

    &-inner {
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: $space-xl;
        grid-column: 9/13;
        padding: $space-xl 0;

        @include media-breakpoint-down(md) {
            grid-column: 2/13;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1/14;
            padding: $space-xl $space-l;
        }
    }

    &.image-bg {
        background-image: var(--bg-image-lg);
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(sm) {
            background-image: var(--bg-image-m);
            margin: 0 $space-l;
        }

        .image-banner-inner {
            align-items: center;
            flex-direction: row;
            grid-column: 1/14;
            justify-content: space-between;
            padding: $space-l rem(160);

            @include media-breakpoint-down(md) {
                padding: $space-xl $space-4xl;
            }

            @include media-breakpoint-down(sm) {
                align-items: flex-start;
                flex-direction: column;
                padding: $space-xl $space-l;
            }
        }

        copy {
            gap: $space-s;
        }

        .promo-title {
            @include font-serif-20px;
        }

        .promo-description {
            @include body-14-light;
        }
    }

    &.image-gwp {
        .image-banner-wrapper {
            aspect-ratio: 8/5;
        }
    }

    &-wrapper {
        grid-column: 1/8;
        aspect-ratio: 3/2;

        @include media-breakpoint-only(md) {
            aspect-ratio: 4/3;
        }

        @include media-breakpoint-down(md) {
            grid-column: 1/14;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    copy {
        display: flex;
        flex-direction: column;
        gap: $space-m;
    }

    .promo-eyebrow {
        @include label-info;
    }

    .promo-title {
        @include heading-h4;
    }

    .promo-description {
        @include body-16-light;

        a {
            margin-left: $space-s;
        }
    }

    actions {
        position: relative;
        z-index: $zindex-lowest;
    }

    a {
        color: $dark-text;
        cursor: pointer;

        + a {
            margin-left: $space-s;
        }

        &:not(.btn) {
            @include body-14-light;
            @include link-underline;

            text-decoration-color: $link-decoration-color-on-light;
        }
    }

    .button-secondary-transparent {
        border-color: $secondary-border-on-light;
        white-space: nowrap;
    }

    .link-entire {
        position: absolute;
        inset: 0;
    }
}
