.recommendations {
    position: relative;

    &:has(div:not(:empty)) {
        margin-top: $space-7xl;

        @include media-breakpoint-down(md) {
            margin-top: $space-6xl;
        }
    }

    .product-listing {
        .image-container {
            min-height: auto;

            .swiper-bottom {
                display: none;
            }

            .swiper-button {
                opacity: 0;
                z-index: $zindex-negative;
            }

            &:hover .swiper-button {
                @include media-breakpoint-up(lg) {
                    opacity: 1;
                    z-index: $zindex-lowest;
                }
            }
        }

        .grid-tile {
            .product-tile .tile-body {
                .loyalty-early-access-lock-container {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }

    .title {
        @include heading-h3;

        margin-bottom: $space-xs;
        width: 100%;
    }

    .description {
        @include font-sans-14px;

        margin-bottom: $space-xs;
    }

    &-image {
        width: 100%;
    }

    &-products {
        display: flex;
        flex-wrap: nowrap;

        @include media-breakpoint-up(md) {
            flex-wrap: wrap;
        }
    }

    .content-center {
        justify-content: normal;
    }

    @include media-breakpoint-up(md) {
        .content-center {
            justify-content: center;
        }
    }

    div[id^='cq_recomm_slot'] {
        width: 100%;
    }
}

.product-listing-button {
    margin-top: rem(24);

    @include media-breakpoint-up(md) {
        margin-top: rem(40);
    }
}

.recently-carousel {
    @include media-breakpoint-up(md) {
        padding-inline: grid-span(1);
    }

    .title {
        margin-bottom: $space-m;

        @include media-breakpoint-down(sm) {
            padding: 0 $space-l;
        }
    }

    .swiper-bottom {
        @include media-breakpoint-up(md) {
            display: none;
        }

        &:has(.swiper-scrollbar-lock) {
            display: none;
        }

        .swiper-scrollbar-lock {
            display: none;
        }
    }

    .product-tile {
        .image-container {
            @include product-image;

            overflow: visible;
        }
    }
}
