.carousel-feature {
    @include grid-layout;

    &.common-shelf {
        .homepage-items & {
            max-width: $site-max-width;
            margin: $space-7xl auto 0;

            @include media-breakpoint-down(md) {
                margin-top: $space-6xl;
            }
        }
    }

    &.feature-standard {
        .swiper-outer:not(.swiper-initialized) {
            .grid-tile {
                width: 25%;

                @include media-breakpoint-only(md) {
                    width: 33.3333%;
                }

                @include media-breakpoint-down(sm) {
                    width: 50%;
                }
            }
        }
    }

    &.feature-personalized,
    &.feature-emphasized {
        .swiper-outer:not(.swiper-initialized) {
            .grid-tile {
                width: 33.3333%;

                @include media-breakpoint-only(md) {
                    width: 40%;
                }

                @include media-breakpoint-down(sm) {
                    width: 50%;
                }
            }
        }
    }

    &.feature-emphasized {
        .recommendations-header,
        .product-listing-header {
            @include media-breakpoint-up(lg) {
                grid-column: 2/6;
            }
        }

        .swiper-outer:not(.swiper-initialized) {
            .grid-tile {
                @include media-breakpoint-down(sm) {
                    width: 66.6667%;
                }
            }
        }
    }

    .recommendations-header,
    .product-listing-header {
        grid-column: 4/10;
        padding-bottom: $space-2xl;
        padding: 0;

        @include media-breakpoint-down(md) {
            grid-column: 2/11;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
            padding-inline: $space-l;
        }

        a + a {
            margin-left: $space-m;
        }
    }

    .title {
        @include heading-h3;

        color: $dark-text;
    }

    // iOS safari issue: removing transition
    // so that element with position other than static is not flickering
    // (heart icon, early access icon etc.)
    .swiper-ios .swiper-wrapper {
        @include media-breakpoint-down(md) {
            transition-property: none;

            .swiper-wrapper {
                transition-property: transform;
            }
        }
    }

    .swiper-outer {
        grid-column: 1/16;
        max-width: 100%;
        width: 100%;
        padding-top: $space-2xl;

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
        }

        &:not(.swiper-initialized) {
            .swiper-wrapper {
                display: flex;
            }
        }
    }

    .carousel-arrows-block {
        grid-column: 13/16;
    }

    .product-tile {
        .tile-body {
            padding-top: $space-m;
            padding-left: $space-2xl;

            @include media-breakpoint-down(sm) {
                padding-left: $space-l;
            }

            .wishlist-actions {
                right: $space-xs;
                top: $space-xs;
            }
        }

        .swiper-button {
            height: $global-icon-link-size-sm;
            opacity: 0;
            transition: opacity $duration-default ease-in-out;
            width: $global-icon-link-size-sm;
            z-index: $zindex-negative;

            @include media-breakpoint-down(md) {
                display: none;
            }

            svg {
                width: rem(12);
                height: rem(12);
            }
        }

        .image-container {
            overflow: visible;

            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
                .swiper-button {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }

        .swiper-bottom {
            display: none;
        }

        @media (hover: hover) {
            @include tile-on-hover;
        }

        @media (hover: none) {
            .tile-bottom {
                display: none;
            }
        }
    }


    .swiper-button-disabled {
        opacity: 0.5;
    }

    .swiper-bottom {
        @include grid-inner-standard;

        background-color: transparent;
        padding-inline: 0;

        .swiper-scrollbar-lock {
            display: none;
        }
    }

    .swiper-scrollbar {
        background-color: $gallery-scrollbar-bg;
        height: $space-2xs;
        z-index: $zindex-lowest;
    }

    .swiper-scrollbar-drag {
        background-color: $gallery-scrollbar-drag-bg;
        height: $space-2xs;
    }

    .color-swatches {
        margin-top: $space-m;
        margin-bottom: $space-m;
    }

    .attribute:has(.size-list.d-none) {
        margin: 0;
    }

    .select-size-label {
        display: none;
    }

    .size-container {
        display: flex;
        flex-wrap: wrap;
        gap: $space-s $space-xs;
        margin-bottom: $space-m;
        margin-left: rem(-6);

        // empty container for One-size products:
        &.m-size-1:has(.size-list.d-none) {
            margin-bottom: 0;
        }
    }

    .size-btn {
        @include size-button;
    }

    @include extra-links;

    .tile-bottom {
        position: relative;
        transition: opacity $duration-slow;
    }
}

.carousel-arrows-block {
    align-items: flex-end;
    display: flex;
    gap: $space-s;
    justify-content: center;

    @include media-breakpoint-down(md) {
        display: none;
    }

    button {
        background-color: transparent;
        border-radius: 50%;
        color: $carousel-arrow-color;
        height: $global-icon-link-size;
        padding: rem(10);
        width: $global-icon-link-size;
        transition:
            background-color $duration-default,
            color $duration-default;

        &:hover {
            background-color: $carousel-arrow-hover-bg;
        }

        &.swiper-button-disabled {
            color: $carousel-arrow-disable-color;
            pointer-events: none;
        }
    }

    svg {
        display: block;
    }

    &:has(.m-prev.swiper-button-disabled):has(.m-next.swiper-button-disabled) {
        display: none;
    }

    .on-color-dark & {
        button {
            color: $color-white;
            background-color: $carousel-arrow-bg-on-dark;

            &:hover {
                background-color: $carousel-arrow-hover-bg-on-dark;
            }
        }
    }

    .on-color-light & {
        button {
            background-color: $carousel-arrow-bg-on-light;

            &:hover {
                background-color: $carousel-arrow-hover-bg;
            }
        }
    }
}
